import clsx from 'clsx';
import { useRef } from 'react';
import { ReactComponent as ArrowIcon } from 'Assets/icons/arrowDown.svg';

import styles from './RerollInput.module.scss';

interface IProps {
  onSubmit: () => void;
  text: string;
  onTextChange: (newText: string) => void;
}

const RerollInput = (props: IProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleBlur = () => {
    if (!inputRef.current) {
      return;
    }
    const value = inputRef.current.value;
    props.onTextChange(value);
  };

  return (
    <div className={styles.rerollInput}>
      <div className={styles.textareaWrapper}>
        <textarea
          className={clsx(styles.aiInput, {
            [styles.leftScrollbar]: props.text.length > 0,
          })}
          value={props.text}
          placeholder="Type here to generate a new image"
          rows={5}
          onChange={(e) => {
            e.stopPropagation();
            props.onTextChange(e.target.value);
          }}
          onBlur={() => handleBlur()}
          ref={inputRef}
        />
        <button className={styles.aiSubmit} onClick={props.onSubmit}>
          <ArrowIcon />
        </button>
      </div>
    </div>
  );
};

export default RerollInput;
