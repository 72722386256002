export const GET_FUNNEL_PRODUCTS = {
  operationName: 'getFunnelWithPages',
  query: `query getFunnelWithPages(
      $id: String!
    ) {
      getFunnelWithPages(
        id: $id
      ) {
        funnel {
          products {
            name
            descriptor
            price
            currency
            isSubscription
            isSevenDayTrial
          },
        }
      }
    }`,
};
