import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import { IUserDetails } from 'types/user';
import { rxCurrentUser } from 'rx/rxState';

export const fetchCurrentUser = () => {
  graphQlCall({
    queryTemplateObject: QUERIES.GET_CURRENT_USER_QUERY,
    headerType: 'USER-AUTH',
  }).then((data: IUserDetails) => {
    rxCurrentUser.next(data);
  });
};
