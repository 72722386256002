import clsx from 'clsx';
import { JSX, useState } from 'react';
import { ReactComponent as RemoveIcon } from 'Assets/remove.svg';
import { ReactComponent as ArrowLeftIcon } from 'Assets/arrow-left.svg';
import Loader from '../Loader/Loader';

import styles from './ImageCardsSelect.module.scss';

interface IProps {
  images: string[] | JSX.Element[];
  loading?: boolean;
  removable?: boolean;
  selectedCard?: number;
  cardsPerSlide?: number;
  onCardSelect: (index: number) => void;
  onCardRemove?: (index: number) => void;
}

const ImageCardsSelect = ({
  loading,
  removable,
  images,
  selectedCard,
  cardsPerSlide = 4,
  onCardSelect,
  onCardRemove,
}: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    const cardsLength = loading ? images.length + cardsPerSlide : images.length;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, cardsLength - cardsPerSlide)
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const showArrows =
    images.length > cardsPerSlide || (loading && images.length > 0);

  return (
    <div className={styles.container}>
      {showArrows && (
        <button
          className={clsx(styles.leftArrow, {
            [styles.disabled]: currentIndex === 0,
          })}
          onClick={prevSlide}
        >
          <ArrowLeftIcon />
        </button>
      )}
      <div className={styles.overflowWrapper}>
        <div
          className={styles.sliderWrapper}
          style={{
            transform: `translateX(calc(-${
              (currentIndex * 100) / cardsPerSlide
            }% - ${(10 * currentIndex) / cardsPerSlide}px))`,
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={clsx(styles.imageCard, {
                [styles.selected]: index === selectedCard,
              })}
              style={{
                flex: `0 0 calc((100% - ${
                  (cardsPerSlide - 1) * 10
                }px)/${cardsPerSlide})`,
              }}
              onClick={() => onCardSelect(index)}
            >
              {removable && (
                <div
                  className={styles.removeImage}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onCardRemove) onCardRemove(index);
                    if (currentIndex - 1 >= 0)
                      setCurrentIndex(currentIndex - 1);
                  }}
                >
                  <RemoveIcon />
                </div>
              )}
              {typeof image === 'string' ? (
                <img
                  crossOrigin="anonymous"
                  src={image}
                  alt={'image option ' + index}
                />
              ) : (
                image
              )}
            </div>
          ))}
          {loading &&
            Array.from({ length: cardsPerSlide }, (_, i) => i + 1).map((el) => (
              <div
                key={el}
                className={clsx(styles.imageCard, styles.loading)}
                style={{
                  flex: `0 0 calc((100% - ${
                    (cardsPerSlide - 1) * 10
                  }px)/${cardsPerSlide})`,
                }}
              >
                <Loader size={24} color="#5a5a5a" />
              </div>
            ))}
        </div>
      </div>
      {showArrows && (
        <button
          className={clsx(styles.rightArrow, {
            [styles.disabled]: currentIndex === images.length - cardsPerSlide,
          })}
          onClick={nextSlide}
        >
          <ArrowLeftIcon />
        </button>
      )}
    </div>
  );
};

export default ImageCardsSelect;
