import { SubmenuActionType } from '../types';
import DeleteAction from './DeleteAction/DeleteAction';
import UploadAction from './UploadAction/UploadAction';
import DownloadAction from './DownloadAction/DownloadAction';
import UploadBookCoverAction from './UploadBookCoverAction/UploadBookCoverAction';

export const actionComponents = {
  [SubmenuActionType.Remove]: DeleteAction,
  [SubmenuActionType.Reroll]: DeleteAction,
  [SubmenuActionType.Upload]: UploadAction,
  [SubmenuActionType.UploadCover]: UploadBookCoverAction,
  [SubmenuActionType.Download]: DownloadAction,
};
