import { ReactComponent as CrossIcon } from 'Assets/icons/x.svg';
import { ReactComponent as Attention } from 'Assets/Attention.svg';
import { CONSULT_URL } from 'Constants';
import { rxIsLoading } from 'rx/rxState';

import s from './LeavePopup.module.scss';

interface LeavePopupProps {
  projectId: string;
  onSave: () => void;
  onClose: () => void;
}

export function LeavePopup(props: LeavePopupProps) {
  const consultLink = `${CONSULT_URL}/console/projects/${props.projectId}/refresh`;

  const handleSave = () => {
    rxIsLoading.next('Saving page...');
    props.onSave();
    setTimeout(() => {
      window.location.href = consultLink;
    }, 1500);
  };

  const handleLeave = () => {
    window.location.href = consultLink;
  };

  return (
    <div className={s.cover}>
      <div className={s.container}>
        <div className={s.header}>
          <button onClick={props.onClose} className={s.closeButton}>
            <CrossIcon />
          </button>
          <div className={s.attention}>
            <Attention />
            <div>
              Wait! <br /> You haven't saved your work!
            </div>
            <p>Would you like to save this page?</p>
          </div>
        </div>
        <div>
          <button onClick={handleLeave} className={s.leaveBtn}>
            Leave without saving
          </button>
          <button onClick={handleSave} className={s.saveBtn}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
