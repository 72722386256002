import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import s from './AttributeTime.module.scss';

const AttributeTime = (props) => {
  const [isErrorActive, setIsErrorActive] = useState(false);
  const [timeValues, setTimeValues] = useState({
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
  });

  const daysRef = useRef(null);
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);

  useEffect(() => {
    if (props.attribute.value) {
      const values = props.attribute.value.split(':');

      if (values.length === 4) {
        setTimeValues({
          days: values[0].trim().padStart(2, '0'),
          hours: values[1].trim().padStart(2, '0'),
          minutes: values[2].trim().padStart(2, '0'),
          seconds: values[3].trim().padStart(2, '0'),
        });
      } else if (values.length === 3) {
        setTimeValues({
          hours: values[0].trim().padStart(2, '0'),
          minutes: values[1].trim().padStart(2, '0'),
          seconds: values[2].trim().padStart(2, '0'),
        });
      }
    }
  }, [props.attribute.value]);

  const handleChange = async (value, type) => {
    const updatedValues = { ...timeValues, [type]: value };
    setTimeValues(updatedValues);

    const formattedValue =
      props.attribute.pattern === 'date'
        ? `${updatedValues.days || '00'} : ${updatedValues.hours || '00'} : ${
            updatedValues.minutes || '00'
          } : ${updatedValues.seconds || '00'}`
        : `${updatedValues.hours || '00'} : ${
            updatedValues.minutes || '00'
          } : ${updatedValues.seconds || '00'}`;

    if (
      (props.attribute.pattern === 'date' &&
        formattedValue.match(regexFirst)) ||
      (props.attribute.pattern === 'time' && formattedValue.match(regexSecond))
    ) {
      setIsErrorActive(false);
      const splittedInterval = formattedValue.split(':');
      let intervalInSeconds = calcIntervalInSeconds(splittedInterval);
      // console.log('intervalInSeconds', intervalInSeconds)
      // integrate logic of setting value in seconds here

      props.onChange(props.attribute.id, formattedValue);
    } else {
      setIsErrorActive(true);
    }
  };

  const handleInput = async (e, type, nextRef) => {
    let value = 0;

    if (e.target.value !== '') {
      value = Math.max(e.target.min, Math.min(e.target.value, e.target.max));
    }

    await handleChange(value, type);

    if (value.toString().length >= 2 && nextRef) {
      nextRef.current.focus();
      nextRef?.current?.setSelectionRange(0, nextRef.current.value.length);
    }
  };

  const handleBlur = (e, type) => {
    const value = e.target.value.padStart(2, '0');
    handleChange(value, type);
  };

  const regexFirst =
    /^[0-9]{2}(\s)[:](\s)[0-9]{2}(\s)[:](\s)[0-9]{2}(\s)[:](\s)[0-9]{2}$/g;
  const regexSecond = /^[0-9]{2}(\s)[:](\s)[0-9]{2}(\s)[:](\s)[0-9]{2}$/g;

  const calcIntervalInSeconds = (splittedInterval) => {
    if (splittedInterval.length === 3) {
      return (
        +splittedInterval[0] * 60 * 60 +
        +splittedInterval[1] * 60 +
        +splittedInterval[2]
      );
    } else if (splittedInterval.length === 4) {
      return (
        +splittedInterval[0] * 24 * 60 * 60 +
        +splittedInterval[1] * 60 * 60 +
        +splittedInterval[2] * 60 +
        +splittedInterval[3]
      );
    }
  };

  const handleKeyDown = (e, nextRef) => {
    if (e.key === 'Enter') {
      nextRef?.current?.focus();
      nextRef?.current?.setSelectionRange(0, nextRef.current.value.length);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.label}>Length</div>
      <div className={s.content}>
        {props.attribute.pattern === 'date' && (
          <>
            <input
              className={clsx(s.input, isErrorActive ? s.error : '')}
              value={timeValues.days}
              onChange={(e) => handleInput(e, 'days', hoursRef)}
              onBlur={(e) => handleBlur(e, 'days')}
              type="tel"
              maxLength={2}
              ref={daysRef}
              min={0}
              max={99}
              placeholder="DD"
              onKeyDown={(e) => handleKeyDown(e, hoursRef)}
            />
            :
          </>
        )}
        <input
          className={clsx(s.input, isErrorActive ? s.error : '')}
          value={timeValues.hours}
          onChange={(e) => handleInput(e, 'hours', minutesRef)}
          onBlur={(e) => handleBlur(e, 'hours')}
          ref={hoursRef}
          type="tel"
          maxLength={2}
          min={0}
          max={23}
          placeholder="HH"
          onKeyDown={(e) => handleKeyDown(e, minutesRef)}
        />
        :
        <input
          className={clsx(s.input, isErrorActive ? s.error : '')}
          value={timeValues.minutes}
          onChange={(e) => handleInput(e, 'minutes', secondsRef)}
          onBlur={(e) => handleBlur(e, 'minutes')}
          ref={minutesRef}
          type="tel"
          maxLength={2}
          min={0}
          max={59}
          placeholder="MM"
          onKeyDown={(e) => handleKeyDown(e, secondsRef)}
        />
        :
        <input
          className={clsx(s.input, isErrorActive ? s.error : '')}
          value={timeValues.seconds}
          onChange={(e) => handleInput(e, 'seconds')}
          onBlur={(e) => handleBlur(e, 'seconds')}
          ref={secondsRef}
          type="tel"
          maxLength={2}
          min={0}
          max={59}
          placeholder="SS"
          onKeyDown={(e) => handleKeyDown(e, 'seconds')}
        />
      </div>
    </div>
  );
};

export default AttributeTime;
