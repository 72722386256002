import React from 'react';
import clsx from 'clsx';

import styles from './Tab.module.scss';

interface IProps {
  isSelected: boolean;
  onTabClick: () => void;
  title?: string;
  children?: React.ReactNode;
  className?: string;
}

const Tab = ({
  onTabClick,
  className,
  isSelected,
  title,
  children,
}: IProps) => {
  return (
    <div className={clsx(styles.container, className)} onClick={onTabClick}>
      <button className={styles.button}>{title ?? children}</button>
      <div
        className={clsx(styles.border, { [styles.borderPrimary]: isSelected })}
      />
    </div>
  );
};

export default Tab;
