import { ReactComponent as ArrowBottom } from 'Assets/arrowOverlayBottom.svg';
import { ReactComponent as ArrowUp } from 'Assets/arrowOverlayUp.svg';
import { Block } from 'types/page';
import { rxReorderSection } from 'rx/rxState';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

interface IProps {
  block: Block;
  enabled: boolean;
}

const OrderAction = ({ block, enabled }: IProps) => {
  const handleClick = (direction: number) => {
    const view = block.view;
    const index = view.blocks.indexOf(block);

    rxReorderSection.next({
      fromIndex: index,
      toIndex: index + direction,
    });
  };

  return (
    <>
      <ToolbarManipulatorButton
        onClick={() => handleClick(-1)}
        enabled={enabled}
      >
        <ArrowUp />
      </ToolbarManipulatorButton>
      <ToolbarManipulatorButton
        onClick={() => handleClick(1)}
        enabled={enabled}
      >
        <ArrowBottom />
      </ToolbarManipulatorButton>
    </>
  );
};

export default OrderAction;
