import React from 'react';
import RcTooltip from 'rc-tooltip';
import './bootstrap.css';

export type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

type TooltipProps = {
  children: JSX.Element;
  overlayContent: React.ReactNode;
  placement?: TooltipPlacement;
  visible?: boolean;
};

const Tooltip = (props: TooltipProps) => {
  const placement = props.placement ? props.placement : 'bottom';

  return (
    <RcTooltip
      placement={placement}
      visible={props.visible}
      motion={{
        motionName: 'rc-tooltip-zoom',
        motionDeadline: 1000,
      }}
      overlay={props.overlayContent}
    >
      {props.children}
    </RcTooltip>
  );
};

export default Tooltip;
