import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Block } from 'types/page';
import { getTemplate } from 'CoverEditor/tmp_data';
import { ReactComponent as FontIcon } from 'Assets/font.svg';
import CoverEditor from 'CoverEditor/CoverEditor';
import ImageCardsSelect from 'Components/Common/ImageCardsSelect/ImageCardsSelect';
import ToolbarManipulatorPopup from '../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

import styles from './BookCoverTemplateAction.module.scss';

interface IProps {
  block: Block;
  enabled: boolean;
}

const BookCoverTemplateAction = ({ block, enabled }: IProps) => {
  const templateScaleFactor =
    window.outerWidth <= 600 ? (0.1115 * window.outerWidth) / 500 : 0.1115;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const catalog: any = [];
    let index = 0;
    const _data = getTemplate();
    for (const data of _data) {
      const d = {
        ...data,
        image: block.image.value,
        index: index,
      };
      catalog.push(d);
      index += 1;
    }

    setTemplates(catalog);
  }, [block.image.value]);

  return (
    <ToolbarManipulatorButton
      className={clsx(styles.button, { [styles.active]: isModalVisible })}
      onClick={() => setIsModalVisible(true)}
      enabled={enabled}
    >
      <FontIcon />
      <ToolbarManipulatorPopup
        isPopupVisible={isModalVisible}
        onPopupClose={() => setIsModalVisible(false)}
        placement={window.outerWidth <= 600 ? 'bottomRight' : 'bottom'}
        popupContent={
          <div className={styles.modalContentWrapper}>
            <ImageCardsSelect
              images={templates.map((template) => (
                <CoverEditor
                  readOnly
                  data={template}
                  titles={block.template.titles}
                  width={block.template.width * templateScaleFactor}
                  height={block.template.height * templateScaleFactor}
                />
              ))}
              onCardSelect={(value) => {
                block.template.onTemplateSelect(templates[value]);
              }}
              selectedCard={block.template.index}
            />
          </div>
        }
      />
    </ToolbarManipulatorButton>
  );
};
export default BookCoverTemplateAction;
