import React from 'react';
import styled from 'styled-components';
import { Block, BlockStyle } from './Block';
import { ReactComponent as DragIcon } from '../../Assets/drag.svg';

const BlockPlaceholderStyle = styled(BlockStyle)`
  ${(props) =>
    props.displayPlaceholder
      ? `
    .content{
        background-color: rgb(0,0,0,0.3);
        height: 100%;
        width: 100%;
        padding: 8px;
    }
    .inner{
        display: flex;
        height: 100%;
        border-style: dotted;
        border-color: #ced2d9;
        border-radius: 5px;
        border-width: 2px;
    }`
      : `opacity: 0;`}
`;

export class BlockPlaceholderProxy extends React.Component {
  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
  }
  render() {
    return (
      <BlockPlaceholderStyle
        id={this.props.id}
        key={this.props.id}
        displayPlaceholder={this.props.displayPlaceholder}
        backgroundColor={this.props.backgroundColor}
        isDragging={this.props.isDragging}
        ref={this.props.reference}
        className={this.className}
      >
        <div className="content">
          <div className="inner">
            <div style={{ margin: 'auto', textAlign: 'center' }}>
              <DragIcon
                style={{
                  padding: '7px',
                  width: '100%',
                  height: '100%',
                  maxWidth: '60px',
                  maxHeight: '60px',
                  objectFit: 'contain',
                }}
              />
            </div>
          </div>
        </div>
      </BlockPlaceholderStyle>
    );
  }
}

export class BlockPlaceholder extends Block {
  constructor(props) {
    super(props);
    this.type = 'Placeholder';
    this.displayPlaceholder = false;
  }

  renderView() {
    return (
      <BlockPlaceholderProxy
        id={this.id}
        key={this.id}
        reference={this.ref}
        ref={this.proxyRef}
        styleText={this.style}
        displayPlaceholder={this.displayPlaceholder}
      ></BlockPlaceholderProxy>
    );
  }
}
