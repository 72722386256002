import styles from './Item.module.scss';
import clsx from 'clsx';

interface IProps {
  id: number;
  style: any;
  maskImage?: string;
}

const ItemGradient = (props: IProps) => {
  if (props.maskImage) {
    return (
      <div
        className={clsx(styles.cover, styles.frozen)}
        style={{ maskImage: `url(${props.maskImage})`, maskSize: 'contain' }}
      >
        <div className={styles.cover} style={props.style}></div>
      </div>
    );
  } else {
    return (
      <div
        className={clsx(styles.cover, styles.frozen)}
        style={props.style}
      ></div>
    );
  }
};

export default ItemGradient;
