import React, { Component } from 'react';
import StoreController from 'StoreController';
import styled from 'styled-components';

const DigitStyle = styled.div.attrs((props) => ({
  style: {
    marginTop: props.number * (props.size === 'small' ? -1.375 : -1.4) + 'em',
    transition: props.transition ? 'margin-top 0.4s ease' : 'none',
  },
}))`
  width: 40%;
`;

const ContainerStyle = styled.div`
  overflow: hidden;
  height: ${(props) => props.height}px;
  line-height: 1.4;
  display: flex;
  justify-content: center;
`;

const InputStyle = styled.input.attrs((props) => ({
  type: 'number',
}))`
  overflow: hidden;
  height: ${(props) => props.height}px;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  background-color: transparent;
  font-size: 38px;
  width: 100%;
`;

export default class ShiftClock extends Component {
  constructor(props) {
    super(props);

    this.offset = [];
    this.state = {
      shift: false,
      isEditing: false,
      editValue: '',
    };
    this.transition = false;
    this.onTransitionComplete = this.onTransitionComplete.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  onTransitionComplete() {
    if (this.props.value[1] === '0') {
      this.setState({ shift: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.value !== this.props.value) {
      setTimeout(this.onTransitionComplete, 500);
      if (this.state.shift) {
        this.setState({ shift: false });
        return false;
      }
    }
    return true;
  }

  handleDoubleClick() {
    this.setState({ isEditing: true, editValue: this.props.value });
  }

  handleChange(e) {
    let value = 0;

    if (e.target.value !== '') {
      value = Math.max(
        parseInt(e.target.min),
        Math.min(parseInt(e.target.value), parseInt(e.target.max))
      );
    }

    this.setState({ editValue: value });

    this.props.onValueChange(value);
  }

  handleBlur() {
    this.setState({ isEditing: false });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleBlur();
    }
  }

  render() {
    let transition = true;
    let value = [];
    value[0] = this.props.value[0];
    value[1] = this.props.value[1];
    if (this.state.shift) {
      value[1] = 10;
      transition = false;
    }
    const store = StoreController.instance();

    return (
      <ContainerStyle height={this.props.height}>
        {this.state.isEditing && !store.liveMode ? (
          <InputStyle
            value={this.state.editValue}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onKeyPress={this.handleKeyPress}
            autoFocus
            placeholder={this.props.value}
            min={this.props.min}
            max={this.props.max}
          />
        ) : (
          <>
            <DigitStyle
              number={value[0]}
              size={this.props.size}
              transition={true}
              onDoubleClick={this.handleDoubleClick}
            >
              0 1 2 3 4 5 6 7 8 9 0
            </DigitStyle>
            <DigitStyle
              number={value[1]}
              size={this.props.size}
              transition={transition}
              onDoubleClick={this.handleDoubleClick}
            >
              0 1 2 3 4 5 6 7 8 9 0
            </DigitStyle>
          </>
        )}
      </ContainerStyle>
    );
  }
}
