import { useState } from 'react';
import { Block } from 'types/page';
import { ReactComponent as AIPromptIcon } from 'Assets/prompt.svg';
import ToolbarWidgetAiFeild from 'Components/Toolbar/ToolbarWidgetAiField';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

import styles from './AiPromptAction.module.scss';

interface IProps {
  block: Block;
  enabled: boolean;
}

const AIPromptAction = ({ block, enabled }: IProps) => {
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);

  return (
    <ToolbarManipulatorButton
      onClick={() => setIsWidgetVisible(true)}
      enabled={enabled}
    >
      <AIPromptIcon />
      {isWidgetVisible && (
        <div
          className={styles.widgetContainer}
          onClick={(e) => e.stopPropagation()}
        >
          <ToolbarWidgetAiFeild
            block={block as any}
            onClose={() => setIsWidgetVisible(false)}
            aiFieldsName={block.view.gatherAiFieldsName()}
          />
        </div>
      )}
    </ToolbarManipulatorButton>
  );
};

export default AIPromptAction;
