import { useState } from 'react';
import { Block } from 'types/page';
import { ReactComponent as GearIcon } from 'Assets/gear.svg';
import BlockSettings from 'BlockSettings/BlockSettings';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';
import ToolbarManipulatorPopup from '../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';

import styles from './SettingsAction.module.scss';

interface IProps {
  block: Block;
  enabled: boolean;
  onToggleManipulatorVisibility: (value: boolean) => void;
}

const SettingsAction = ({
  block,
  enabled,
  onToggleManipulatorVisibility,
}: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleValueChange = (
    attributeId: string,
    value: string | boolean | number
  ) => {
    block.onAttributeChanged(attributeId, value);
    block[attributeId].value = value;
    block.view.overlay.update();
    onToggleManipulatorVisibility(true);
  };

  return (
    <ToolbarManipulatorButton
      className={styles.settingsButton}
      onClick={() => setIsModalVisible(true)}
      enabled={enabled}
    >
      <GearIcon />
      <ToolbarManipulatorPopup
        className={styles.popup}
        isPopupVisible={isModalVisible}
        onPopupClose={() => setIsModalVisible(false)}
        popupContent={
          <BlockSettings block={block} onValueChange={handleValueChange} />
        }
      />
    </ToolbarManipulatorButton>
  );
};

export default SettingsAction;
