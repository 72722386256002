import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import TextArea from '../TextArea/TextArea';

import s from './ContentEditableDiv.module.scss';

interface IProps {
  setIsTouched?: (isTouched: boolean) => void;
  onClick?: () => void;
  onChange: (name: string) => void;
  placeholder?: string;
  autofocus?: boolean;
  name: string;
  fontSize: number;
  lineHeight: number;
  fontWeight: number;
  width?: number;
  height: number;
  onEdit?: (value: string, pageId?: string, pageEnabled?: boolean) => void;
  allowEmpty?: boolean;
  isMultiline?: boolean;
  blocked?: boolean;
  suffixIcon?: React.ReactNode;
  prefixIcon?: React.ReactNode;
  preFirstEdit?: boolean;
}

const ContentEditableDiv = ({
  setIsTouched,
  onChange,
  placeholder,
  autofocus,
  name,
  fontSize,
  lineHeight,
  fontWeight,
  width,
  height,
  onEdit,
  onClick,
  allowEmpty,
  isMultiline,
  blocked,
  suffixIcon,
  prefixIcon,
  preFirstEdit,
}: IProps) => {
  const refTextarea = useRef<HTMLTextAreaElement>(null);

  UseOnClickOutside(refTextarea, () => {
    if (onChange) {
      onChange(name.trim());
      getTextAreaHeight();
    }
  });

  useEffect(() => {
    getTextAreaHeight();
  }, [refTextarea]);

  const getTextAreaHeight = () => {
    const textarea = refTextarea.current;
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleEditing = () => {
    if (blocked) {
      return;
    }
    if (onEdit) {
      onEdit(name);
    }
    // Check for an empty value
    if (name === '') {
      const emptyText = allowEmpty ? '' : 'Untitled';
      if (onChange) {
        onChange(emptyText);
      }
      if (onEdit) {
        onEdit(emptyText);
      }
    }
  };

  if (isMultiline) {
    return (
      <div
        className={clsx(
          s.containerTextArea,
          preFirstEdit ? s.containerTextAreaFreFirst : '',
          {
            [s.inputWithSuffix]: !!suffixIcon,
            [s.inputWithPrefix]: !!prefixIcon,
          }
        )}
      >
        {prefixIcon && <div className={s.prefixIcon}>{prefixIcon}</div>}
        <div style={{ width: '100%' }}>
          <TextArea
            direction="bookTitle"
            textareaRef={refTextarea}
            label={placeholder || ''}
            autoFocus={autofocus && true}
            value={name}
            onFocus={() => setIsTouched && setIsTouched(false)}
            maxLength={150}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            onBlur={() => {
              handleEditing();
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (onClick) onClick();
            }}
            onChange={(value) => {
              if (blocked) {
                return;
              }
              getTextAreaHeight();
              if (onChange) onChange(value);
            }}
            style={{
              width: width ? `${width}px` : 'unset',
              fontSize: `${fontSize}px`,
              lineHeight: `${lineHeight}px`,
              fontWeight: `${fontWeight}`,
            }}
          />
        </div>
        {suffixIcon && <div className={s.suffixIcon}>{suffixIcon}</div>}
      </div>
    );
  } else
    return (
      <div
        className={clsx(s.container, {
          [s.inputWithSuffix]: !!suffixIcon,
          [s.inputWithPrefix]: !!prefixIcon,
        })}
      >
        {prefixIcon && <div className={s.prefixIcon}>{prefixIcon}</div>}
        <input
          placeholder={placeholder}
          autoFocus={autofocus && true}
          className={s.name}
          value={name}
          onFocus={() => setIsTouched && setIsTouched(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !blocked) {
              e.currentTarget.blur();
            }
          }}
          onBlur={(e) => {
            handleEditing();
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick && !blocked) onClick();
          }}
          onChange={(e) => {
            onChange && !blocked && onChange(e.currentTarget.value);
          }}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            fontSize: `${fontSize}px`,
            lineHeight: `${lineHeight}px`,
            fontWeight: `${fontWeight}`,
          }}
        />
        {suffixIcon && <div className={s.suffixIcon}>{suffixIcon}</div>}
      </div>
    );
};

export default ContentEditableDiv;
