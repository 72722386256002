class FontManager {
  loadedFonts: string[] = [];

  private static instance: FontManager;
  private constructor() {}

  public static getInstance(): FontManager {
    if (!FontManager.instance) {
      FontManager.instance = new FontManager();
    }

    return FontManager.instance;
  }

  public loadFont(fontFamily: string, weight: number) {
    const fontUrlName = fontFamily.replaceAll(' ', '+');

    let elementId = 'font-' + fontFamily;
    let fontWeight = '';
    if (weight && weight != 0) {
      fontWeight = ':wght@' + weight;
      elementId += ':' + weight;
    }

    const fontKey = `${fontUrlName}${fontWeight}`;
    if (this.loadedFonts.indexOf(fontKey) < 0) {
      //If font not loaded yet we will load it and add key to the list;
      this.loadedFonts.push(fontKey);

      if (!document.getElementById(elementId)) {
        var link = document.createElement('link');
        link.id = elementId;
        link.rel = 'stylesheet';
        link.crossOrigin = 'anonymous';
        link.href = `https://fonts.googleapis.com/css2?family=${fontKey}&display=swap`;
        document.head.appendChild(link);
      }
    }
  }
}

export default FontManager;
