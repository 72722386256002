export const GET_USER_PRODUCTS = {
  operationName: 'getUserProducts',
  query: `query getUserProducts {
      getUserProducts {
        _id
        name
        description
        currency
        price
        createdAt
        updatedAt
      }
    }`,
};
