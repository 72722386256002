import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import clsx from 'clsx';
import style from './LinkButton.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'secondary';
}

const LinkButton: React.FC<PropsWithChildren<IProps>> = ({
  children,
  color = 'primary',
  className,
  ...rest
}) => {
  const colors = {
    primary: style.primaryColor,
    secondary: style.secondaryColor,
    accent: style.accentColor,
  };

  return (
    <button
      type="button"
      className={clsx(style.button, colors[color], className)}
      {...rest}
    >
      {children}
    </button>
  );
};

export default LinkButton;
