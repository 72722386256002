import { rxPages, rxNavigation, eventEmitter } from '../rxState';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

export const deletePage = (state, { name }) => {
  const navigation = state.navigation.filter((item) => item.name !== name);

  let newPages = {};

  const oldPages = state.pages;

  for (const pageName in oldPages) {
    if (oldPages.hasOwnProperty(pageName)) {
      if (pageName !== name) {
        newPages[pageName] = oldPages[pageName];
      }
    }
  }
  graphQlCall({
    queryTemplateObject: QUERIES.DELETE_SUBPAGE,
    headerType: 'USER-AUTH',
    values: {
      pageId: state.pageId,
      name,
    },
  })
    .then((res) => {
      eventEmitter.next({
        type: 'add-alert',
        payload: {
          type: 'success',
          text: 'Delete successfully',
        },
      });
    })
    .catch((error) => {
      eventEmitter.next({
        type: 'add-alert',
        payload: {
          type: 'error',
          text: 'API delete error',
          description: error.message,
        },
      });
    });

  rxPages.next(newPages);
  rxNavigation.next(navigation);
};
