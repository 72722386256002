import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { rxSelectedBlocks } from '../rx/rxState';
import ReactJson from 'react-json-view';

const SectionEditorStyle = styled.div`
  width: 600px;
  background-color: #2e2e2e;
  overflow: auto;
  font-size: 12px;
  position: relative;
`;

const OpenSectionEditor = styled.button`
  position: fixed;
  right: 0px;
  z-index: 10;
  width: 50px;
`;

var _blockData = {};

const SectionEditor = (props) => {
  let selectedBlockSub = null;
  const [block, setBlock] = useState(null);
  const [counter, setCounter] = useState(0); //Forced Update counter
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!selectedBlockSub) {
      selectedBlockSub = rxSelectedBlocks.subscribe({
        next: (v) => {
          if (v.length > 0) {
            let block = v[0];
            if (_blockData['id'] !== block.id) {
              _blockData = block.pack();
            }

            setBlock(block);
          } else {
            setBlock(null);
            _blockData = {};
          }
        },
      });
    }

    return () => {
      selectedBlockSub.unsubscribe();
      selectedBlockSub = null;
    };
  });

  const handleEdit = (edit) => {
    console.log('edit', edit);
    let newValue = null;
    try {
      newValue = JSON.parse(edit.new_value);
    } catch (error) {}
    if (newValue) {
      let obj = _blockData;
      for (let name of edit.namespace) {
        obj = obj[name];
      }
      obj[edit.name] = newValue;
      setCounter(counter + 1);
      // console.log(_blockData);
    } else {
      _blockData = edit.updated_src;
    }
  };

  const handleAdd = (edit) => {
    _blockData = edit.updated_src;
  };

  const handleUpdate = () => {
    let view = block.view;
    view.removeBlock(block);
    let b = view.addBlock(_blockData);
    setBlock(null);
    view.overlay.selectBlock(b);
  };

  const handleDelete = (edit) => {
    _blockData = edit.updated_src;
  };

  const handleCollapse = (field) => {
    return field.name === 'text';
  };
  const handleOpen = () => {
    setVisible(!visible);
  };
  return (
    <>
      <OpenSectionEditor onClick={handleOpen}>SE</OpenSectionEditor>

      {visible ? (
        <>
          <SectionEditorStyle>
            {_blockData['id'] ? (
              <button
                style={{
                  position: 'fixed',
                  background: 'gray',
                  zIndex: '2',
                  right: '50px',
                }}
                onClick={handleUpdate}
              >
                update
              </button>
            ) : null}

            {block ? (
              <>
                <ReactJson
                  src={_blockData}
                  theme="monokai"
                  displayObjectSize={false}
                  displayDataTypes={false}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                  onAdd={handleAdd}
                  shouldCollapse={handleCollapse}
                />
              </>
            ) : null}
          </SectionEditorStyle>
        </>
      ) : null}
    </>
  );
};

export default SectionEditor;
