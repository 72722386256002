import { RefObject, useRef } from 'react';
import styles from './Item.module.scss';
import Draggable, { DraggableHandle } from './Draggable';

interface IProps {
  id: number;
  image: string;
  width: number;
  height: number;
  x: number;
  y: number;
  scale: number;
  frozen: boolean;
  style?: any;
  onSelected: (senderRef: RefObject<DraggableHandle>) => boolean;
}

const ItemImage = (props: IProps) => {
  const draggableRef = useRef<DraggableHandle>(null);

  return (
    <Draggable
      id={props.id}
      width={props.width}
      height={props.height}
      x={props.x}
      y={props.y}
      scale={props.scale}
      frozen={props.frozen}
      onSelected={() => {
        props.onSelected(draggableRef);
      }}
      ref={draggableRef}
    >
      <img
        crossOrigin="anonymous"
        style={props.style}
        className={styles.image}
        draggable={false}
        src={props.image}
        alt="item"
      />
    </Draggable>
  );
};

export default ItemImage;
