export const CREATE_USER_MUTATION = {
  operationName: 'createUser',
  query: `mutation createUser(
      <KEYS>
    ) {
      createUser(
        <VALUES>
      ) {
        _id
        stripeClientId
        name
        owner
        email
        role
      }
    }`,
};
