import React, { Fragment } from 'react';
import { Block, BlockStyle } from './Block';
import Video from '../../Components/VideoPlayer/Video';
import styled from 'styled-components';
import { API_VIDEO_UPLOAD, API_VIDEO_STATUS } from '../../Constants';
import StoreController from '../../StoreController';
import { ReactComponent as VideoPlaceholderIcon } from '../../Assets/BlockPlaceholderIcons/video.svg';
import { rxPageId } from '../../rx/rxState';
import Spinner from '../../Components/Common/Spinner/Spinner';
import Progress from '../../Components/Common/Progress/Progress';

const BlockVideoStyle = styled(BlockStyle)``;

const VideoContainerStyle = styled.div`
  color: black;
  position: relative;
  height: 100%;
  border: 1px solid ${(props) => (props.dragOver ? 'white' : '#97b6f5')};
  text-align: center;
  background: ${(props) => (props.dragOver ? '#B3D9FF' : '#7DBBFA')};
`;

const VideoPlaceholderStyle = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .video-icon {
    padding-bottom: calc((56.2% - 82px) / 2);
    padding-top: calc((56.2% - 82px) / 2);
    > svg {
      height: 50px;
      width: auto;
      margin: 2px;
    }
    > p {
      margin: 0;
      padding: 0;
    }
  }
  .video-progress {
    width: 90%;

    .ant-progress-text {
      color: #000;
    }

    .ant-progress-inner {
      background-color: #fff;
    }
  }
`;

export class BlockVideoProxy extends React.Component {
  constructor(props) {
    super(props);
    this.videoUrl = props.content;
    if (this.props.fileInProgress) {
      this.videoConversionJobId = this.props.fileInProgress;
      this.state = {
        dragOver: false,
        status: 'idle',
        videoUrl: undefined,
        loadInProgress: true,
        loaded: 100,
        converted: 0,
      };
      this.timer = setInterval(
        this.videoConversionStatusUpdate.bind(this),
        1000
      );
    } else {
      this.timer = '';
      this.videoConversionJobId = '';
      this.state = {
        dragOver: false,
        status: 'idle',
        videoUrl: this.videoUrl,
        loadInProgress: false,
        loaded: 0,
        converted: 0,
      };
    }
    this.isEventTriggered = false;
  }

  onDragLeave(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      dragOver: false,
    });
  }
  onDragOver(event) {
    event.preventDefault();
    this.setState({
      ...this.state,
      dragOver: true,
    });
  }

  componentDidUpdate(prevProps) {
    // if(prevProps.styleText !== this.props.styleText){
    this.props.reference.current.style.cssText = this.props.styleText();
    // }
  }

  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
    const element = document.createElement('video');
    element.setAttribute('src', this.videoUrl);
    element.addEventListener('loadedmetadata', () => {
      const duration = Math.ceil(element.duration);
      this.props.onChange({ duration: duration });
    });

    if (StoreController.instance().liveMode && this.props.timedElements) {
      let rootSection = this.props.view.getRootSectionFor(this.props.block);
      let block = rootSection.getBlockById(this.props.timedElementId);
      if (block) {
        block.setVisible(false);
      }
    }
  }

  videoConversionStatusUpdate() {
    fetch(API_VIDEO_STATUS + '/?jobId=' + this.videoConversionJobId)
      .then((res) => res.json())
      .then(
        (result) => {
          // get value for the progress bar
          let progress;
          if (result.progress)
            // TODO: backend not yet send value in result.progress.
            progress = result.progress;
          else {
            progress = this.state.converted + 10;
            if (progress === 100 && result.status !== 'COMPLETE') progress = 10;
          }

          this.setState({
            ...this.state,
            converted: progress,
          });
          if (result.status === 'COMPLETE') {
            this.setState({
              ...this.state,
              status: 'idle',
              videoUrl: this.videoUrl,
              loadInProgress: false,
              converted: 0,
              loaded: 0,
            });
            this.props.onChange({ jobId: null });

            clearInterval(this.timer);
          } else if (result.status === 'ERROR') {
            this.setState({
              ...this.state,
              status: 'error',
            });
            clearInterval(this.timer);
          }
        },
        (error) => {
          console.log(error);
          clearInterval(this.timer);
        }
      );
  }

  uploadVideoFile(file) {
    var xhr = new XMLHttpRequest();
    this.setState({
      loadInProgress: true,
      status: 'processing',
    });

    console.log('uploading new video');
    xhr.upload.addEventListener(
      'progress',
      (e) => {
        var pc = parseInt(100 - (e.loaded / e.total) * 100);
        this.setState({
          loaded: 100 - pc,
        });
      },
      false
    );

    xhr.onreadystatechange = ((e) => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let response = JSON.parse(xhr.response);
          this.props.onChange({ jobId: response.jobId });
          this.videoConversionJobId = response.jobId;
          this.videoUrl = response.url;
          this.props.onChange({ videoUrl: this.videoUrl });
          this.timer = setInterval(
            this.videoConversionStatusUpdate.bind(this),
            1000
          );
        } else {
          this.setState({
            status: 'error',
          });
          if (this.props.fileInProgress) {
            this.setState({
              dragOver: false,
              videoUrl: undefined,
              loadInProgress: true,
              loaded: 100,
              converted: 0,
            });
          } else {
            this.setState({
              dragOver: false,
              videoUrl: this.videoUrl,
              loadInProgress: false,
              loaded: 0,
              converted: 0,
            });
          }
        }
      }
    }).bind(this);

    xhr.open('POST', API_VIDEO_UPLOAD, true);
    var fd = new FormData();
    fd.append('file', file);
    xhr.send(fd);
  }

  onVideoTimeUpdate(time) {
    if (StoreController.instance().liveMode && this.props.timedElements) {
      let comp = this.props.timeToShow.split(':');
      let seconds =
        parseInt(comp[0]) * 60 * 60 +
        parseInt(comp[1]) * 60 +
        parseFloat(comp[2]);
      if (seconds < time && !this.isEventTriggered) {
        this.isEventTriggered = true;
        let rootSection = this.props.view.getRootSectionFor(this.props.block);
        let block = rootSection.getBlockById(this.props.timedElementId);

        if (block) {
          block.setVisible(true);
        }
      }
    }
  }

  onDrop(event) {
    event.preventDefault();

    console.log('ONDROP');
    this.videoUrl = null;
    this.setState({
      dragOver: false,
      status: 'uploading',
      videoUrl: null,
    });

    let files = event.dataTransfer.files;
    let file = files[0];

    //Changed because this code make status error while drop video box from sidebar
    if (file && file.length !== 0) {
      if (file.type.indexOf('video') === 0) {
        this.uploadVideoFile(file);
      } else {
        this.setState({
          ...this.state,
          status: 'error',
        });
      }
    }
  }
  onDraggerChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      // message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      // message.error(`${info.file.name} file upload failed.`);
    }
  }

  render() {
    var videoBlock;
    if (this.state.videoUrl) {
      videoBlock = (
        <Video
          width="100%"
          height="100%"
          inEditor={!StoreController.instance().liveMode}
          autoplay={this.props.autoplay}
          continueWatching={this.props.continueWatching}
          playBar={this.props.playBar}
          loop={this.props.isLooped}
          onTimeUpdate={(e) => this.onVideoTimeUpdate(e)}
          id={`${this.props.id}-${rxPageId.getValue()}`}
          url={this.state.videoUrl}
        />
      );
    } else {
      videoBlock = (
        <VideoPlaceholderStyle className={this.props.className}>
          <div className="video-icon">
            <VideoPlaceholderIcon />
            <div className="video-status">
              {!this.state.loadInProgress &&
                this.state.status !== 'error' &&
                'Drop video file here'}
              {this.state.loadInProgress && <Spinner size={110} />}
              {this.state.loadInProgress &&
                this.state.loaded < 100 &&
                this.state.converted === 0 &&
                this.state.status !== 'error' && <span>Uploading...</span>}
              {this.state.status === 'error' && (
                <span style={{ fontSize: '25px', color: 'red' }}>
                  Uploading Error. Try again later.
                </span>
              )}
              {this.state.loadInProgress && this.state.loaded === 100 && (
                <span>Converting...</span>
              )}
            </div>
          </div>
          {this.state.loadInProgress && (
            <div className="video-progress">
              {this.state.loaded < 100 && (
                <Progress completed={this.state.loaded} />
              )}
              {this.state.converted > 0 && (
                <Progress
                  completed={this.state.converted}
                  isLabelVisible={false}
                />
              )}
            </div>
          )}
        </VideoPlaceholderStyle>
      );
    }
    let content;
    if (!StoreController.instance().liveMode) {
      content = (
        <VideoContainerStyle
          onDragOver={(e) => this.onDragOver(e)}
          onDragLeave={(e) => this.onDragLeave(e)}
          onDrop={(e) => this.onDrop(e)}
          dragOver={this.state.dragOver}
        >
          {videoBlock}
        </VideoContainerStyle>
      );
    } else {
      content = videoBlock;
    }
    return (
      <BlockVideoStyle
        width={this.props.width}
        id={this.props.id}
        ref={this.props.reference}
        style={this.props.style}
        isDragging={this.props.isDragging}
      >
        {content}
      </BlockVideoStyle>
    );
  }
}

export class BlockVideo extends Block {
  constructor(props) {
    super(props);
    this.type = 'Video';

    let attr = {
      id: 'autoplay',
      displayName: 'Autoplay',
      value: true,
      type: 'AttributeBool',
      visible: true,
    };
    this.addAttribute(attr);

    attr = {
      id: 'isLooped',
      displayName: 'GIF Mode',
      value: false,
      type: 'AttributeBool',
      visible: true,
    };
    this.addAttribute(attr);

    attr = {
      id: 'playBar',
      displayName: 'Play Bar',
      value: true,
      type: 'AttributeBool',
      visible: true,
    };
    this.addAttribute(attr);

    attr = {
      id: 'continueWatching',
      displayName: 'Continue Watching',
      value: true,
      type: 'AttributeBool',
      visible: true,
    };
    this.addAttribute(attr);

    attr = {
      id: 'timedElements',
      displayName: 'Timed Button',
      value: false,
      type: 'AttributeBool',
      visible: true,
    };
    this.addAttribute(attr);

    attr = {
      id: 'timeToShow',
      displayName: 'Time To Show',
      value: null,
      type: 'AttributeTime',
      pattern: 'time',
      visible: false,
    };
    this.addAttribute(attr);

    attr = {
      id: 'timedElementId',
      displayName: 'Timed Element ID',
      value: '',
      type: 'AttributeString',
      visible: false,
    };
    this.addAttribute(attr);

    this.onChange = this.onChange.bind(this);
    this.rate = 16 / 9;
  }
  update() {
    super.update();
    if (this.timedElements.value === true) {
      this.timeToShow.visible = true;
    } else {
      this.timeToShow.visible = false;
    }
  }
  pack() {
    let data = super.pack();
    data['videoUrl'] = this.videoUrl;
    data['jobId'] = this.jobId;
    return data;
  }
  unpack(data) {
    super.unpack(data);
    let videoUrl = data['videoUrl'];
    if (videoUrl !== undefined) {
      this.videoUrl = videoUrl;
    }

    let jobId = data['jobId'];
    if (jobId !== undefined) {
      this.jobId = jobId;
    }
  }

  onChange(e) {
    if (e.videoUrl !== undefined) {
      this.videoUrl = e.videoUrl;
    }
    if (e.duration !== undefined) {
      // this.attributes[ 'video' ].duration = e.duration;
      // this.attributes[ 'events' ].duration = e.duration;
    }
    if (e.jobId !== undefined) {
      this.jobId = e.jobId;
    }
  }

  updateBoundingRect() {
    if (this.ref.current) {
      let rect = this.ref.current.getBoundingClientRect();
      const height = rect.width / this.rate;
      const bottom = rect.top + height;
      this.boundingRect = {
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: height,
        left: rect.left,
        right: rect.right,
        top: rect.top,
        bottom: bottom,
      };
    }
  }

  calculateHeight(styleHeight, geometry) {
    return geometry.width / this.rate;
  }

  renderView() {
    return (
      <Fragment key={this.id}>
        {this.isVisible ? (
          <>
            {/* { this.isDragging ? this.getPlaceholder()
                : */}
            <BlockVideoProxy
              id={this.id}
              key={this.id}
              content={this.videoUrl}
              width={this.worldRenderBRect ? this.worldRenderBRect.width : 0}
              onChange={this.onChange}
              reference={this.ref}
              ref={this.proxyRef}
              // events={this.attributes['events'].value}
              view={this.view}
              styleText={this.style}
              fileInProgress={this.jobId}
              timedElementId={this.timedElementId.value}
              timeToShow={this.timeToShow.value}
              timedElements={this.timedElements.value}
              block={this}
              autoplay={this.autoplay.value}
              continueWatching={this.continueWatching.value}
              playBar={this.playBar.value}
              isLooped={this.isLooped.value}
              className={this.className}
            ></BlockVideoProxy>
            {/* } */}
          </>
        ) : null}
      </Fragment>
    );
  }
}
