import { useEffect, useState } from 'react';
import { rxBookDetailsPopup } from 'rx/rxState';
import { useObservable } from 'utils/UseObservable';
import Tabs from 'Components/Common/Tabs/Tabs';
import { IBookChapter } from 'Components/books/types';
import { SIDE_MENU_ID } from 'Pages/BookEditor';
import { ReactComponent as CloseSvg } from '../../../Assets/closeModal.svg';

import styles from './BookDetails.module.scss';

const BookDetails = () => {
  const bookDetailsPopup: any = useObservable(rxBookDetailsPopup);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (bookDetailsPopup?.open) {
      setOpen(true);
      toggleSideMenuUserSelection(false);
    } else {
      setOpen(false);
      toggleSideMenuUserSelection(true);
    }
  }, [bookDetailsPopup?.open]);

  const generalContent = (
    <div className={styles.generalContentContainer}>
      <div className={styles.section}>
        <div className={styles.title}>What is the title of this book?</div>
        <div className={styles.content}>
          {bookDetailsPopup?.bookData?.title || '-'}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Tone</div>
        <div className={styles.content}>
          {bookDetailsPopup?.bookData?.tone || '-'}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Book Description</div>
        <div className={styles.content}>
          {bookDetailsPopup?.bookData?.description || '-'}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>What is your audience?</div>
        <div className={styles.content}>
          {bookDetailsPopup?.bookData?.audience || '-'}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Structure</div>
        <div className={styles.content}>
          {bookDetailsPopup?.bookData?.structure || '-'}
        </div>
      </div>
    </div>
  );

  const chaptersContent = (
    <div className={styles.chapterContentContainer}>
      {bookDetailsPopup?.bookData?.chapters.map(
        (chapter: IBookChapter, index: number) => (
          <div className={styles.section}>
            <div className={styles.title}>
              <div className={styles.sectionNumber}>{index + 1}</div>
              <div>{chapter.title}</div>
            </div>
            <div className={styles.description}>
              {chapter.comments.map((comment) => (
                <div>{comment}</div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );

  const handleModalClose = () => {
    rxBookDetailsPopup.next({
      open: false,
      bookData: bookDetailsPopup?.bookData,
    });
    toggleSideMenuUserSelection(true);
    setOpen(false);
  };

  const toggleSideMenuUserSelection = (enable: boolean) => {
    const sideMenu = document.getElementById(SIDE_MENU_ID);
    if (!sideMenu) return;

    if (enable) sideMenu.style.removeProperty('user-select');
    else sideMenu.style.setProperty('user-select', 'none');
  };

  if (open && !!bookDetailsPopup?.bookData) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.background} onClick={handleModalClose} />
        <div className={styles.popupContainer}>
          <h2 className={styles.popupHeader}>Book Details</h2>
          <Tabs
            className={styles.tabsWrapper}
            contentClassName={styles.contentContainer}
            tabs={[
              { title: 'General', content: generalContent },
              { title: 'Chapters', content: chaptersContent },
            ]}
          />
          <CloseSvg className={styles.closeIcon} onClick={handleModalClose} />
        </div>
      </div>
    );
  }

  return null;
};

export default BookDetails;
