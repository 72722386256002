import React, { useState } from 'react';
import s from './DatePicker.module.scss';
import arrow from '../../../Assets/icons/arrow.svg';
import Calendar from './Calendar/Calendar';
import useElementClickedOutside from '../../../Hooks/useElementClickedOutside';

const DatePicker = (props) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(props.value);

  const onDateChanged = (date) => {
    setDate(date);
    props.onChange(date);
  };

  const { ref } = useElementClickedOutside(() => {
    setOpen(false);
  });
  const format = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };
  return (
    <div ref={ref} className={s.container}>
      <div onClick={() => setOpen(!open)}>
        <p className={s.placeholder}>{props.label}:</p>
        <input
          readOnly
          value={
            date === null ? 'MM:DD:YY' : date.toLocaleString('en-US', format)
          }
        />
        <img className={`${s.arrow} ${open ? s.open : null}`} src={arrow} />
      </div>
      {open && <Calendar selectedDate={date} setDate={onDateChanged} />}
    </div>
  );
};

export default DatePicker;
