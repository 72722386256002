import React from 'react';
import Spinner from 'Components/Common/LoadingSpinner/LoadingSpinner';
import s from './BlockProcessing.module.scss';

const BlockProcessing = () => {
  return (
    <div className={s.container}>
      <div className={s.subContainer}>
        <Spinner />
      </div>
    </div>
  );
};

export default BlockProcessing;
