export const ADD_TO_MAILCHIMP_LIST = {
  operationName: 'addToMailChimpList',
  query: `mutation addToMailChimpList(
      $email: String!
    ) {
      addToMailChimpList(
        email: $email
      ) {
        message
      }
    }`,
};
