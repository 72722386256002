import { useEffect, useState } from 'react';
import { eventEmitter, rxPriceError } from 'rx/rxState';
import { Block } from 'types/page';
import Dropdown from 'Components/Common/Dropdown/Dropdown';
import RequiredFieldWrapper from 'Components/Common/RequiredFieldWrapper/RequiredFieldWrapper';

import styles from './AttributeDropdown.module.scss';

interface IProps {
  block: Block;
  attribute: any;
  onChange: (attrName: string, newValue: string) => void;
}

const AttributeDropdown = (props: IProps) => {
  const [label, setLabel] = useState('');

  useEffect(() => {
    updateLabel(props.attribute.value);
  }, []);

  const updateLabel = (value: string) => {
    const option = props.attribute.options.find(
      (opt: { label: string; value: string }) => opt.value === value
    );
    if (option) setLabel(option.label);
  };

  const onClick = (option: { value: string; label: string }) => {
    if (props.attribute.id === 'price') {
      rxPriceError.next(null);
    }
    let attr = props.attribute;
    props.onChange(attr.id, option.value);
    setTimeout(() => {
      props.onChange(attr.id, option.value);
    }, 0);
    updateLabel(props.attribute.value);

    eventEmitter.next({
      type: 'update-view',
    });
  };

  return (
    <RequiredFieldWrapper
      missingValue={props.attribute.required && !props.attribute.value}
    >
      <div
        className={
          props.attribute.id === 'borderRadius'
            ? styles['attribute-control-border-radius']
            : styles['attribute-control']
        }
      >
        <Dropdown
          name={props.attribute.displayName}
          initialValue={label}
          options={props.attribute.options}
          onChange={onClick}
        />
      </div>
    </RequiredFieldWrapper>
  );
};

export default AttributeDropdown;
