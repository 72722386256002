import React, { Fragment } from 'react';
import styled from 'styled-components';
import { generateAiImageBySocket } from 'utils/socket';
import { rxBlockIndexForBook, rxBlockIndexForSectionsPopup } from 'rx/rxState';
import BlockProcessing from 'Components/BlockProcessing/BlockProcessing';

import { Block, BlockStyle } from './Block';

const PlaceholderStyle = styled.div`
  height: 100px;
  background-image: url(${process.env.PUBLIC_URL}/assets/droparea.png);
`;

const BlockSectionStyle = styled(BlockStyle)`
  ${(props) =>
    props.isDragging
      ? `box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.5);
                                position: absolute;
                                z-index: 100;
                                width: 100%;`
      : null}
  ${(props) => (props.image ? `background: url(${props.image});` : null)}
    background-color: ${(props) => props.backgroundColor};
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  break-after: page;
  position: relative;
`;

export class BlockSectionProxy extends React.Component {
  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
  }

  componentDidUpdate(prevProps) {
    // if(prevProps.styleText !== this.props.styleText){
    this.props.reference.current.style.cssText = this.props.styleText();
    // }
  }

  render() {
    let style = {};
    if (this.props.width) {
      style['width'] = this.props.width;
    } else {
      const width = this.props.view.getViewWidth();
      if (width != '100%') {
        style['width'] = width;
      }
    }
    if (this.props.height) {
      style['height'] = this.props.height;
    }

    return (
      <BlockSectionStyle
        id={this.props.id}
        key={this.props.id}
        backgroundColor={this.props.backgroundColor}
        image={this.props.image}
        isDragging={this.props.isDragging}
        ref={this.props.reference}
        className={this.props.className}
      >
        <div className="s-container" style={style}>
          {this.props.children}
        </div>
        {this.props.processing && <BlockProcessing />}
      </BlockSectionStyle>
    );
  }
}

export class BlockSection extends Block {
  constructor(props) {
    super(props);

    this.type = 'Section';
    this.widthOverride = null;
    this.heightOverride = null;

    let attr = {
      id: 'color',
      displayName: 'Color',
      value: '#7f7f7f',
      type: 'AttributeColor',
    };
    this.addAttribute(attr);

    attr = {
      id: 'paddingTop',
      displayName: 'Top',
      value: '0px',
      type: 'AttributeDropdown',
      options: [
        {
          label: '180px',
          value: '180px',
        },
        {
          label: '80px',
          value: '80px',
        },
        {
          label: '60px',
          value: '60px',
        },
        {
          label: '30px',
          value: '30px',
        },
        {
          label: '10px',
          value: '10px',
        },
      ],
    };

    this.addAttribute(attr);
    attr = {
      id: 'paddingBottom',
      displayName: 'Bottom',
      value: '0px',
      type: 'AttributeDropdown',
      options: [
        {
          label: '180px',
          value: '180px',
        },
        {
          label: '80px',
          value: '80px',
        },
        {
          label: '60px',
          value: '60px',
        },
        {
          label: '30px',
          value: '30px',
        },
        {
          label: '10px',
          value: '10px',
        },
      ],
    };
    this.addAttribute(attr);

    attr = {
      id: 'image',
      displayName: 'Image',
      type: 'AttributeImage',
    };

    this.addAttribute(attr);
    this.manipulatorHasBeenClicked = null;

    this.mousePosition = {};
    this.isMobile = false; //'ontouchstart' in window || navigator.maxTouchPoints > 0 //TODO: need to find a global way of adjusting behvaiour
    this.openSectionsBtnPosition = {};
    this.openSectionsBtnHovered = false;
    this.isButtonBelowSection = null;
    const plusSvgSrc = `${process.env.PUBLIC_URL}/assets/icons/plus.svg`;
    this.icon = new Image();
    this.icon.src = plusSvgSrc;
  }

  onAttributeChanged(attr, newValue) {
    if (attr == 'image') {
      if (this.color.value === '#ffffff') {
        this.color.value = '#E0E0E0';
      }
    }
  }

  unpack(data) {
    super.unpack(data);

    if (data['widthOverride']) {
      this.widthOverride = data['widthOverride'];
    }
    if (data['heightOverride']) {
      this.heightOverride = data['heightOverride'];
    }

    if (typeof data['image'] === 'string') {
      //TODO: remove this eventually when conversion will not be needed
      this.image.value = data['image'];
    }
  }

  pack() {
    let data = super.pack();

    data['widthOverride'] = this.widthOverride;
    data['heightOverride'] = this.heightOverride;

    return data;
  }

  style() {
    let output = super.style();
    output += `padding-bottom: ${this.paddingBottom.value};`;
    output += `padding-top: ${this.paddingTop.value};`;

    return output;
  }

  setOffset(value) {
    super.setOffset(value);
    if (this.ref.current) {
      this.ref.current.style.left = value.x + 'px';
      this.ref.current.style.top = value.y + 'px';
    }
  }
  onMouseMove(e) {
    this.mousePosition = {
      x: e.clientX - this.view.canvasRect.x,
      y: e.clientY - this.view.canvasRect.y,
    };
    if (
      this.mousePosition.x >= this.openSectionsBtnPosition.x &&
      this.mousePosition.x <= this.openSectionsBtnPosition.x + 70 &&
      this.mousePosition.y >= this.openSectionsBtnPosition.y &&
      this.mousePosition.y <= this.openSectionsBtnPosition.y + 30
    ) {
      this.openSectionsBtnHovered = true;
    } else {
      this.openSectionsBtnHovered = false;
    }
  }
  onMouseUp(e) {
    let clickPosition = this.view.toLocal({ x: e.clientX, y: e.clientY });
    if (
      clickPosition.x >= this.openSectionsBtnPosition.x &&
      clickPosition.x <= this.openSectionsBtnPosition.x + 70 &&
      clickPosition.y >= this.openSectionsBtnPosition.y &&
      clickPosition.y <= this.openSectionsBtnPosition.y + 30
    ) {
      const blockIndex = this.view.blocks.findIndex(
        (block) => block.id === this.id
      );
      if (this.openSectionsBtnHovered && this.isHovered) {
        //TODO: this is workaround to fix issue when button "activates" on every click
        if (this.isButtonBelowSection) {
          rxBlockIndexForSectionsPopup.next(blockIndex + 1);
          rxBlockIndexForBook.next({ index: blockIndex + 1 });
        } else {
          rxBlockIndexForSectionsPopup.next(blockIndex);
          rxBlockIndexForBook.next({ index: blockIndex });
        }
        return true;
      }
    }

    return false;
  }
  renderOverlay(ctx) {
    if (this.isDragging) {
      return;
    }
    if (this.isSelected) {
      const rect = this.worldRenderBRect;
      if (rect === null) {
        return;
      }
      ctx.save();

      ctx.fillStyle = '#6C68FF';
      ctx.fillRect(rect.x, rect.y, rect.width, 5);
      ctx.fillRect(rect.x, rect.y + rect.height - 5, rect.width, 5);

      ctx.strokeStyle = '#6C68FF';
      ctx.lineWidth = 1;

      ctx.beginPath();

      ctx.moveTo(rect.x, rect.y + 1);
      ctx.lineTo(rect.x + rect.width, rect.y + 1);

      ctx.moveTo(rect.x, rect.y - 1 + rect.height);
      ctx.lineTo(rect.x + rect.width, rect.y - 1 + rect.height);

      ctx.stroke();

      ctx.restore();
    }

    if (this.isMobile) {
      const rect = this.worldRenderBRect;
      if (rect === null) {
        return;
      }

      const elementBottom = rect.bottom;

      const addSectionRectangleWidth = 70;
      const addSectionRectangleHeight = 30;
      const blockIndex = this.view.blocks.findIndex(
        (block) => block.id === this.id
      );
      const isLastSection = blockIndex === this.view.blocks.length - 1;
      const isFirstSection = blockIndex === 0;

      const addSectionRectangle = {
        x: rect.width / 2 - addSectionRectangleWidth / 2,
        y: null,
        width: addSectionRectangleWidth,
        height: addSectionRectangleHeight,
      };

      if (isLastSection) {
        addSectionRectangle.y = elementBottom - addSectionRectangleHeight;
      } else {
        addSectionRectangle.y = elementBottom - addSectionRectangleHeight / 2;
      }
      this.isButtonBelowSection = true;

      ctx.setLineDash([2, 4]);
      ctx.beginPath();
      if (isLastSection) {
        ctx.moveTo(0, addSectionRectangle.y + addSectionRectangle.height);
        ctx.lineTo(
          rect.width,
          addSectionRectangle.y + addSectionRectangle.height
        );
      } else {
        ctx.moveTo(0, addSectionRectangle.y + addSectionRectangle.height / 2);
        ctx.lineTo(
          rect.width,
          addSectionRectangle.y + addSectionRectangle.height / 2
        );
      }
      ctx.strokeStyle = '#A0A0A0';
      ctx.stroke();
      ctx.setLineDash([]); //reostore normal line dash

      ctx.fillStyle = this.openSectionsBtnHovered ? '#6674F4' : '#4957D8';
      ctx.strokeStyle = this.openSectionsBtnHovered ? '#6674F4' : '#4957D8';
      const x = addSectionRectangle.x;
      const y = addSectionRectangle.y;
      const width = addSectionRectangle.width;
      const height = addSectionRectangle.height;
      const borderRadius = 25;
      if (isLastSection) {
        ctx.beginPath();
        ctx.moveTo(x + borderRadius, y);
        ctx.lineTo(x + width - borderRadius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
        ctx.lineTo(x + width, y + height);
        ctx.lineTo(x, y + height);
        ctx.lineTo(x, y + borderRadius);
        ctx.quadraticCurveTo(x, y, x + borderRadius, y);
        ctx.closePath();
        ctx.fill();
      } else {
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.roundRect(x, y, width, height, borderRadius - 5);
        ctx.fill();
      }
      ctx.drawImage(
        this.icon,
        addSectionRectangle.x + 27,
        addSectionRectangle.y + 6,
        18,
        18
      );
      this.openSectionsBtnPosition = {
        x: addSectionRectangle.x,
        y: addSectionRectangle.y,
      };
    }
    if (this.isHovered && !this.isMobile) {
      const mousePosition = this.mousePosition;
      const rect = this.worldRenderBRect;
      if (rect === null) {
        return;
      }

      const elementTop = rect.top;
      const elementBottom = rect.bottom;
      const topThreshold = 30;
      const bottomThreshold = rect.height - 30;

      const addSectionRectangleWidth = 70;
      const addSectionRectangleHeight = 30;
      const blockIndex = this.view.blocks.findIndex(
        (block) => block.id === this.id
      );
      const isLastSection = blockIndex === this.view.blocks.length - 1;
      const isFirstSection = blockIndex === 0;

      const addSectionRectangle = {
        x: rect.width / 2 - addSectionRectangleWidth / 2,
        y: null,
        width: addSectionRectangleWidth,
        height: addSectionRectangleHeight,
      };

      if (mousePosition.y <= elementTop + topThreshold) {
        if (isFirstSection) {
          addSectionRectangle.y = elementTop;
        } else {
          addSectionRectangle.y = elementTop - addSectionRectangleHeight / 2;
        }
        // console.log("Top")
        this.isButtonBelowSection = false;
      } else if (mousePosition.y >= elementTop + bottomThreshold) {
        if (isLastSection) {
          addSectionRectangle.y = elementBottom - addSectionRectangleHeight;
        } else {
          addSectionRectangle.y = elementBottom - addSectionRectangleHeight / 2;
        }
        // console.log("Bottom")
        this.isButtonBelowSection = true;
      }
      if (
        mousePosition.y <= elementTop + topThreshold ||
        mousePosition.y >= elementTop + bottomThreshold
      ) {
        ctx.setLineDash([2, 4]);
        ctx.beginPath();
        if (mousePosition.y >= elementTop + bottomThreshold && isLastSection) {
          ctx.moveTo(0, addSectionRectangle.y + addSectionRectangle.height);
          ctx.lineTo(
            rect.width,
            addSectionRectangle.y + addSectionRectangle.height
          );
        } else if (
          mousePosition.y <= elementTop + topThreshold &&
          isFirstSection
        ) {
          ctx.moveTo(0, addSectionRectangle.y);
          ctx.lineTo(rect.width, addSectionRectangle.y);
        } else {
          ctx.moveTo(0, addSectionRectangle.y + addSectionRectangle.height / 2);
          ctx.lineTo(
            rect.width,
            addSectionRectangle.y + addSectionRectangle.height / 2
          );
        }
        ctx.strokeStyle = '#A0A0A0';
        ctx.stroke();
        ctx.setLineDash([]); //reostore normal line dash

        ctx.fillStyle = this.openSectionsBtnHovered ? '#6674F4' : '#4957D8';
        ctx.strokeStyle = this.openSectionsBtnHovered ? '#6674F4' : '#4957D8';
        const x = addSectionRectangle.x;
        const y = addSectionRectangle.y;
        const width = addSectionRectangle.width;
        const height = addSectionRectangle.height;
        const borderRadius = 25;
        if (mousePosition.y >= elementTop + bottomThreshold && isLastSection) {
          ctx.beginPath();
          ctx.moveTo(x + borderRadius, y);
          ctx.lineTo(x + width - borderRadius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
          ctx.lineTo(x + width, y + height);
          ctx.lineTo(x, y + height);
          ctx.lineTo(x, y + borderRadius);
          ctx.quadraticCurveTo(x, y, x + borderRadius, y);
          ctx.closePath();
          ctx.fill();
        } else if (
          mousePosition.y <= elementTop + topThreshold &&
          isFirstSection
        ) {
          ctx.beginPath();
          ctx.moveTo(x + borderRadius, y + height);
          ctx.lineTo(x + width - borderRadius, y + height);
          ctx.quadraticCurveTo(
            x + width,
            y + height,
            x + width,
            y + height - borderRadius
          );
          ctx.lineTo(x + width, y);
          ctx.lineTo(x, y);
          ctx.lineTo(x, y + height - borderRadius);
          ctx.quadraticCurveTo(x, y + height, x + borderRadius, y + height);
          ctx.closePath();
          ctx.fill();
        } else {
          ctx.lineCap = 'round';
          ctx.lineJoin = 'round';
          ctx.roundRect(x, y, width, height, borderRadius - 5);
          ctx.fill();
        }

        ctx.drawImage(
          this.icon,
          addSectionRectangle.x + 27,
          addSectionRectangle.y + 6,
          18,
          18
        );
        this.openSectionsBtnPosition = {
          x: addSectionRectangle.x,
          y: addSectionRectangle.y,
        };
      } else {
        this.openSectionsBtnPosition = {};
      }
    }

    for (let child of this.children) {
      child.renderOverlay(ctx);
    }
  }

  async rerollWithAiPrompt() {
    if (!this.image) return;

    const prompt = this.getAiFieldPrompt();
    if (prompt) {
      this.processing = true;
      this.view.update();
      const ratio = '1:1';
      const payload = {
        text: prompt,
        imageCount: 1,
        ratio,
      };
      const response = await generateAiImageBySocket({
        payload,
      });
      this.processing = false;
      this.image.value = response.imageUrl;
      this.view.update();
    }
  }

  renderView() {
    let placeholder = <PlaceholderStyle></PlaceholderStyle>;

    return (
      <Fragment key={this.id}>
        {this.isDragging ? placeholder : null}
        <BlockSectionProxy
          id={this.id}
          key={this.id}
          view={this.view}
          reference={this.ref}
          width={this.widthOverride}
          height={this.heightOverride}
          styleText={this.style}
          backgroundColor={this.color.value}
          isDragging={this.isDragging}
          className={this.className}
          image={this.image.value}
          processing={this.processing}
          handleManipulatorChange={(value) =>
            (this.manipulatorHasBeenClicked = value)
          }
        >
          {this.children.map((child) => {
            return child.renderView();
          })}
        </BlockSectionProxy>
      </Fragment>
    );
  }
}
