import { useState, useRef } from 'react';
import Button from 'Components/Common/Button/Button';
import { loadFile } from 'utils/functions';
import { eventEmitter } from 'rx/rxState';

import s from './AttributeImage.module.scss';

const AttributeImage = (props) => {
  const [value, setValue] = useState(props.attribute.value);

  const inputFileRef = useRef(null);

  const onChange = (v) => {
    let attr = props.attribute;
    props.onChange(attr.id, v);
    setValue(v);

    eventEmitter.next({
      type: 'update-view',
    });
  };

  const handleImageSet = () => {
    inputFileRef.current.click();
  };

  const handleFileLoad = () => {
    const files = inputFileRef.current.files;
    const file = files[0];
    loadFile(file, onChange);
  };

  const handleImageRemove = () => {
    onChange('');
  };

  return (
    <div className={s.container}>
      <Button color="attribute" size="attribute" onClick={handleImageSet}>
        Set Image
      </Button>
      <input
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        ref={inputFileRef}
        onChange={handleFileLoad}
      />
      {props.attribute.value && (
        <Button color="attribute" size="attribute" onClick={handleImageRemove}>
          Remove Image
        </Button>
      )}
    </div>
  );
};

export default AttributeImage;
