import { useMemo, useState } from 'react';
import { Block } from 'types/page';
import { ReactComponent as PencilIcon } from 'Assets/pencil.svg';
import BlockSettings from 'BlockSettings/BlockSettings';
import ToolbarManipulatorPopup from '../../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';
import AttributesQuickAccessAction from '../../AttributesQuickAccessAction/AttributesQuickAccessAction';

import styles from './DestinationQuickAccessAction.module.scss';

interface IProps {
  block: Block;
  onToggleManipulatorVisibility: (value: boolean) => void;
}

const DestinationQuickAccessAction = ({
  block,
  onToggleManipulatorVisibility,
}: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleValueChange = (
    attributeId: string,
    value: string | number | boolean
  ) => {
    block.onAttributeChanged(attributeId, value);
    block[attributeId].value = value;
    block.view.overlay.update();
    onToggleManipulatorVisibility(true);
  };

  const destinationLabel = useMemo(() => {
    const option = block.destination.options.find(
      (opt: { label: string; value: string }) =>
        opt.value === block.destination.value
    );

    return option ? option.label : '';
  }, [block.destination.options, block.destination.value]);

  return (
    <>
      <div
        className={styles.destinationLabel}
        onClick={() => setIsModalVisible(true)}
      >
        {destinationLabel}
        <PencilIcon className={styles.editIcon} />
        <ToolbarManipulatorPopup
          className={styles.popup}
          isPopupVisible={isModalVisible}
          onPopupClose={() => setIsModalVisible(false)}
          popupContent={
            <BlockSettings block={block} onValueChange={handleValueChange} />
          }
        />
      </div>
      <AttributesQuickAccessAction
        block={block}
        onToggleManipulatorVisibility={onToggleManipulatorVisibility}
      />
    </>
  );
};

export default DestinationQuickAccessAction;
