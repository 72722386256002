import React, { useState, useEffect } from 'react';
import s from './ModalExit.module.scss';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import View from '../../../Canvas/View';
import { rxExitModalEnabled, rxShowExitModal } from '../../../rx/rxState';
import { useObservable } from '../../../utils/UseObservable';
import {
  VIEW_STATUSES,
  VIEW_TYPES,
  switchStatus,
} from '../../../rx/actions/rxViewStatus';
import StoreController from '../../../StoreController';

const ModalExit = (props) => {
  const showModalInEditor = useObservable(rxShowExitModal);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (e) => {
    props.onClose();
    setIsOpen(false);

    // if(!e){
    //   return;
    // }

    switchStatus({
      status: VIEW_STATUSES.IDLE,
      viewType: VIEW_TYPES.REGULAR,
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isOpen]);

  useEffect(() => {
    let posY = 1000;
    document.addEventListener('mousemove', (e) => {
      posY = e.clientY;
    });
    let showed = false;
    document.addEventListener('mouseout', (e) => {
      if (
        !e.toElement &&
        !e.relatedTarget &&
        posY < 100 &&
        props.liveMode &&
        !showed
      ) {
        if (rxExitModalEnabled.getValue()) {
          showed = true;
          setIsOpen(true);
        }
      }
    });

    if (showModalInEditor) {
      setIsOpen(true);
    } else {
      handleClose();
    }
  }, [showModalInEditor]);

  const isLive = StoreController.instance().liveMode;

  return (
    <div
      className={`${s.root} ${isOpen ? s.open : ''} ${
        isLive ? s.rootLive : null
      }`}
    >
      <div className={s.modalBeckground} onClick={handleClose} />
      <div className={`${s.container} ${isOpen ? s.open : ''}`}>
        {isLive ? (
          <div className={s.close} onClick={handleClose}>
            <CloseIcon
              style={{
                top: '4px',
                right: '8px',
                width: '20px',
                position: 'absolute',
              }}
            />
          </div>
        ) : null}
        <View
          id="popupView"
          modalView={true}
          productId={props.productId}
          page={props.page}
          liveMode={props.liveMode}
          width={850}
          height={640}
          zoom={1}
        />
      </div>
    </div>
  );
};

export default ModalExit;
