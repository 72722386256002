import Manipulator from './Manipulator';
import Adder from './Adder';
import { VIEW_STATUSES } from '../rx/actions/rxViewStatus';
import { rxSelectedBlocks } from '../rx/rxState';
import { isToolbarAction } from '../utils/functions';

const kDesignResolutionWidth = 1024;
var sOverlays = {}; // one Overlay per view;

export default class Overlay {
  constructor(props) {
    this.ctx = props.canvas.getContext('2d');
    this.view = props.view;
    this.blocks = [];
    this.selectedBlocks = [];
    this.scrollTop = 0;

    if (!props.live) {
      setTimeout(
        function () {
          let viewRoot = props.view.viewRef.current.parentElement;
          document.addEventListener(
            'mousemove',
            this.ignoreEventsOutside(this.onMouseMove)
          );
          viewRoot.addEventListener(
            'mousedown',
            this.ignoreEventsOutside(this.onMouseDown)
          );
          document.addEventListener(
            'mouseup',
            this.ignoreEventsOutside(this.onMouseUp)
          );
          document.addEventListener(
            'drag',
            this.ignoreEventsOutside(this.onDrag)
          );
          document.addEventListener(
            'drop',
            this.ignoreEventsOutside(this.onDrop)
          );
          document.addEventListener(
            'dragstart',
            this.ignoreEventsOutside(this.onDragStart)
          );
          // document.addEventListener('dblclick', this.ignoreEventsOutside(this.onDoubleClick));
          document.addEventListener(
            'wheel',
            this.ignoreEventsOutside(this.onWheel)
          );

          document.addEventListener(
            'animationstart',
            this.ignoreEventsOutside(this.handleAnimationStart)
          );
          document.addEventListener(
            'animationend',
            this.ignoreEventsOutside(this.handleAnimationEnd)
          );

          document.addEventListener(
            'transitionstart',
            this.ignoreEventsOutside(this.handleAnimationStart)
          );
          document.addEventListener(
            'transitionend',
            this.ignoreEventsOutside(this.handleAnimationEnd)
          );
        }.bind(this),
        1000
      );
    }

    this.manipulator = new Manipulator(props.view);
    this.manipulator.setBlocks(this.selectedBlocks);

    this.adder = new Adder(props.view);

    this.isMouseDown = false;

    this.creationData = {};
    this.mousePosition = {};

    this.zoomAnimationTarget = 1.0;
    this.scrollAnimationTarget = 0.0;

    this.dragMovement = { x: 0, y: 0 };
    this.dragStart = { x: 0, y: 0 };

    this.updateAnimationEnabled = false;
    this.updateAnimation = this.updateAnimation.bind(this);
    this.updateZoomAnimation = this.updateZoomAnimation.bind(this);
  }

  static instance(id, props) {
    if (sOverlays[id] === undefined) {
      sOverlays[id] = new Overlay(props);
    }
    return sOverlays[id];
  }

  setZoom(value) {
    this.zoomAnimationTarget = value;
    window.requestAnimationFrame(this.updateZoomAnimation);
  }

  handleAnimationStart(e) {
    //no transtion or animation should take longer then 2 seconds
    setTimeout(
      function () {
        this.handleAnimationEnd();
      }.bind(this),
      2000
    );

    this.updateAnimationEnabled = true;
    window.requestAnimationFrame(this.updateAnimation);
  }

  handleAnimationEnd(e) {
    //there is some delay between event triggered and animation actually finished.
    setTimeout(
      function () {
        this.updateAnimationEnabled = false;
      }.bind(this),
      2000
    );
  }

  addSectionFromData(sectionData, oldLastId, index = 0) {
    const { lastId, data } = this.transformData(sectionData, oldLastId);
    let block = this.view.createBlock(data.type, data.id);
    this.view.blocks.splice(index, 0, block);
    block.unpack(data);
    // block.unpackConnections(data);

    this.selectBlock(block);
    block.update();
    this.manipulator.update();
    // this.manipulator.grabReorderHandler();

    // block.update();

    return lastId;
  }

  cancelSectionAdd() {
    if (this.selectedBlocks.length >= 0) {
      this.view.removeBlock(this.selectedBlocks[0]);
    }
  }

  transformData(sectionData, oldLastId) {
    if (typeof sectionData === 'string') {
      sectionData = JSON.parse(sectionData);
    }
    let lastId = oldLastId + 1;
    let dict = {};
    const oldIds = this.getSectionIds(sectionData);
    oldIds.forEach((oldId) => {
      dict[oldId] = lastId++;
    });
    var data = { ...sectionData };
    this.changeSeactionIds(data, dict);

    return {
      data,
      lastId,
    };
  }

  getSectionIds(data) {
    let ids = [];
    if (data.id) {
      ids.push(data.id);
    }
    if (data.children && data.children.length > 0) {
      data.children.forEach((child) => {
        const childIds = this.getSectionIds(child);
        ids = [...ids, ...childIds];
      });
    }
    return ids;
  }

  changeSeactionIds(data, dict) {
    data.id = dict[data.id];

    if (data.children) {
      for (let child of data.children) {
        this.changeSeactionIds(child, dict);
      }
    }
  }

  duplicateSection(block) {
    if (block) {
      if (block.type == 'Section') {
        let dataString = JSON.stringify(block.pack()); //needed to make sure all Dict references are broken and all objects are independent
        let data = JSON.parse(dataString);
        let index = this.blocks.indexOf(block);
        this.view.idCounter = this.addSectionFromData(
          data,
          this.view.idCounter,
          index
        );
      }
    }
    this.view.update();
    this.update();
  }
  updateZoomAnimation(timestamp) {
    let smooth = 0.4;
    this.view.setState({
      zoom:
        this.zoomAnimationTarget * smooth + this.view.state.zoom * (1 - smooth),
    });

    if (Math.abs(this.zoomAnimationTarget - this.view.state.zoom) > 0.01) {
      window.requestAnimationFrame(this.updateZoomAnimation);
    } else {
      this.view.setState({ zoom: this.zoomAnimationTarget });
    }
  }

  setBlocks(blocks) {
    this.blocks = blocks;
  }

  rectContainPoint(rect, x, y) {
    return (
      rect.x <= x &&
      x <= rect.x + rect.width &&
      rect.y <= y &&
      y <= rect.y + rect.height
    );
  }

  // onDoubleClick(e){
  //     const viewStatus = this.view.getStatus();
  //     if (viewStatus === VIEW_STATUSES.DISABLED) {
  //         return;
  //     }
  //     let isEditing = false;
  //     for(let block of this.blocks){
  //         let editingBlock = block.blockAtPoint(this.mousePosition.x, this.mousePosition.y);
  //         const doubleClickEnabled = ['Text', 'Button', 'Image', ];
  //         if (editingBlock && doubleClickEnabled.includes(editingBlock.type)) {
  //             editingBlock.setEditing(true);
  //             isEditing = true;
  //             break;
  //         }
  //     }

  //     this.draw();
  // }

  onWheel(e) {
    this.manipulator.onScroll(e.deltaY);
  }

  selectBlock(block) {
    if (!block) {
      return;
    }

    for (let block of this.selectedBlocks) {
      block.setSelected(false);
    }
    this.selectedBlocks.splice(0, this.selectedBlocks.length);

    block.setSelected(true);
    this.selectedBlocks.push(block);

    this.manipulator.selectionDidChanged();
  }
  moveSectionBlocks(threshold, delta) {
    let blockToMove = [];
    for (let block of this.view.blocks) {
      if (block.worldRenderBRect.y >= threshold) {
        blockToMove.push(block);
      }
    }

    for (let block of blockToMove) {
      block.move({ x: 0, y: delta, width: 0, height: 0 });
    }
  }

  removeSelectedBlock() {
    let selectedBlock = this.manipulator.blocks[0];
    if (selectedBlock && !selectedBlock.isEditing) {
      if (selectedBlock.type === 'Section') {
        selectedBlock.removeFromParent();
        this.clearSelection();
        this.view.update();

        //Move All Section blocks up to close the gap after block deletion
        // let bottom = selectedBlock.worldRenderBRect.y + selectedBlock.worldRenderBRect.height;
        // this.moveSectionBlocks(bottom, -selectedBlock.worldRenderBRect.height);
      }
    }
  }
  clearSelection() {
    for (let block of this.selectedBlocks) {
      block.setSelected(false);
      if (block.isEditing) {
        block.setEditing(false);
      }

      //TODO need to find a way to make it more elegant. In some cases like "Button and Text" we need to send signals of non editing to child
      for (let child of block.children) {
        if (child.isEditing) {
          child.setEditing(false);
        }
      }
    }
    this.selectedBlocks.splice(0, this.selectedBlocks.length);
    this.manipulator.selectionDidChanged();

    rxSelectedBlocks.next(this.selectedBlocks);
  }
  selectBlockAtPoint(point) {
    //create new selection
    let selectedBlock = null;
    for (let block of this.blocks) {
      let blockPoint = block.blockAtPoint(point.x, point.y);
      if (blockPoint) {
        if (!blockPoint.isSelectable) {
          selectedBlock = block.blockAtPoint(point.x, point.y, blockPoint);
        } else {
          selectedBlock = blockPoint;
        }
        if (selectedBlock) {
          break;
        }
        break;
      }
    }
    if (selectedBlock) {
      let index = this.selectedBlocks.indexOf(selectedBlock);
      if (index >= 0) {
        // block already selected;
        return false;
      }
      this.clearSelection();

      selectedBlock.setSelected(true);
      this.selectedBlocks.push(selectedBlock);

      this.manipulator.selectionDidChanged();
      this.manipulator.onMouseDown(point, 1); // extra mouse down event processing for selected blocks

      this.view.setState({}); //TODO: need to find a way to proper update React components

      return true;
    }
    return false;
  }

  onMouseDown(e) {
    const viewStatus = this.view.getStatus();
    if (viewStatus === VIEW_STATUSES.DISABLED) {
      return;
    }

    if (e.button !== 0) {
      return;
    }
    let canvasRect = this.view.canvasRect;
    if (e.clientX > canvasRect.right || e.clientY < canvasRect.top) {
      return;
    }

    this.isMouseDown = true;

    this.manipulator.update();
    let clickCaptured = this.manipulator.onMouseDown(this.mousePosition, 1);
    for (let block of this.blocks) {
      block.onMouseDown(e);
    }
    if (!clickCaptured) {
      clickCaptured = this.selectBlockAtPoint(this.mousePosition);
      if (!clickCaptured) {
        clickCaptured = this.manipulator.onMouseDown(this.mousePosition, 0);
        if (!clickCaptured) {
          this.clearSelection();
        }
      } else {
        rxSelectedBlocks.next(this.selectedBlocks);
      }
    }

    this.draw();
  }

  onMouseMove(e) {
    const viewStatus = this.view.getStatus();
    if (viewStatus === VIEW_STATUSES.DISABLED) {
      return;
    }
    this.mousePosition = this.view.toLocal({ x: e.clientX, y: e.clientY });

    for (let block of this.blocks) {
      block.setHovered(false);
    }
    for (let block of this.blocks) {
      let b = block.blockAtPoint(this.mousePosition.x, this.mousePosition.y);
      if (b) {
        b.setHovered(true);
        b.onMouseMove(e);
        break;
      }
    }

    this.update();
    this.manipulator.onMouseMove(this.mousePosition, {
      x: e.movementX,
      y: e.movementY,
    });
    this.draw();
  }

  ignoreEventsOutside = (action) => {
    const actionFn = action.bind(this);
    return (e) => {
      if (!isToolbarAction(e)) {
        return actionFn(e);
      }
    };
  };

  onMouseUp(e) {
    const viewStatus = this.view.getStatus();
    if (viewStatus === VIEW_STATUSES.DISABLED) {
      return;
    }

    this.isMouseDown = false;
    this.manipulator.onMouseUp(e);

    for (let block of this.blocks) {
      const captured = block.onMouseUp(e);
      if (captured) {
        break;
      }
    }

    if (
      this.selectedBlocks[0] &&
      (this.selectedBlocks[0].type === 'Text' ||
        this.selectedBlocks[0].type === 'TextCover')
    ) {
      this.selectedBlocks[0].isEditing = true;
    }

    this.draw();
  }

  onDrag(e) {
    const viewStatus = this.view.getStatus();
    if (viewStatus !== VIEW_STATUSES.IDLE) {
      return;
    }
    let currentPos = {
      x: e.clientX - this.view.canvasRect.x,
      y: e.clientY - this.view.canvasRect.y,
    };

    this.update();

    this.manipulator.onMouseMove(currentPos, {
      x: currentPos.x - this.mousePosition.x,
      y: currentPos.y - this.mousePosition.y,
    });

    this.mousePosition = currentPos;

    this.adder.onDrag(currentPos);
    this.draw();
  }

  onDrop(e) {
    this.manipulator.onMouseUp(e);
    this.adder.onDrop(e);
    this.draw();
    this.view.update();
  }

  onDragStart(e) {
    const viewStatus = this.view.getStatus();
    if (viewStatus !== VIEW_STATUSES.IDLE) {
      return;
    }
    this.dragStart = { x: e.clientX, y: e.clientY };
  }

  updateAnimation() {
    if (this.updateAnimationEnabled) {
      window.requestAnimationFrame(this.updateAnimation);
    }
    this.update();
    this.draw();
  }

  update() {
    let canvasRect;
    canvasRect = this.view.canvasRect;

    if (
      canvasRect.width === 0 &&
      canvasRect.height === 0 &&
      this.view.viewRef.current
    ) {
      canvasRect = this.view.viewRef.current.getBoundingClientRect();
    }

    for (let block of this.blocks) {
      block.update();
    }

    this.manipulator.update();
    this.adder.update();
  }
  draw() {
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.scale(devicePixelRatio, devicePixelRatio);

    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

    let designRect = this.ctx.canvas.getBoundingClientRect();
    let rect = this.view.canvasRect;
    this.ctx.translate(rect.x - designRect.x, 0);

    for (let block of this.blocks) {
      block.renderOverlay(this.ctx);
    }

    const viewStatus = this.view.getStatus();
    if (viewStatus !== VIEW_STATUSES.EDITING_TEXT) {
      this.manipulator.renderOverlay(this.ctx);
    }

    this.adder.renderOverlay(this.ctx);
  }
}
