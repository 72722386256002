import clsx from 'clsx';
import { useState } from 'react';
import { Block } from 'types/page';

import styles from './BorderSettings.module.scss';

interface IProps {
  block: Block;
}

const BorderSettings = ({ block }: IProps) => {
  const [selectedRadius, setSelectedRadius] = useState(
    block.borderRadius.value
  );

  const selectRadius = (value: string) => {
    setSelectedRadius(value);
    block.borderRadius.value = value;
    block.view.update();
  };

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.wrapper, {
          [styles.selected]: selectedRadius === '0',
        })}
        onClick={() => selectRadius('0')}
      >
        <div className={clsx(styles.borderType, styles.square)} />
      </div>
      <div
        className={clsx(styles.wrapper, {
          [styles.selected]: selectedRadius === '10px',
        })}
        onClick={() => selectRadius('10px')}
      >
        <div className={clsx(styles.borderType, styles.roundCorners)} />
      </div>
      <div
        className={clsx(styles.wrapper, {
          [styles.selected]: selectedRadius === '30px',
        })}
        onClick={() => selectRadius('30px')}
      >
        <div className={clsx(styles.borderType, styles.round)} />
      </div>
    </div>
  );
};

export default BorderSettings;
