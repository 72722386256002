import { useState, useEffect, useRef } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { Colors } from '../TextEditor/StyleMaps';

import styles from './ColorSelector.module.scss';

interface IProps {
  color: string;
  onChange: (value: string) => void;
  onSelectorToggle?: (value: boolean) => void;
}

const ColorSelector = (props: IProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedColor, setColor] = useState(props.color);

  const sketchPickerRef = useRef(null);
  const colorSelectedRef = useRef(false);

  const noColorStyle = {
    backgroundColor: 'black',
    backgroundImage:
      'linear-gradient(to bottom right, transparent 47%, red 45%, red 53%, transparent 53%)',
  };

  const onColorClick = () => {
    setDisplayColorPicker(!displayColorPicker);
    if (props.onSelectorToggle) props.onSelectorToggle(true);
  };

  const onClose = () => {
    setDisplayColorPicker(false);
    if (props.onSelectorToggle) props.onSelectorToggle(false);
  };

  // const applyColor = useMemo(() => {
  //   return debounce((color) => {
  //     props.onChange(color);
  //   }, 50);
  // }, [props.onChange])

  const changeColorByPicker = (value: ColorResult) => {
    setColor(value.hex);
    props.onChange(value.hex);
    // applyColor(value.hex);
  };

  useEffect(() => {
    if (props.color !== selectedColor) {
      setColor(props.color);
    }
  }, [props.color]);

  useEffect(() => {
    if (!displayColorPicker) return;

    const sketchPicker = document.querySelector('.sketch-picker');
    if (!sketchPicker) return;

    const handleMouseUp = () => {
      setTimeout(() => {
        if (colorSelectedRef.current) {
          colorSelectedRef.current = false;
          onClose();
        }
      }, 500);
    };
    sketchPicker.addEventListener('mouseup', handleMouseUp);

    const transparentDiv = sketchPicker.lastElementChild?.querySelector(
      '[title="transparent"]'
    );
    if (!transparentDiv) return;

    if (!transparentDiv.firstElementChild) return;
    transparentDiv.firstElementChild.setAttribute(
      'style',
      `border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset;
        position: absolute;inset: 0px;
        background-color: black;
        background-image: linear-gradient(to bottom right, transparent 45%, red 45%, red 53%, transparent 53%)`
    );

    return () => {
      sketchPicker.removeEventListener('mouseup', handleMouseUp);
    };
  }, [displayColorPicker]);

  return (
    <>
      <div
        className={styles.viewBlock}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onColorClick}
        style={
          selectedColor === 'transparent'
            ? noColorStyle
            : { background: selectedColor }
        }
      />
      {displayColorPicker && (
        <div className={styles.popover}>
          <div className={styles.cover} onClick={onClose} />
          <SketchPicker
            ref={sketchPickerRef}
            width="188px"
            disableAlpha
            presetColors={Colors}
            onChange={changeColorByPicker}
            onChangeComplete={() => {
              colorSelectedRef.current = true;
            }}
            color={selectedColor}
          />
        </div>
      )}
    </>
  );
};

export default ColorSelector;
