import { ReactComponent as MoveIcon } from 'Assets/move.svg';
import { kBlockReorderHandler } from 'Canvas/Manipulator';
import { Block } from 'types/page';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

interface IProps {
  block: Block;
  onToggleManipulatorVisibility: (value: boolean) => void;
  enabled: boolean;
}

const MoveAction = ({
  block,
  enabled,
  onToggleManipulatorVisibility,
}: IProps) => {
  const handleMouseDown = (e: any) => {
    e.preventDefault();
    onToggleManipulatorVisibility(false);

    const view = block.view;
    const manipulator = view.overlay.manipulator;
    const localMouseMove = view.toLocal({ x: e.clientX, y: e.clientY });
    manipulator.onMouseDownForHandler(localMouseMove, kBlockReorderHandler);

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = (e: any) => {
    e.preventDefault();

    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    const view = block.view;
    const manipulator = view.overlay.manipulator;
    manipulator.onMouseUpForHandler(kBlockReorderHandler);

    onToggleManipulatorVisibility(true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    const view = block.view;
    const manipulator = view.overlay.manipulator;
    const movement = { x: e.movementX, y: e.movementY };
    const localMouseMove = view.toLocal({ x: e.clientX, y: e.clientY });
    manipulator.onMouseMoveForHandler(
      localMouseMove,
      movement,
      kBlockReorderHandler
    );
  };

  return (
    <ToolbarManipulatorButton
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      enabled={enabled}
    >
      <MoveIcon />
    </ToolbarManipulatorButton>
  );
};

export default MoveAction;
