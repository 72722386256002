import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ZoomOutIcon } from '../Assets/zoomOut.svg';
import { ReactComponent as ZoomInIcon } from '../Assets/zoomIn.svg';

const ZoomComponentStyle = styled.span`
  padding: 5px;
  border-radius: 5px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  margin: 0 2px;
  overflow: hidden;

  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2000;

  .center {
    padding: 0px 7px 0px 7px;
    width: 50px;
    text-align: center;
  }

  .icon {
    padding-top: 3px;
  }
`;

const ZoomComponent = (props) => {
  return (
    <ZoomComponentStyle>
      <ZoomOutIcon className="icon" onClick={(v) => props.onClick(-1)} />
      <div className="center">{Math.round(props.value * 100)}%</div>

      <ZoomInIcon className="icon" onClick={(v) => props.onClick(+1)} />
    </ZoomComponentStyle>
  );
};

export default ZoomComponent;
