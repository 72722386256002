import clsx from 'clsx';
import {
  LegacyRef,
  ElementType,
  HTMLAttributes,
  DetailedHTMLProps,
} from 'react';
import ToolbarManipulatorButton from '../ToolbarManipulatorButton/ToolbarManipulatorButton';

import styles from './ToolbarManipulatorSubmenuButton.module.scss';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  enabled?: boolean;
  reference?: LegacyRef<HTMLDivElement>;
  Icon: ElementType;
  text: string;
}

const ToolbarManipulatorSubmenuButton = ({
  className,
  children,
  Icon,
  text,
  ...rest
}: IProps) => {
  return (
    <ToolbarManipulatorButton
      className={clsx(styles.button, className)}
      {...rest}
    >
      <Icon />
      {text}
      {children}
    </ToolbarManipulatorButton>
  );
};

export default ToolbarManipulatorSubmenuButton;
