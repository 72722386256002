export const CREATE_UPSELL_PAYMENT_INTENT2 = {
  operationName: 'createUpsellPaymentIntent2',
  query: `mutation createUpsellPaymentIntent2(
      <KEYS>
    ) {
      createUpsellPaymentIntent2(
        <VALUES>
      ) {
        message
      }
    }`,
};
