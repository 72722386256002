import React from 'react';
import styled from 'styled-components';
import Tooltip from 'Components/Common/Tooltip/Tooltip';
import { ReactComponent as CloseIcon } from '../Assets/close.svg';
import { ReactComponent as PencilIcon } from '../Assets/icons/pencil.svg';

const ExitModalButtonsStyled = styled.span`
  display: inline-block;
  overflow: hidden;
  border: 0px #c6cacf solid;
  color: #000;
  display: flex;

  > label {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    background: white;
    border-radius: 5px 0px 0px 5px;

    > input {
      display: none;
    }

    > input + div {
    }

    &:first-of-type {
      margin-right: 1px;
      div {
        border-radius: 5px 0px 0px 5px;

        span {
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    &:last-of-type {
      position: relative;
      top: 1px;
      border-radius: 0px 5px 5px 0px;
      div {
        border-radius: 0px 5px 5px 0px;
        svg {
          path {
            fill: black;
          }
        }
      }
    }

    &:last-of-type > input + div {
      width: 30px;
    }

    > input:checked + div {
      background: #000000;
      // border-color: #067fff;

      > span {
        color: #fff;
      }
    }

    &:last-of-type > input:checked + div {
      background: #ffffff;
    }

    > div {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
    }
  }
`;

const ExitModalButtons = (props) => {
  const handleChangeEnabled = (e) => {
    props.onChangeEnabled(e.target.checked);
  };

  const handleChangeView = (e) => {
    props.onChangeView(e.target.checked);
  };

  return (
    <ExitModalButtonsStyled>
      <Tooltip overlayContent="Turn on/off exit intent popup">
        <label>
          <input
            type="checkbox"
            checked={props.isModalEnabled}
            onChange={handleChangeEnabled}
          />
          <div>
            <span>Pop Up</span>
          </div>
        </label>
      </Tooltip>
      <Tooltip
        overlayContent={!!props.isModalOpened ? 'Exit popup' : 'Edit popup'}
      >
        <label>
          <input
            type="checkbox"
            checked={props.isModalOpened}
            onChange={handleChangeView}
          />
          <div>
            {!!props.isModalOpened ? (
              <CloseIcon height={16} />
            ) : (
              <PencilIcon width={16} height={16} />
            )}
          </div>
        </label>
      </Tooltip>
    </ExitModalButtonsStyled>
  );
};

export default ExitModalButtons;
