import { Block } from 'types/page';
import { ReactComponent as ArrowDownIcon } from 'Assets/icons/arrowDown.svg';
import {
  b64toBlob,
  getLinkWithoutCDN,
  generateBookCoverThumbnail,
} from 'utils/functions';
import ToolbarManipulatorSubmenuButton from '../../../Common/ToolbarManipulatorSubmenuButton/ToolbarManipulatorSubmenuButton';

interface IProps {
  block: Block;
  enabled?: boolean;
}

const DownloadBookCoverAction = ({ block, enabled }: IProps) => {
  const handleClick = async () => {
    block.image.onImageChange(getLinkWithoutCDN(block.image.value));
    const imageSrc = await generateBookCoverThumbnail(800);
    if (!imageSrc) return;

    const imageURL = URL.createObjectURL(b64toBlob(imageSrc));
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'book cover.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ToolbarManipulatorSubmenuButton
      onClick={handleClick}
      enabled={enabled}
      Icon={ArrowDownIcon}
      text="Download Book Cover Image"
    />
  );
};

export default DownloadBookCoverAction;
