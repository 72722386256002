import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { AIBookGenerator } from './bookGeneration';
import queries from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchSectionsMenu } from '../../rx/actions/rxFetchSectionsMenu';

import Notification from './Notification/Notification';

import LoadingSpinner from '../Common/LoadingSpinner/LoadingSpinner';

import { rxBlocks } from 'rx/rxState';

import EditorStateContext from 'Pages/EditorStateContext';
import View from 'Canvas/View';
import Toolbar from './Toolbar/Toolbar';

import CoverEditor from 'CoverEditor/CoverEditor';

const CanvasStyle = styled.div`
  position: relative;
  height: 100%;

  &.regular {
    background-image: url(${process.env.PUBLIC_URL}/assets/bg.png);
  }

  @media (max-width: 670px) {
    width: 100dvw;
  }
`;

const BookCoverStyle = styled.div`
  padding-bottom: 5px;
`;

const BookEditorView = (props) => {
  const { bookId } = useParams();
  const view = useRef(null);
  const [editor, setEditor] = useState({});

  const [bookData, setBookData] = useState(props.bookData); //TODO: this is weird to have another later of data for whole book when we have PROPS for it.

  useLayoutEffect(() => {
    if (bookId && props.liveMode === true) {
      //Only works for LIVE MODE when user is viewing book via public URL
      //TODO: probably need to move it out from here.
      const getBookDataById = async () => {
        const bookDataById = await graphQlCall({
          queryTemplateObject: queries.GET_BOOK_BY_ID,
          values: {
            id: bookId,
          },
        });

        if (bookDataById) {
          if (bookDataById.data.blocks) {
            const dataForView = {
              blocks: bookDataById.data.blocks,
              lastId: bookDataById.data.lastId,
            };
            rxBlocks.next(dataForView);
          } else {
            await fetchSectionsMenu();

            const newGeneratedChapters = bookDataById.chapters.reduce(
              (acc, el, index) => {
                if (el.text) {
                  acc.push({
                    title: el.title,
                    chapter: el.text,
                    bookName: bookDataById.title,
                  });
                }
                return acc;
              },
              []
            );

            const result = AIBookGenerator.build({
              chapters: newGeneratedChapters,
              coverUrl: bookDataById.coverImageUrl,
              bookTitle: bookDataById.title,
            });
            rxBlocks.next(result);
          }
          setBookData(bookDataById);
        }
      };
      getBookDataById();
    }

    if (props.referenceCallback) {
      props.referenceCallback(view.current);
    }
  }, []);

  useEffect(() => {
    if (props.bookData) {
      setBookData(props.bookData);
    }
  }, props.bookData);

  const setEditorState = (data) => {
    if (data === null) {
      setEditor({ state: null });
      return;
    }
    let colorStyleMap = data.colorStyleMap;
    if (colorStyleMap === undefined) {
      colorStyleMap = editor.colorStyleMap;
    }

    let sizeStyleMap = data.sizeStyleMap;
    if (sizeStyleMap === undefined) {
      sizeStyleMap = editor.sizeStyleMap;
    }

    setEditor({
      state: data.state,
      colorStyleMap,
      sizeStyleMap,
    });
  };
  let className;
  if (!props.liveMode) {
    className = 'regular';
  }

  const bookCoverEditorComponent = () => {
    return (
      <BookCoverStyle>
        {bookData && Object.keys(bookData.coverData).length !== 0 && (
          <CoverEditor
            titles={bookData.coverData.titles} //TODO: super weird way. need to refactor
            width={800}
            height={1142}
            onChangeData={(data) => {
              props.onCoverDataChanged(data);
              setBookData({ ...bookData, coverData: data });
            }}
            readOnly={props.liveMode}
            data={bookData.coverData}
            onItemsChanged={props.onCoverItemsChanged}
          />
        )}
      </BookCoverStyle>
    );
  };

  return (
    <EditorStateContext.Provider
      value={{
        editor: editor,
        setEditorState: setEditorState.bind(this),
      }}
    >
      {!props.liveMode && (
        <>
          <Toolbar />
          {props.inProgress && (
            <Notification>
              <LoadingSpinner />
              <div>
                Your book is currently being generated. Once the generation
                process is complete, you will have the ability to fully edit
                your book.
              </div>
            </Notification>
          )}
        </>
      )}

      <CanvasStyle id="view-canvas-book" className={className}>
        <View
          headerComponent={bookCoverEditorComponent()}
          ref={view}
          id="liveView"
          key="editor-view"
          width="800"
          liveMode={props.liveMode}
          page={'1'}
          username={'2'}
          productId={bookId}
          productMode={'4'}
          modalView={false}
          isBook={true}
          generationInProgress={props.inProgress}
        />
      </CanvasStyle>
    </EditorStateContext.Provider>
  );
};

export default BookEditorView;
