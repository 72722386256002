import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import s from './StripePlans.module.scss';
import { StripeProducts } from 'Constants';
import signUpWorkFlow from '../../../Assets/signUpWorkFlow.svg';
import arrowDown from '../../../Assets/arrowDown.svg';
import checkMark from '../../../Assets/checkMark.svg';
import { ReactComponent as RightBigSvg } from '../../../Assets/rightBigArrow.svg';
import { VersionStripeType, PlanType, PricePeriod } from 'types/signup';

import Spinner from 'Components/Common/Spinner/Spinner';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

const planBenefits = {
  basic: [
    '20 Funnels',
    '50+ Templates',
    '1,000 Customers',
    '1 Domain',
    '1 User',
    'Email Support',
  ],
  plus: [
    '50 Funnels',
    '100+ Templates',
    '10,000 Customers',
    '3 Domain',
    '3 User',
    'Priority Support',
  ],
  pro: [
    'Unlimited Funnels',
    '150+ Templates',
    'Unlimited Customers',
    '10+ Domains',
    '10+ Users',
    '24/7 Chat Support',
  ],
};

const AICreditRange = {
  basic: {
    min: 100,
    max: 1000,
  },
  plus: {
    min: 250,
    max: 2500,
  },
  pro: {
    min: 500,
    max: 5000,
  },
};
interface IProps {
  email?: string;
  versionPage: VersionStripeType;
  onSubmit?: (
    priceId: string,
    priceData?: { price: number; priceGap: string }
  ) => void;
  discount?: boolean;
}

const StripePlans = (props: IProps) => {
  const isVersionWithInput = props.versionPage === 'withInput';

  const planInfoOverflow = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    if (!mediaQuery.matches) return;
    // if (planInfoOverflow.current) {
    //   planInfoOverflow.current.scrollTo( planInfoOverflow.current.scrollWidth / 2 - planInfoOverflow.current.clientWidth / 2, 0)
    // }
    setPlanTypeSelected('Basic');
  }, [planInfoOverflow]);

  const [planSelectIsOpen, setPlanSelectIsOpen] = useState(false);
  const [plan, setPlan] = useState<PricePeriod>('monthly');

  const [basicAICreditValue, setBasicAICreditValue] = useState(
    AICreditRange.basic.min
  );
  const [basicPriceValue, setBasicPriceValue] = useState(0);

  const [plusAICreditValue, setPlusAICreditValue] = useState(
    AICreditRange.plus.min
  );
  const [plusPriceValue, setPlusPriceValue] = useState(0);

  const [proAICreditValue, setProAICreditValue] = useState(
    AICreditRange.pro.min
  );
  const [proPriceValue, setProPriceValue] = useState(0);
  const [planTypeSelected, setPlanTypeSelected] = useState<PlanType>('Basic');

  useEffect(() => {
    setBasicPriceValue(getPriceByValue(basicAICreditValue, 'Basic'));
    setPlusPriceValue(getPriceByValue(plusAICreditValue, 'Plus'));
    setProPriceValue(getPriceByValue(proAICreditValue, 'Pro'));
  }, [plan]);

  useLayoutEffect(() => {
    if (props.discount) {
      setPlan('annuallyDiscount');
    }
  }, []);

  const getPriceByValue = (
    value: number,
    planType: string,
    key: string = 'price'
  ) => {
    let data = StripeProducts[planType as keyof object][plan as keyof object];
    let values = Object.keys(data);
    let index = 0;
    let distance = 10000000000;
    for (let i = 0; i < values.length; i++) {
      let d = Math.abs(value - parseInt(values[i]));
      if (d < distance) {
        distance = d;
        index = i;
      }
    }

    const price = data[values[index] as keyof object][key as keyof object];
    return price;
  };

  const basicAICreditValueChange = (e: any) => {
    setBasicAICreditValue(e.target.value);
    const price = getPriceByValue(e.target.value, 'Basic');
    setBasicPriceValue(price);
  };

  const plusAICreditValueChange = (e: any) => {
    setPlusAICreditValue(e.target.value);
    const price = getPriceByValue(e.target.value, 'Plus');
    setPlusPriceValue(price);
  };
  const proAICreditValueChange = (e: any) => {
    setProAICreditValue(e.target.value);
    const price = getPriceByValue(e.target.value, 'Pro');
    setProPriceValue(price);
  };

  const handleChangePlan = async () => {
    let value = 0;
    if (planTypeSelected === 'Basic') {
      value = basicAICreditValue;
    } else if (planTypeSelected === 'Plus') {
      value = plusAICreditValue;
    } else if (planTypeSelected === 'Pro') {
      value = proAICreditValue;
    }

    const priceId = getPriceByValue(value, planTypeSelected, 'id');

    if (props.versionPage == 'custom') {
      if (props.onSubmit) {
        props.onSubmit(priceId, {
          price: getPriceByValue(value, planTypeSelected),
          priceGap: plan,
        });
      }
      return;
    }

    setLoading(true);
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.AF_CREATE_CHECKOUT_SESSION,
      values: {
        priceId,
        email: props.email,
        lm_data: window.lm_data,
      },
    });
    setLoading(false);
    if (response.sessionUrl) {
      window.open(response.sessionUrl, '_self');
    }
  };

  const getPlanBenefits = (benefits: string[]) => {
    return benefits.map((el: string, index) => {
      return (
        <div
          className={s.planBenefitsItem}
          key={index}
          style={{ fontSize: '16px' }}
        >
          <img src={checkMark} alt="check mark" />
          {el}
        </div>
      );
    });
  };

  const getRangeInput = (
    min: number,
    max: number,
    value: number,
    setValue: (arg: any) => void
  ) => {
    return (
      <div className={s.inputRange}>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={setValue}
          style={{
            width: '100%',
            borderRadius: '2px',
            background: '#D0D0D0',
            outline: 'none',
            height: '8px',
            cursor: 'pointer',
          }}
        />
        <div className={s.inputRangeValueLimits}>
          <div>{min}</div>
          <div>{max}+</div>
        </div>
      </div>
    );
  };
  return (
    <div className={isVersionWithInput ? s.content : s.contentWithoutInput}>
      <img src={signUpWorkFlow} alt="autoFunnel logo" className={s.logo} />

      {isVersionWithInput ? (
        <>
          <h2>Try it free for 5 days</h2>
          <h3>A plan for every size of business.</h3>
        </>
      ) : (
        <>
          <h2>Choose a Plan</h2>
          <h3>Upgrade your plan anytime for advanced features</h3>
        </>
      )}
      {/* <div className={isVersionWithInput ? '' : s.planSelectWrapperWithoutInput}>
        <div className={s.billingText}>
          Billing: <s>Save {props.discount ? "50%" : "20%"}</s> with Annual
        </div>
        {!props.discount && <div
          className={s.planSelect}
          onClick={() => {
            setPlanSelectIsOpen(!planSelectIsOpen);
          }}
        >
          <p>{plan}</p> <img src={arrowDown} alt="arrow down" />
          <div
            className={s.planSelectInner}
            style={
              planSelectIsOpen ? { display: 'block' } : { display: 'none' }
            }
          >
            <div
              onClick={() => {
                setPlan('monthly');
                setPlanSelectIsOpen(false);
              }}
            >
              {plan === 'monthly' && <img src={checkMark} alt={'check mark'} />}
              Monthly
            </div>
            <div
              onClick={() => {
                setPlan('annually');
                setPlanSelectIsOpen(false);
              }}
            >
              {plan === 'annually' && <img src={checkMark} alt={'check mark'} />}
              Annually
            </div>
          </div>
        </div>}

      </div> */}
      <div className={s.planInfoOverflow} ref={planInfoOverflow}>
        <div className={s.planInfoWrapper}>
          <div
            className={
              planTypeSelected === 'Basic' ? s.planItemPrimary : s.planItem
            }
            onMouseEnter={() => setPlanTypeSelected('Basic')}
            style={isVersionWithInput ? {} : { paddingBottom: '30px' }}
          >
            <h2>{isVersionWithInput ? 'Basic' : 'Starter'}</h2>
            <h3>For hobbyists just getting started</h3>
            <div className={s.priceBlock}>
              $
              {plan === 'monthly'
                ? basicPriceValue
                : Math.ceil(basicPriceValue / 12)}{' '}
              <s>/mo</s>
            </div>
            <div
              className={
                isVersionWithInput ? s.description : s.descriptionWithoutInput
              }
            >
              Access to all base template. Connect your domain an launch up to
              20 funnels using our AI
            </div>
            {isVersionWithInput && (
              <div>
                {getRangeInput(
                  AICreditRange.basic.min,
                  AICreditRange.basic.max,
                  basicAICreditValue,
                  basicAICreditValueChange
                )}
              </div>
            )}
            <div className={s.planBenefitsBlock}>
              {getPlanBenefits(planBenefits.basic)}
            </div>
            {!loading && (
              <div
                className={
                  planTypeSelected === 'Basic' ? s.startBtnPrimary : s.startBtn
                }
                onClick={() => {
                  handleChangePlan();
                }}
              >
                Try For Free <RightBigSvg />
              </div>
            )}
            {loading && planTypeSelected === 'Basic' && (
              <div className={s.spinnerBlock}>
                <Spinner size={36} />
              </div>
            )}
          </div>
          <div
            className={
              planTypeSelected === 'Plus' ? s.planItemPrimary : s.planItem
            }
            onMouseEnter={() => setPlanTypeSelected('Plus')}
            style={isVersionWithInput ? {} : { paddingBottom: '30px' }}
          >
            <h2>Plus</h2>
            <h3>For Individuals and Small Teams</h3>
            <div className={s.priceBlock}>
              $
              {plan === 'monthly'
                ? plusPriceValue
                : Math.ceil(plusPriceValue / 12)}{' '}
              <s>/mo</s>
            </div>
            <div
              className={
                isVersionWithInput ? s.description : s.descriptionWithoutInput
              }
            >
              Meant for individuals and small teams looking to launch and scale
              a medium sized business quickly.
            </div>
            {isVersionWithInput && (
              <div>
                {getRangeInput(
                  AICreditRange.plus.min,
                  AICreditRange.plus.max,
                  plusAICreditValue,
                  plusAICreditValueChange
                )}
              </div>
            )}
            <div className={s.planBenefitsBlock}>
              {getPlanBenefits(planBenefits.plus)}
            </div>
            {!loading && (
              <div
                className={
                  planTypeSelected === 'Plus' ? s.startBtnPrimary : s.startBtn
                }
                onClick={() => {
                  handleChangePlan();
                }}
              >
                Try For Free <RightBigSvg />
              </div>
            )}
            {loading && planTypeSelected === 'Plus' && (
              <div className={s.spinnerBlock}>
                <Spinner size={36} />
              </div>
            )}
          </div>
          <div
            className={
              planTypeSelected === 'Pro' ? s.planItemPrimary : s.planItem
            }
            onMouseEnter={() => setPlanTypeSelected('Pro')}
            style={isVersionWithInput ? {} : { paddingBottom: '30px' }}
          >
            <h2>Pro</h2>
            <h3>For Businesses & Agencies</h3>
            <div className={s.priceBlock}>
              $
              {plan === 'monthly'
                ? proPriceValue
                : Math.ceil(proPriceValue / 12)}{' '}
              <s>/mo</s>
            </div>
            <div
              className={
                isVersionWithInput ? s.description : s.descriptionWithoutInput
              }
            >
              Business and Agencies have unlimited access to the number of
              funnels and 24/7 support from our tech team.
            </div>
            {isVersionWithInput && (
              <div>
                {getRangeInput(
                  AICreditRange.pro.min,
                  AICreditRange.pro.max,
                  proAICreditValue,
                  proAICreditValueChange
                )}
              </div>
            )}
            <div className={s.planBenefitsBlock}>
              {getPlanBenefits(planBenefits.pro)}
            </div>
            {!loading && (
              <div
                className={
                  planTypeSelected === 'Pro' ? s.startBtnPrimary : s.startBtn
                }
                onClick={() => {
                  handleChangePlan();
                }}
              >
                Try For Free <RightBigSvg />
              </div>
            )}
            {loading && planTypeSelected === 'Pro' && (
              <div className={s.spinnerBlock}>
                <Spinner size={36} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripePlans;
