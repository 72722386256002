import { rxHistory, rxBlocks, rxHistoryStage, eventEmitter } from '../rxState';

const HISTORY_DEEP = 20;

export const addHistory = (state) => {
  let historyState = [...state.history];
  let step = state.historyStage;
  const blocks = state.blocks;

  if (step === historyState.length - 1) {
    if (historyState.length >= HISTORY_DEEP) {
      historyState.shift();
    } else {
      step++;
      rxHistoryStage.next(step);
    }
    historyState.push(blocks);
  } else {
    historyState = historyState.slice(0, step);
    historyState.push(blocks);
    step++;
    rxHistoryStage.next(step);
  }
  rxHistory.next(historyState);
};

export const undoHistory = (state) => {
  eventEmitter.next({ type: 'freeze-handlers' });
  let historyState = [...state.history];
  let step = state.historyStage;
  if (step >= historyState.length) {
    step--;
  }
  if (step < 0) {
    eventEmitter.next({ type: 'run-handlers' });
    return;
  }
  rxBlocks.next([]);
  rxBlocks.next(historyState[step]);
  rxHistoryStage.next(step - 1);
};

export const redoHistory = (state) => {
  eventEmitter.next({ type: 'freeze-handlers' });
  let historyState = [...state.history];
  let step = state.historyStage;
  const index = step < 0 ? 1 : step + 1;

  if (index >= historyState.length) {
    eventEmitter.next({ type: 'run-handlers' });
    return;
  }
  rxBlocks.next([]);

  rxBlocks.next(historyState[index]);
  rxHistoryStage.next(index);
};
