import { useRef } from 'react';
import clsx from 'clsx';
import { ReactComponent as ArrowDownIcon } from 'Assets/icons/arrowDown.svg';
import CloseSvg from 'Assets/icons/x.svg';

import s from './RerollInput.module.scss';

interface IProps {
  onSubmit: () => void;
  text: string;
  onTextChange: (newText: string) => void;
  loading: boolean;
  onCancel: () => void;
  percent: number;
}

const RerollInput = (props: IProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleBlur = () => {
    if (!inputRef.current) {
      return;
    }
    const value = inputRef.current.value;
    props.onTextChange(value);
  };

  return (
    <div className={s.rerollInput}>
      {props.loading ? (
        <div className={s.loadingBar}>
          <div className={s.progressBar}>
            <div
              className={s.progressPercent}
              style={{ width: `${props.percent}%` }}
            ></div>
          </div>
          <button className={s.cancelButton} onClick={props.onCancel}>
            Cancel &nbsp;
            <img src={CloseSvg} alt="x" />
          </button>
        </div>
      ) : (
        <div className={s.textareaWrapper}>
          <textarea
            className={clsx(s.aiInput, {
              [s.leftScrollbar]: props.text.length > 0,
            })}
            value={props.text}
            rows={5}
            onChange={(e) => {
              e.stopPropagation();
              props.onTextChange(e.target.value);
            }}
            onBlur={() => handleBlur()}
            ref={inputRef}
          />
          <button className={s.aiSubmit} onClick={props.onSubmit}>
            <ArrowDownIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default RerollInput;
