import { Block } from 'types/page';
import { rxManipulatorInUse } from 'rx/rxState';
import { useObservable } from 'utils/UseObservable';
import { isAdmin } from 'utils/api';
import { ActionType } from '../types';
import { actionComponents, actionGroups } from './constants';
import ManipulatorSubmenuActions from '../ManipulatorSubmenuActions/ManipulatorSubmenuActions';

import styles from './ManipulatorActions.module.scss';

interface IProps {
  alignment?: 'start' | 'center' | 'end';
  block: Block;
  onToggleManipulatorVisibility: (value: boolean) => void;
}

const ManipulatorActions = ({
  block,
  alignment = 'center',
  onToggleManipulatorVisibility,
}: IProps) => {
  const manipulatorInUse = useObservable(rxManipulatorInUse);

  const actionGroup = actionGroups[block.type];

  if (actionGroup === undefined) return null;

  return (
    <div className={styles.mainContainer}>
      <div
        className={styles.groupContainer}
        style={{ justifyContent: alignment }}
      >
        {actionGroup.map((actions, groupIndex) => (
          <div
            key={block.type + block.id + groupIndex}
            className={styles.buttonContainer}
          >
            {actions.map((action, index) => {
              if (action.isAdmin && !isAdmin()) return null;

              if (action.type === ActionType.Submenu) {
                const Icon = action.icon!;
                return (
                  <ManipulatorSubmenuActions
                    key={block.type + index}
                    enabled={!manipulatorInUse}
                    block={block}
                    icon={<Icon />}
                    children={action.children!}
                  />
                );
              } else {
                const Component =
                  action.additionalAction ?? actionComponents[action.type];
                return (
                  <Component
                    key={block.type + index}
                    enabled={!manipulatorInUse}
                    block={block}
                    onToggleManipulatorVisibility={
                      onToggleManipulatorVisibility
                    }
                  />
                );
              }
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManipulatorActions;
