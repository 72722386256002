import styles from './ErrorMessage.module.scss';

interface IProps {
  text: string;
  color?: string;
  prefixIcon?: React.ReactNode;
  postfixIcon?: React.ReactNode;
}

const ErrorMessage = ({
  text,
  color = '#ff4f4f',
  prefixIcon,
  postfixIcon,
}: IProps) => {
  return (
    <div className={styles.errorMessage} style={{ color }}>
      {prefixIcon}
      {text}
      {postfixIcon}
    </div>
  );
};

export default ErrorMessage;
