import clsx from 'clsx';
import { DetailedHTMLProps, InputHTMLAttributes, LegacyRef } from 'react';

import styles from './ToolbarManipulatorInput.module.scss';

interface IProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  reference?: LegacyRef<HTMLInputElement>;
}

const ToolbarManipulatorInput = ({ className, reference, ...rest }: IProps) => {
  return (
    <input
      ref={reference}
      className={clsx(styles.input, className)}
      {...rest}
    />
  );
};

export default ToolbarManipulatorInput;
