import React, { useEffect, useRef, useState } from 'react';
import s from './sectionsPopup.module.scss';

import { useObservable } from 'utils/UseObservable';
import {
  rxBlockIndexForSectionsPopup,
  rxSideMenu,
  rxCreationData,
} from 'rx/rxState';
import Sections from './Sections/Sections';
import { ReactComponent as CloseSvg } from '../../Assets/closeModal.svg';
import Spinner from 'Components/Common/Spinner/Spinner';

function SectionsPopup(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectSectionIndex, setSelectSectionIndex] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const menuItems = useObservable(rxSideMenu);
  const sectionsContent = useRef();

  const generateSectionsBlock = () => {
    if (activeMenu === null) {
      return;
    }
    const subMenu = menuItems[activeMenu].items;
    if (!subMenu || !subMenu.length) {
      return;
    }

    const handleSectionClick = (sectionData) => {
      rxCreationData.next({
        data: sectionData,
        blockType: 'section',
        index: selectSectionIndex,
      });
      handleClose();
    };

    function divideArray(array) {
      const result = [];
      let currentSection = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].type === 'devider') {
          currentSection = [];
          result.push({ sections: currentSection, devider: array[i].label });
        } else {
          currentSection.push(array[i]);
        }
      }
      return result;
    }
    const subMenuDivideArr = divideArray(subMenu);
    return (
      <Sections
        sections={subMenuDivideArr}
        handleSectionClick={handleSectionClick}
      />
    );
  };

  const handleClickMenu = (i) => {
    sectionsContent.current.scrollTop = 0;
    setActiveMenu(i);
  };

  const handleClose = () => {
    rxBlockIndexForSectionsPopup.next(-1);
    setSelectSectionIndex(null);
  };

  useEffect(() => {
    const subscription = rxBlockIndexForSectionsPopup.subscribe(
      (sectionIndex) => {
        if (sectionIndex >= 0) {
          setIsOpen(true);
          setSelectSectionIndex(sectionIndex);
        } else {
          setIsOpen(false);
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (menuItems && !!menuItems.length && activeMenu === null) {
      setActiveMenu(0);
    }
  }, [menuItems]);
  if (!isOpen) {
    return null;
  }
  if (menuItems && !!menuItems.length) {
    return (
      <div className={s.container}>
        {menuItems && (
          <div className={s.menuItemsBlock}>
            {menuItems.map((menuItem, i) => {
              return (
                <div
                  className={s.menuItemBlock}
                  onClick={() => handleClickMenu(i)}
                  key={i}
                >
                  <button className={s.button}>{menuItem.name}</button>
                  <div
                    className={i === activeMenu ? s.borderPrimary : s.border}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className={s.sectionsContent} ref={sectionsContent}>
          {generateSectionsBlock()}
        </div>

        <CloseSvg className={s.closeIcon} onClick={() => handleClose()} />
      </div>
    );
  } else
    return (
      <div className={s.containerForSpinner}>
        <Spinner size={250} />
        <CloseSvg className={s.closeIcon} onClick={() => handleClose()} />
      </div>
    );
}

export default SectionsPopup;
