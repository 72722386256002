export const SAVE_PAGE_MUTATION = {
  operationName: 'savePage',
  query: `mutation savePage(
      <KEYS>
    ) {
      savePage(
        <VALUES>
      ) {
        message
      }
    }`,
};
