export const UPDATE_FUNNEL_MUTATION = {
  operationName: 'updateFunnel',
  query: `mutation updateFunnel(
      <KEYS>
    ) {
      updateFunnel(
        <VALUES>
      ) {
        description
        name
        title
        _id
        archived
        mailchimpAPI
        mailchimpAudienceId
        mailchimpUsername
        domain
        headerScript
        footerScript
        stripePublicKey
        stripeSecretKey
        customSubDomain,
        pages {
          _id
        }
      }
    }`,
};
