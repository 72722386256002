export const UPDATE_CHAPTER_MUTATION = {
  operationName: 'updateChapter',
  query: `mutation updateChapter(
      <KEYS>
    ) {
      updateChapter(
        <VALUES>
      ) {
        title
        text
        comments
      }
    }`,
};
