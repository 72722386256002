import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import styles from './Item.module.scss';

export interface DraggableHandle {
  id: number;
  locked?: boolean;
  forcedBlur?: () => void;
  rect: () => DOMRect | undefined;
}

interface IProps {
  id: number;
  width: number;
  height: number;
  scale: number;
  x: number;
  y: number;
  frozen: boolean;
  locked?: boolean;
  children: JSX.Element[] | JSX.Element;
  onSelected?: () => void;
  onForcedBlur?: () => void;
}

const Draggable = forwardRef<DraggableHandle, IProps>((props, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      id: props.id,
      locked: props.locked,
      forcedBlur: props.onForcedBlur,
      rect: rect,
    }),
    [props.locked]
  );

  const rect = () => {
    return containerRef.current?.getBoundingClientRect();
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (props.onSelected) {
      props.onSelected();
    }
  };

  // const handleMouseUp = (event: React.MouseEvent<HTMLElement>) =>{
  //     setMouseDown( false );
  // }

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, {
        [styles.frozen]: props.frozen === true,
      })}
      onMouseDown={handleMouseDown}
      // onMouseUp={handleMouseUp}
      style={{
        width: props.width,
        height: props.height,
        pointerEvents: props.onSelected ? 'auto' : 'none',
        transform: `translate(${props.x}px, ${props.y}px) scale(${props.scale})`,
      }}
    >
      {props.children}
    </div>
  );
});

export default Draggable;
