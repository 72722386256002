export const GET_AI_PAGE = {
  operationName: 'getAiPage',
  query: `query getAiPage(
      $pageId: String!
    ) {
      getAiPage(
        pageId: $pageId
      ) {
        questions {
          id
          label
        }
        name
      }
    }`,
};
