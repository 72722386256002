import { useEffect, useState, useRef, RefObject } from 'react';
import {
  Editor,
  ContentState,
  SelectionState,
  BlockMap,
  RichUtils,
  EditorState,
  ContentBlock,
  convertToRaw,
  convertFromRaw,
  CharacterMetadata,
  genKey,
  Modifier,
} from 'draft-js';
import { List, Repeat, Map, OrderedMap } from 'immutable';

import styles from './Item.module.scss';

import Draggable, { DraggableHandle } from './Draggable';
import clsx from 'clsx';

import FontManager from '../FontManager';

const _MAX_SCALE = 15;

export interface Font {
  family: string;
  weight: number;
  style: string;
}

interface TextEntity {
  key: string;
  text: string;
  scale: number;
}

interface IProps {
  id: number;
  style: any;
  text: string;
  textEditorState?: any;
  width: number;
  height: number;
  scale: number;
  x: number;
  y: number;
  frozen: boolean;
  editable: boolean;
  font: Font;
  dynamicWrap?: boolean;
  textHAlign?: string;
  // positionReferenceId?: number;
  onSelected: (senderRef: RefObject<DraggableHandle>) => boolean;
  onChange?: (
    senderRef: RefObject<DraggableHandle>,
    editorState: EditorState
  ) => void;
}

const ItemText = (props: IProps) => {
  const [editorState, setEditorSate] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const draggableRef = useRef<DraggableHandle>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const textEditorRef = useRef<Editor>(null);
  const [textEditMode, setTextEditMode] = useState(false);
  const [textFitScale, setTextFitScale] = useState(1);

  // const mouseDragged = useRef(false);
  const mouseDragDistance = useRef(0);

  const onChange = (es: EditorState) => {
    // if (props.dynamicWrap) {
    //   es = processDynamicWrap(es);
    // }
    setEditorSate(es);

    if (props.onChange) {
      props.onChange(draggableRef, editorState);
    }
  };

  const fitTextByWidth = () => {
    if (textContainerRef.current && draggableRef.current) {
      const textWidth =
        textContainerRef.current.getBoundingClientRect().width / textFitScale;
      const draggableWidth = draggableRef.current.rect()!.width;
      if (textWidth > draggableWidth) {
        const scale = draggableWidth / textWidth;
        // console.log('scale:', scale);
        setTextFitScale(scale);
      } else {
        setTextFitScale(1);
      }
    }
  };

  // const processDynamicWrap = (es: EditorState) => {
  //   const parentWidth = draggableRef.current!.rect()!.width / props.scale - 40;
  //   const content = es.getCurrentContent();
  //   const blocks = content.getBlockMap();
  //   blocks.forEach((value, key) => {
  //     const element = document.querySelector(
  //       `span[data-offset-key="${key}-0-0"]`
  //     );
  //     if (element) {
  //       let originalScale = Number(element.getAttribute('data-scale'));
  //       if (originalScale === 0) {
  //         originalScale = 1;
  //       }
  //       const rect = element.getBoundingClientRect();
  //       const width = rect.width / originalScale / props.scale;
  //       const height = rect.height / originalScale / props.scale;

  //       if (parentWidth && width) {
  //         const parent = element.parentElement;
  //         let scale = parentWidth / width;
  //         if (scale > _MAX_SCALE) {
  //           scale = _MAX_SCALE;
  //         }
  //         element.setAttribute('data-scale', String(scale));
  //         if (parent) {
  //           parent.style.transform = `scale(${scale})`;
  //           parent.style.transformOrigin = 'top';
  //           parent.style.height = `${height * scale}px`;
  //           parent.style.margin = `${-7 * scale}px 0 ${-10 * scale}px 0`;
  //         }
  //       }
  //     }
  //   });

  //   return es;
  // };

  const handleBlur = () => {
    setTextEditMode(false);
  };

  const handleMouseDown = () => {
    const isSameSelected = props.onSelected(draggableRef);
    mouseDragDistance.current = 0;
  };

  const handleMouseUp = () => {
    const isSameSelected = props.onSelected(draggableRef);
    if (isSameSelected && mouseDragDistance.current < 10 && !props.frozen) {
      setTextEditMode(true);
    }
    mouseDragDistance.current = 0;
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    if (event.buttons === 1) {
      mouseDragDistance.current += Math.sqrt(
        Math.pow(event.movementX, 2) + Math.pow(event.movementY, 2)
      );
    }
  };

  useEffect(() => {
    if (props.font) {
      FontManager.getInstance().loadFont(props.font.family, props.font.weight);
    }
  }, [props.font]);

  useEffect(() => {
    // if (props.dynamicWrap) {
    //   const entities: TextEntity[] = [];
    //   const components = props.text.split(' ');
    //   var es: EditorState = EditorState.createEmpty();

    //   var blocks: BlockMap = OrderedMap<string, ContentBlock>();

    //   for (let i = 0; i < components.length; i++) {
    //     const contentState = es.getCurrentContent();
    //     const charData = CharacterMetadata.create();
    //     const newBlock = new ContentBlock({
    //       key: genKey(),
    //       type: 'align-center-item',
    //       text: components[i],
    //       characterList: List(Repeat(charData, components[i].length)),
    //     });
    //     blocks = blocks.set(newBlock.getKey(), newBlock);
    //   }

    //   es = EditorState.push(
    //     es,
    //     ContentState.createFromBlockArray(blocks.toArray()),
    //     'insert-characters'
    //   );

    //   setEditorSate(es);

    //   setTimeout(() => {
    //     onChange(es);
    //   }, 200);

    // } else {

    setEditorSate(textToEditorState(props.text));

    // }
  }, [props.text]);

  //NOTE: we need to call this AFTER props.text props updated.
  //NOTE: props.text is needed if outside template got updated so we can bring new style but the same text.
  useEffect(() => {
    if (props.textEditorState) {
      //TODO: patch that will prevent crash. Some time when Draft convert in to RAW JSON it is not adding entity Map at all.
      if (props.textEditorState.entityMap === undefined) {
        props.textEditorState.entityMap = {};
      }

      const es = EditorState.createWithContent(
        convertFromRaw(props.textEditorState)
      );
      setEditorSate(es);
    } else {
      setEditorSate(textToEditorState(props.text));
    }
  }, []);

  useEffect(() => {
    fitTextByWidth();
  }, [editorState, props.style]);

  const textToEditorState = (text: string) => {
    let es = editorStateFromText(text);
    let textAlignment = 'align-center-item';
    if (props.textHAlign) {
      textAlignment = `align-${props.textHAlign}-item`;
    }
    return RichUtils.toggleBlockType(es, textAlignment);
  };

  const editorStateFromText = (text: string) => {
    const content = ContentState.createFromText(text);
    return EditorState.createWithContent(content);
  };

  const processStyles = () => {
    let fontStyle = {};
    if (props.font) {
      fontStyle = {
        fontFamily: props.font.family,
        fontWeight: props.font.weight,
        fontStyle: props.font.style,
      };
    }
    return {
      ...props.style,
      ...fontStyle,
    };
  };

  const blockStyleFn = (block: ContentBlock) => {
    const type = block.getType();
    switch (type) {
      case 'align-left-item':
        return 'align-left-item';
      case 'align-center-item':
        return 'align-center-item';
      case 'align-right-item':
        return 'align-right-item';
    }
    return '';
  };

  return (
    <Draggable
      id={props.id}
      width={props.width}
      height={props.height}
      x={props.x}
      y={props.y}
      scale={props.scale}
      frozen={props.frozen}
      locked={textEditMode}
      // onSelected={handleSelected}
      ref={draggableRef}
      onForcedBlur={handleBlur}
      // positionReferenceId={props.positionReferenceId}
    >
      <div
        className={clsx(styles.textContainer, {
          [styles.noTextSelect]: !textEditMode,
        })}
        style={processStyles()}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div
          className={clsx(styles.text)}
          style={{ transform: `scale(${textFitScale})` }}
          ref={textContainerRef}
        >
          <Editor
            ref={textEditorRef}
            readOnly={!textEditMode}
            onBlur={handleBlur}
            editorState={editorState}
            stripPastedStyles={true}
            spellCheck={true}
            onChange={onChange}
            blockStyleFn={blockStyleFn}
            // blockRenderMap={blockRenderMap}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default ItemText;
