import React from 'react';

import s from './Progress.module.scss';

const ProgressBar = (props) => {
  const { completed, isLabelVisible = true } = props;

  return (
    <div className={s.root}>
      <div className={s.container} style={{ width: `${completed}%` }}>
        <span className={s.label}>
          {isLabelVisible ? `${completed}%` : null}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
