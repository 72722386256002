import React, { useEffect, useState } from 'react';
import arrowUp from '../../../../Assets/arrow-up.svg';
import arrowDown from '../../../../Assets/arrow-down.svg';
import s from './Number.module.scss';

interface IProps {
  step: number;
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  tooltip?: string;
}

const NumberInput = (props: IProps) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const filter = (v: number) => {
    return Math.max(Math.min(v, props.max), props.min);
  };

  const handleChange = (e: any) => {
    const v = e.target.value;
    if (v <= props.max && v >= props.min) {
      props.onChange(v);
    }
    setValue(v);
  };

  const handleUp = (e: any) => {
    e.preventDefault();
    const v = filter(props.value + props.step);
    setValue(v);
    props.onChange(v);
  };

  const handleDown = (e: any) => {
    e.preventDefault();
    const v = filter(props.value - props.step);
    setValue(v);
    props.onChange(v);
  };

  return (
    <div className={s.container}>
      <input
        type="number"
        min={props.min}
        max={props.max}
        step={props.step}
        value={value}
        onChange={handleChange}
      />
      <div className={s.nav}>
        <img
          onMouseDown={(e) => e.preventDefault()}
          draggable={false}
          className={s.button}
          src={arrowUp}
          onClick={handleUp}
        />
        <img
          onMouseDown={(e) => e.preventDefault()}
          draggable={false}
          className={s.button}
          src={arrowDown}
          onClick={handleDown}
        />
      </div>
    </div>
  );
};

export default NumberInput;
