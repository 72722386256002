import React, { useEffect, useState } from 'react';
import { ContainerFlat } from './Styles';
import SwitchSmall from '../Components/Common/Switches/SwitchSmall/SwitchSmall';

const AttributeBool = (props) => {
  const [value, setValue] = useState(false);

  const onChange = (block, value) => {
    let attr = props.attribute;
    props.onChange(attr.id, value);
    setValue(value);
  };

  useEffect(() => {
    setValue(props.attribute.value);
  });

  return (
    <ContainerFlat>
      <div className="attribute-label">{props.attribute.displayName}</div>
      <div className="attribute-control">
        <SwitchSmall value={value} onChange={onChange} />
      </div>
    </ContainerFlat>
  );
};

export default AttributeBool;
