import React from 'react';
import s from './SwitchSmall.module.scss';

function SwitchSmall(props) {
  return (
    <div className={s.container}>
      <label className={s.switch}>
        <input
          checked={props.value}
          type="checkbox"
          onChange={(e) => props.onChange(props.block, e.currentTarget.checked)}
        />
        <span className={s.slider} />
      </label>
    </div>
  );
}

export default SwitchSmall;
