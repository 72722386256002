import styles from './Loader.module.scss';

interface IProps {
  size?: number;
  color?: string;
}

const Loader = ({ size = 26, color = '#000000' }: IProps) => {
  return (
    <div className={styles.loader}>
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          className={styles.line}
          style={{ width: size, backgroundColor: color }}
        />
      ))}
    </div>
  );
};

export default Loader;
