import styled from 'styled-components';
import { colors } from './constants';

export default styled.div`
  background-color: ${colors.background};
  font-family: 'Roboto', sans-serif;
  height: 100%;
  h1,
  h5 {
    text-align: center;
    color: #000;
    font-weight: 400;
  }

  h1 {
    font-size: 52px;
    padding-top: 180px;
    margin: 0 auto 5px auto;

    > img {
      width: 220px;
    }
  }

  h5 {
    margin: 0;
    font-size: 16px;
  }
`;
