import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DragIcon } from '../../Assets/drag.svg';
import { rxHistoryStage } from '../../rx/rxState';
import { isAdmin } from '../../utils/api';

export const BlockStyle = styled.div`
  ${(props) =>
    props.isDragging
      ? `box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.5);
        position: absolute;
        z-index: 100;
        width: ${props.width}px;
        background-color: rgb(255,255,255,0.4);
        `
      : null}
`;

const PlaceholderStyle = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  .content {
    background-color: rgb(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    padding: 8px;
  }
  .inner {
    display: flex;
    height: 100%;
    border-style: dotted;
    border-color: #ced2d9;
    border-radius: 5px;
    border-width: 2px;
  }
`;

class BlockProxy extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <BlockStyle
        id={this.props.id}
        key={this.props.id}
        // style={this.props.style}
        ref={this.props.reference}
        className={this.props.className}
      >
        {this.props.children}
      </BlockStyle>
    );
  }
}

export class Block {
  constructor(props) {
    this.type = 'Block';
    this.view = props.view;
    this.id = props.id;
    this.canvas = props.canvas;
    this.proxyRef = React.createRef();
    this.ref = React.createRef();
    this.worldRenderBRect = null;
    this.aiField = null;
    this.className = '';
    this.gridSpan = {
      lg: { start: 0, length: 12 },
      // md: {start:0, length:12},
      sm: { start: 0, length: 12 },
      xs: { start: 0, length: 12 },
    };

    this.offset = { x: 0, y: 0 }; //offset during drag and drop

    this.dirty = true; //dirty means needs update to recaculate some variables//TODO might need to remove this one

    this.isHovered = false;
    this.isSelected = false;
    this.isSelectable = true;
    this.isEditing = false;
    this.isVisible = true;
    this.isDragging = false;
    this.isDraggable = true;

    this.dependents = [];
    this.children = [];
    this.parent = null;
    this.styleText = null;

    this.editingRef = null;
    this.textEditorStateRef = null;

    this.onChangeSubscribers = [];

    this.style = this.style.bind(this);

    this.attributes = [];

    this.padTop = null;
    this.padBottom = null;

    // let attr = {
    //     id: 'paddingTop',
    //     displayName: 'Top',
    //     value: '25px',
    //     type: 'AttributeString',
    // }
    // this.addAttribute( attr );
    // this.paddingTop.visible = false;

    // attr = {
    //     id: 'paddingBottom',
    //     displayName: 'Bottom',
    //     value: '25px',
    //     type: 'AttributeString',
    // }
    // this.addAttribute( attr );
    // this.paddingBottom.visible = false;

    this.isAutoResize = true;
    this.icon = new Image();
    this.icon.src = process.env.PUBLIC_URL + '/assets/icons/move.svg';
  }

  addAttribute(props) {
    this.removeAttribute(props.id); //remove attribute in case it is already created before
    this[props.id] = props;
    this.attributes.push(props);
  }

  removeAttribute(id) {
    const index = this.attributes.findIndex((attr) => attr.id === id);
    if (index >= 0) {
      this.attributes.splice(index, 1);
    }
  }

  clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
  }

  setDirty(value) {
    this.dirty = value;
    for (let block of this.children) {
      block.setDirty(value);
    }
  }

  onAttributeChanged(attr, newValue) {}

  onMouseMove(e) {}

  onMouseDown(e) {
    return false;
  }

  onMouseUp(e) {
    return false;
  }

  columnWidthAndGap() {
    if (this.type !== 'Row') {
      if (this.parent) {
        return this.parent.columnWidthAndGap();
      } else {
        return null;
      }
    }
  }

  setVisible(value) {
    this.isVisible = value;
    this.view.setState({});
  }
  addChild(child, index = -1) {
    child.parent = this;
    if (index < 0) {
      this.children.push(child);
    } else {
      this.children.splice(index, 0, child);
    }
  }
  removeChild(child) {
    let blockIndex = this.children.findIndex((block) => block.id === child.id);
    this.children.splice(blockIndex, 1);
  }
  removeFromParent() {
    if (this.parent) {
      this.parent.removeChild(this);
    } else {
      this.view.removeBlock(this);
    }
  }
  addDependent(value) {
    this.dependents.push(value);
  }

  editingHandle(ref) {
    this.editingRef = ref;
  }

  textEditorStateHandle(ref) {
    this.textEditorStateRef = ref;
  }
  addOnChangeSub(e) {
    this.onChangeSubscribers.push(e);
  }
  removeOnChangeSub(e) {
    let index = this.onChangeSubscribers.indexOf(e);
    if (index >= 0) {
      this.onChangeSubscribers.splice(index, 1);
    }
  }
  rectContainPoint(rect, x, y) {
    if (!rect) {
      return false;
    }
    return (
      rect.x <= x &&
      x <= rect.x + rect.width &&
      rect.y <= y &&
      y <= rect.y + rect.height
    );
  }

  getBlockById(id) {
    if (this.id === id) {
      return this;
    }
    for (let block of this.children) {
      let b = block.getBlockById(id);
      if (b) {
        return b;
      }
    }
    return null;
  }

  getBlocksByType(type) {
    let out = [];
    if (this.type === type) {
      out.push(this);
    }
    for (let block of this.children) {
      out = out.concat(block.getBlocksByType(type));
    }
    return out;
  }

  getBlocksByAiField(aiFieldName) {
    let out = [];
    if (this.aiField && this.aiField.includes(aiFieldName)) {
      out.push(this);
    }
    for (let block of this.children) {
      out = out.concat(block.getBlocksByAiField(aiFieldName));
    }
    return out;
  }

  getAiFieldPrompt() {
    if (this.aiField) {
      return this.view.processAiFieldPrompt(this.aiField);
    }
  }

  blockAtPoint(x, y, ignoreBlock) {
    for (let block of this.children) {
      let b = block.blockAtPoint(x, y, ignoreBlock);
      if (b) {
        return b;
      }
    }
    if (
      this.rectContainPoint(this.worldRenderBRect, x, y) &&
      this.isSelectable &&
      this.isVisible
    ) {
      if (ignoreBlock) {
        if (ignoreBlock !== this) {
          return this;
        }
      } else {
        return this;
      }
    }
    return null;
  }

  setDragging(value) {
    this.isDragging = value;
    this.offset.x = 0;
    this.offset.y = 0;
  }

  setSelected(value) {
    this.isSelected = value;
  }

  setHovered(value) {
    this.isHovered = value;
    if (value == false) {
      for (let child of this.children) {
        child.setHovered(value);
      }
    }
  }

  setEditing(value) {
    this.isEditing = value;
    if (this.editingRef) {
      this.editingRef(value);
    }
  }
  setOffset(value) {
    this.offset = value;
  }

  forceUpdate() {
    if (this.proxyRef.current) {
      this.proxyRef.current.forceUpdate();
      this.update();
    }
  }

  update() {
    if (this.ref.current) {
      this.worldRenderBRect = this.ref.current.getBoundingClientRect();
      this.worldRenderBRect.x -= this.view.canvasRect.x;
      this.worldRenderBRect.y -= this.view.canvasRect.y;
    }

    for (let block of this.children) {
      block.update();
    }
  }

  calculateHeight(styleHeight, geometry) {
    return styleHeight;
  }

  pack() {
    let data = {};
    data['id'] = this.id;
    data['type'] = this.type;
    data['style'] = this.styleText;
    data['styleMobile'] = this.styleMobileText;
    data['className'] = this.className;
    if (this.isSelectable === false) {
      data['isSelectable'] = false;
    }

    if (this.aiField) {
      data['aiField'] = this.aiField;
    }
    if (this.isDraggable === false) {
      data['isDraggable'] = false;
    }
    if (this.isVisible === false) {
      data['isVisible'] = false;
    }
    if (this.name) {
      data['name'] = this.name;
    }
    if (this.canHide !== undefined) {
      data['canHide'] = this.canHide;
    }

    data['padTop'] = this.padTop;
    data['padBottom'] = this.padBottom;

    let children = [];
    for (let child of this.children) {
      let data = child.pack();
      children.push(data);
    }

    data['children'] = children;

    for (let attr of this.attributes) {
      data[attr.id] = attr.value;
    }
    // console.log('pack', data);
    data['gridSpan'] = { ...this.gridSpan };

    return data;
  }

  unpack(data) {
    // console.log('unpack', data);
    this.children = [];
    this.styleText = data['style'];
    this.styleMobileText = data['styleMobile'];
    this.className = data['className'];

    if (data['name']) {
      this.name = data['name'];
    }

    if (data['aiField']) {
      this.aiField = data['aiField'];
    }

    if (data['canHide'] !== undefined) {
      this.canHide = data['canHide'];
    }

    if (data['gridSpan'] !== undefined) {
      this.gridSpan = data['gridSpan'];
    }

    const top = data['padTop'];
    if (top !== undefined && top !== null) {
      this.padTop = parseInt(top);
    }

    const bottom = data['padBottom'];
    if (bottom !== undefined && bottom !== null) {
      this.padBottom = parseInt(bottom);
    }

    for (let child of data['children']) {
      if (child !== null) {
        let block = this.view.createBlock(child.type, child.id);
        block.unpack(child);
        this.addChild(block);
      }
    }

    let selectable = data['isSelectable'];
    if (selectable !== undefined && selectable === false) {
      this.isSelectable = false;
    }
    // let draggable = data['isDraggable'];
    // if(draggable !== undefined && draggable === false){
    //     this.isDraggable = false;
    // }

    let visible = data['isVisible'];
    if (visible !== undefined && visible === false) {
      this.isVisible = false;
    }

    for (let attr of this.attributes) {
      let val = data[attr.id];
      if (val !== undefined) {
        this[attr.id].value = val;
      }
    }
  }
  unpackConnections(data) {}

  setGridSpan(span) {
    var maxLength = 2;
    if (this.type == 'Image') {
      maxLength = 1;
    }

    if (span.length < maxLength) {
      span.start = span.start + span.length - maxLength;
      span.length = maxLength;
    }

    if (span.start < 0) {
      span.length = span.length + span.start;
      span.start = 0;
    }

    if (this.parent && this.parent.type == 'Row') {
      if (span.start + span.length > this.parent.columns) {
        span.length = this.parent.columns - span.start;
      }
    }

    if (span.length < maxLength) {
      span.length = maxLength;
    }

    const size = this.view.getViewSize();
    const oldSpan = this.gridSpan[size];
    this.gridSpan[size] = span;

    if (oldSpan.start == span.start && oldSpan.length == span.length) {
      return false;
    } else {
      return true;
    }
  }

  getGridSpan(size) {
    if (this.gridSpan.length == 0) {
      return '';
    }

    const span = this.gridSpan[size];
    if (span) {
      return span;
    } else {
      console.log('warning: size: ', size, 'not found');
      return { start: 0, length: 12 };
    }
  }

  style(getOriginal) {
    let output;
    if (this.view.isMobileLayout() && this.styleMobileText !== undefined) {
      output = this.styleMobileText;
    } else {
      output = this.styleText;
    }

    if (output === undefined || output === null) {
      output = '';
    }

    const size = this.view.getViewSize();
    const span = this.getGridSpan(size);
    if (span) {
      output += `grid-column:${span.start + 1}/span ${span.length};`;
    }

    if (this.padBottom !== null) {
      output += `padding-bottom: ${this.padBottom}px;`;
    }

    if (this.padTop !== null) {
      output += `padding-top: ${this.padTop}px;`;
    }

    return output;
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  styleDict() {
    //convert CSS style text in to JS dictinary
    let outDict = {};
    let styleText = this.style(true); // getting original CSS styling
    if (!styleText) {
      return outDict;
    }
    let components = styleText.split(';');
    for (let element of components) {
      let parts = element.split(':');
      let keyParts = parts[0].split('-');

      if (keyParts === undefined) {
        outDict[parts[0]] = parts[1];
      } else {
        if (keyParts[0] === '' || parts.length <= 1) {
          continue;
        }

        let key = '';
        let index = 0;
        for (let keyPart of keyParts) {
          if (index === 0) {
            key += keyPart.replace(/\s/g, '');
          } else {
            key += this.capitalizeFirstLetter(keyPart);
          }
          index += 1;
        }
        outDict[key] = parts[1].replace(/\s/g, '');
      }
    }
    return outDict;
  }
  getContentByAiField(fieldName) {
    for (let child of this.children) {
      const content = child.getContentByAiField(fieldName);
      if (content) {
        return content;
      }
    }
    return null;
  }
  getAiFieldsName(names) {
    if (this.aiField) {
      names.push(this.aiField);
    }
    for (let child of this.children) {
      names = child.getAiFieldsName(names);
    }
    return names;
  }

  duplicateBlock() {
    const newBlock = this.view.createBlock(this.type);
    newBlock.unpack(this.pack());

    if (this.parent) {
      const index = this.parent.children.findIndex((b) => b.id === this.id);
      this.parent.addChild(newBlock, index);
    } else {
      const packedData = this.pack();
      const item = this.view.addBlock(packedData);
      this.view.overlay.selectBlock(item);
    }
    this.view.update();
  }

  removeCurrentBlock() {
    this.view.removeBlock(this);
    this.view.overlay.selectBlock(this);
  }

  renderView() {
    return (
      <BlockProxy
        id={this.id}
        key={this.id}
        ref={this.proxyRef}
        reference={this.ref}
        styleText={this.style()}
        className={this.className}
      >
        {this.children.map((child) => {
          return child.renderView();
        })}
      </BlockProxy>
    );
  }

  reroll() {}

  rerollWithAiPrompt() {}

  strokeCorners(ctx, rect, cornerLength) {
    ctx.beginPath();
    ctx.moveTo(rect.x + cornerLength, rect.y);
    ctx.lineTo(rect.x, rect.y);
    ctx.lineTo(rect.x, rect.y + cornerLength);

    ctx.moveTo(rect.x + rect.width - cornerLength, rect.y);
    ctx.lineTo(rect.x + rect.width, rect.y);
    ctx.lineTo(rect.x + rect.width, rect.y + cornerLength);

    ctx.moveTo(rect.x + rect.width - cornerLength, rect.y + rect.height);
    ctx.lineTo(rect.x + rect.width, rect.y + rect.height);
    ctx.lineTo(rect.x + rect.width, rect.y + rect.height - cornerLength);

    ctx.moveTo(rect.x, rect.y + rect.height - cornerLength);
    ctx.lineTo(rect.x, rect.y + rect.height);
    ctx.lineTo(rect.x + cornerLength, rect.y + rect.height);

    ctx.stroke();
  }

  renderOverlay(ctx) {
    let brect = this.worldRenderBRect;
    if (brect === null) {
      return;
    }

    if (isAdmin()) {
      if (this.aiField !== null && this.aiField) {
        ctx.fillStyle = '#ff9d00';
        ctx.beginPath();
        const width = ctx.measureText(this.aiField).width + 10;
        ctx.roundRect(brect.x + 5, brect.y + 5, width, 15, [5]);
        ctx.fill();
        ctx.fillStyle = 'black';
        ctx.fillText(this.aiField, brect.x + 10, brect.y + 16);
      }
    }

    if (this.isDragging) {
      //make it 1 pixel smaller since line is 2 pixels thick.
      brect = {
        x: brect.x + 1,
        y: brect.y + 1,
        width: brect.width - 2,
        height: brect.height - 2,
      };
      ctx.lineWidth = 2;

      ctx.strokeStyle = '#9f9f9f';
      ctx.setLineDash([2, 4]);

      ctx.strokeRect(brect.x, brect.y, brect.width, brect.height);

      ctx.setLineDash([]);

      ctx.fillStyle = 'rgba(255,255,255, 0.4)';
      ctx.fillRect(brect.x, brect.y, brect.width, brect.height);

      let placeholderSize = 80;
      let rect = {
        x: this.offset.x - placeholderSize * 0.5,
        y: this.offset.y - placeholderSize * 0.5,
        width: placeholderSize,
        height: placeholderSize,
      };
      ctx.fillStyle = 'rgba(123, 134, 149, 0.6)';
      ctx.shadowColor = '#6C68FF';
      ctx.shadowBlur = 15;
      ctx.fillRect(rect.x, rect.y, rect.width, rect.height);

      let off = (placeholderSize - 26) / 2.0;
      ctx.drawImage(this.icon, rect.x + off, rect.y + off, 26, 26);
    } else if (this.isSelected || this.isHovered) {
      let rect = this.worldRenderBRect;
      //make it 1 pixel smaller since line is 2 pixels thick.
      rect = {
        x: rect.x + 1,
        y: rect.y + 1,
        width: rect.width - 2,
        height: rect.height - 2,
      };
      ctx.lineWidth = this.isSelected ? 2 : 1;

      ctx.strokeStyle = this.isSelected ? '#6C68FF' : '#9f9f9f';
      if (this.isHovered && !this.isSelected) {
        ctx.setLineDash([2, 4]);
      }

      ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);

      ctx.setLineDash([]);
      if (this.isHovered && !this.isSelected) {
        ctx.strokeStyle = 'rgb(255,255,255)';
        this.strokeCorners(ctx, rect, 10);
      }

      //draw paddings dashes
      if (this.isSelected) {
        ctx.setLineDash([2, 4]);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#6C68FF';

        ctx.beginPath();
        if (this.padTop) {
          const top = this.padTop;

          ctx.moveTo(rect.x, rect.y + top);
          ctx.lineTo(rect.x + rect.width, rect.y + top);
        }

        if (this.padBottom) {
          const bottom = this.padBottom;
          ctx.moveTo(rect.x, rect.y + rect.height - bottom);
          ctx.lineTo(rect.x + rect.width, rect.y + rect.height - bottom);
        }
        ctx.stroke();

        ctx.setLineDash([]);
      }
    }

    for (let child of this.children) {
      child.renderOverlay(ctx);
    }
  }
}
