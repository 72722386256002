import clsx from 'clsx';
import {
  useMemo,
  useState,
  ReactNode,
  HTMLAttributes,
  DetailedHTMLProps,
  useRef,
  useEffect,
  LegacyRef,
} from 'react';
import { ReactComponent as Arrow } from 'Assets/arrow-left.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/check.svg';
import useElementClickedOutside from 'Hooks/useElementClickedOutside';

import styles from './ToolbarManipulatorDropdown.module.scss';

interface IProps<T>
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'onChange'
  > {
  value?: T;
  label: string;
  height?: number;
  options: { label: string; value: T }[];
  onChange: (newValue: T) => void;
}

const ToolbarManipulatorDropdown = <T extends ReactNode>({
  className,
  onChange,
  options,
  height = 40,
  label,
  value,
  ...rest
}: IProps<T>) => {
  const [open, setOpen] = useState(false);

  const { ref } = useElementClickedOutside(() => setOpen(false));
  const dropdownRef: LegacyRef<HTMLUListElement> = useRef(null);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const handleSelect = (option: T) => {
    setOpen(false);
    onChange(option);
  };

  useEffect(() => {
    if (open && dropdownRef.current) {
      const handleWheel = (e: WheelEvent) => {
        e.stopPropagation();
      };

      dropdownRef.current.addEventListener('wheel', handleWheel);

      return () => {
        dropdownRef.current?.removeEventListener('wheel', handleWheel);
      };
    }
  }, [open]);

  const selectedOptionLabel = useMemo(() => {
    const option = options.find((opt) => opt.value === value);
    if (option) return option.label;

    return '';
  }, [value, options]);

  return (
    <div ref={ref} className={clsx(styles.dropdown, className)} {...rest}>
      <span
        className={clsx(styles.selected, { [styles.openState]: open })}
        onClick={toggleDropdown}
        style={{ height }}
      >
        {selectedOptionLabel || <span>{label}</span>} <Arrow />
      </span>
      {open && (
        <ul ref={dropdownRef} className={styles.optionsList}>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelect(option.value)}
              style={{ height }}
            >
              {option.value === value && <CheckMarkIcon />}
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ToolbarManipulatorDropdown;
