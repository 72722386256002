// production constants
export const AUTH_USER_TOKEN_KEY = 'LanderAmplify.TokenKey';
export const API_ENDPOINT = 'http://localhost:3001/';
export const API_VIDEO_UPLOAD = 'https://video.autofunnel.ai/upload';
export const API_VIDEO_STATUS = 'https://video.autofunnel.ai/status';
export const API_AI_PAGE_GENERATOR = 'https://main-532jor4d2q-uc.a.run.app';
export const API_AI_REROLL_TEXT = 'https://main2-ikaevrdlna-uc.a.run.app';
export const API_PAYMENT = 'http://localhost:3002/paymentUp';
export const DESIGN_WIDTH = 1024;

export const COLORS = {
  anchorGuideStroke: '#fa55e9',
  anchorGuideFill: '#ffaaf6',
  anchorGuideAlt: '#fa55e9',
  anchorGuideText: '#2c2c2c',
};

export const FONTS = {
  anchorLabel: '11px Arial',
};

export const S3_PAGES = 'editor.data.live';
export const CONSULT_URL = 'https://app.autofunnel.ai';
export const STRIPE_KEY =
  'pk_live_51MvOYPIw2eOqESjKs9CnD41kxZYR7ocWVq3cvzl2KXyQYxdTKtIwXkcnpkrViXhT48aF5JAV07ahKb9QvLWHHgbE00OPUrS6CN';

export const TEMPLATES_URL = 'https://cdn.autofunnel.ai/templates';
export const AMPLIFY_CLIENT_ID = '15sq4lnuel1ann4q6124vled3d';
export const AMPLIFY_POOL_ID = 'us-east-1_x4GSuj2gz';
export const ENVIRONMENT = 'PROD';
export const GOOGLE_TAG = 'GTM-WMRQR3T'; //TODO: depricated needs to be removed eventually
export const UPLOAD_API = 'https://image-upload.autofunnel.ai';

export const USE_MOCKED_DATA = false;

export const StripeProducts = {
  Basic: {
    monthly: {
      1000: { id: 'price_1Mw6o5Iw2eOqESjKxDrKRS3W', price: '693' },
      750: { id: 'price_1Mw6o5Iw2eOqESjK9lRh8QVu', price: '520' },
      500: { id: 'price_1Mw6o5Iw2eOqESjKTgJGAk6s', price: '346' },
      250: { id: 'price_1Mw6o5Iw2eOqESjKq6ApQgrg', price: '173' },
      100: { id: 'price_1N3jxsIw2eOqESjKo9pCXcOV', price: '149' },
    },
    annually: {
      1000: { id: 'price_1Mw6o5Iw2eOqESjKeqlcMzK5', price: '6648' },
      750: { id: 'price_1Mw6o5Iw2eOqESjK8Zlhl1ji', price: '4992' },
      500: { id: 'price_1Mw6o5Iw2eOqESjKuh4SVKJd', price: '3324' },
      250: { id: 'price_1Mw6o5Iw2eOqESjKLEpDKDZr', price: '1668' },
      100: { id: 'price_1N3k2WIw2eOqESjKmQOHCm0j', price: '1428' },
    },
    annuallyDiscount: {
      100: { id: 'price_1MzDsYIw2eOqESjKUIY6IIzS', price: '600' },
    },
  },
  Plus: {
    monthly: {
      250: { id: 'price_1Mw6nxIw2eOqESjKld0CA17x', price: '199' },
      750: { id: 'price_1Mw6nxIw2eOqESjKP1pA2eDD', price: '332' },
      1250: { id: 'price_1Mw6nxIw2eOqESjKbgfpqCqp', price: '497' },
      1750: { id: 'price_1Mw6nxIw2eOqESjKdxobUsQ1', price: '695' },
      2500: { id: 'price_1Mw6nwIw2eOqESjK3fnodrF0', price: '995' },
    },
    annually: {
      250: { id: 'price_1Mw6nxIw2eOqESjKYaUlb8Ls', price: '1908' },
      750: { id: 'price_1Mw6nxIw2eOqESjKNkSSlTnh', price: '3180' },
      1250: { id: 'price_1Mw6nxIw2eOqESjKEpWEOkQU', price: '4764' },
      1750: { id: 'price_1Mw6nxIw2eOqESjK3dobeAFx', price: '6672' },
      2500: { id: 'price_1Mw6nxIw2eOqESjKoWL5z7V0', price: '9552' },
    },
    annuallyDiscount: {
      250: { id: 'price_1MzDsYIw2eOqESjK8B7rWmMY', price: '1200' },
    },
  },
  Pro: {
    monthly: {
      500: { id: 'price_1NTxQJIw2eOqESjKLxDY31BS', price: '499' },
      1250: { id: 'price_1Mw6noIw2eOqESjKL2yVM3yE', price: '374' },
      2500: { id: 'price_1Mw6noIw2eOqESjKbXqBiIyT', price: '747' },
      3750: { id: 'price_1Mw6noIw2eOqESjKtGHsssg1', price: '1121' },
      5000: { id: 'price_1Mw6noIw2eOqESjKKh1bMWfk', price: '1495' },
    },
    annually: {
      500: { id: 'price_1NTxQjIw2eOqESjK1yw0CK6K', price: '4790' },
      1250: { id: 'price_1Mw6noIw2eOqESjK3fM5ZG4s', price: '3588' },
      2500: { id: 'price_1Mw6noIw2eOqESjK8IGy1eeu', price: '7164' },
      3750: { id: 'price_1Mw6noIw2eOqESjKhSWTR6Q4', price: '10764' },
      5000: { id: 'price_1Mw6noIw2eOqESjKUCa6WD1R', price: '14340' },
    },
    annuallyDiscount: {
      250: { id: 'price_1MzDsYIw2eOqESjKC5j07vl0', price: '1800' },
    },
  },
};

export const PAGECRAFT_API_URL = 'https://pagecraft-api-prod.autofunnel.ai';
export const BOOKS_AI_URL = 'https://book-ai.autofunnel.ai';
export const SOCKET_URL = 'wss://pagecraft-api-prod.autofunnel.ai';
export const AI_COPYRIGHT_URL = 'wss://text-ai.autofunnel.ai/api/v1/stream';
export const SENTRY_DNS =
  'https://841f1bc4950d9348bfbb99cfc2e23c51@o4506429919920128.ingest.sentry.io/4506434832629760';

export const ANALYTIC_API = 'https://pagecraft-analytics-prod.autofunnel.ai';
export const NO_SOCKET_CREDENTIALS = false;
export const ENCRYPTION_KEY = 'm9gHGAn5LCFEWD2y7Ur3qXxSfJ8dck';
