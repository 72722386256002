import React, { useState, useEffect } from 'react';
import s from './LinksControls.module.scss';

import { EditorState, RichUtils } from 'draft-js';
import linkIcon from '../../../Assets/link.svg';
import PrimaryButton from '../../Common/PrimaryButton/PrimaryButton';
import Tooltip from 'Components/Common/Tooltip/Tooltip';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';

const LinksControls = (props) => {
  const [showInput, setShowInput] = useState(false);
  const [currentLink, setCurrentLink] = useState('');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    check(props.editorState);
  }, [props.editorState]);

  const handleAddLink = (link) => {
    console.log('adding new link', link);
    const editorState = props.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: link }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    newEditorState = RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    );

    setShowInput(false);
    setCurrentLink('');
    props.onChange(newEditorState);
  };

  const check = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    let url = '';
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
    }

    setEdit(url !== '');
    setCurrentLink(url);
    return true;
  };

  const handleOpenInput = () => {
    check(props.editorState);

    setShowInput(!showInput);
  };

  const handleDeleteLink = () => {
    const editorState = props.editorState;
    const selection = editorState.getSelection();

    const newEditorState = RichUtils.toggleLink(editorState, selection, null);
    props.onChange(newEditorState);

    setShowInput(false);
  };

  return (
    <div className={s.container}>
      <Tooltip overlayContent="Link">
        <div className={s.previewButton} onClick={handleOpenInput}>
          <img src={linkIcon} />
        </div>
      </Tooltip>
      <div
        className={s.messageBody}
        style={{ visibility: showInput ? 'visible' : 'hidden' }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{ link: currentLink }}
          validationSchema={Yup.object({
            link: Yup.string()
              .required('Link is required')
              .matches(
                /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))\.?(:\d{2,5})?(?:[\/?#]\S*)?$/,
                'Please, enter correct link'
              ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log('values', values);
            setCurrentLink(values.link);
            handleAddLink(values.link);
            setSubmitting(false);
          }}
        >
          <Form className={s.form}>
            <div className={s.field}>
              <Field
                autoComplete={0}
                name={'link'}
                placeholder={'Enter Link'}
                type={'text'}
              />
              <p className={s.error}>
                <ErrorMessage name="link" />
              </p>
            </div>
            <div className={s.buttons}>
              <PrimaryButton
                type="submit"
                width={70}
                height={30}
                fontSize={14}
                name={edit ? 'Update' : 'Add'}
              />
              <PrimaryButton
                width={70}
                height={30}
                fontSize={14}
                name="Delete"
                onClick={handleDeleteLink}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default LinksControls;
