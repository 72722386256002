import clsx from 'clsx';
import { useState, useRef, useEffect, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useObservable } from 'utils/UseObservable';
import {
  rxActiveChapterIndex,
  rxBookDetailsPopup,
  rxCurrentEditorBlockIndex,
  rxCurrentUser,
  rxUpdatePlanPopup,
} from 'rx/rxState';
import { IUserDetails } from 'types/user';
import { ReactComponent as AddNewChapter } from 'Assets/addNewChapter.svg';
import { ReactComponent as InfoIcon } from 'Assets/Info.svg';
import { ReactComponent as BookIcon } from 'Assets/Book.svg';
import { ReactComponent as Premium } from 'Assets/Premium.svg';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import ChapterItemEditor from '../ChapterItem/ChapterItemEditor';
import { IBookChapter, IBookData } from '../types';
import Spinner from 'Components/Common/Spinner/Spinner';

import s from './BookInfoBlockEditor.module.scss';

export interface GenerationError {
  error: string;
  initialGeneration: boolean;
}

interface IProps {
  bookData: IBookData | null;
  onBookTitleEdited: (value: string) => void;
  onChapterTitleEdited: (value: string, index: number) => void;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  reorderChapter: (dragIndex: number, dropIndex: number) => void;
  endReorderChapter: () => void;
  onChapterRegenerate: (index: number) => void;
  onChapterNotesRegenerate: (index: number, notes: string[]) => void;
  addingChapterIndex: null | number;
  onManualRegenerationTrigger: () => void;
  generationError?: GenerationError;
  view: any;
  chapterIndexOffset: number;
}

function BookInfoBlockEditor({
  bookData,
  onBookTitleEdited,
  onChapterTitleEdited,
  deleteChapter,
  onChapterAdd,
  reorderChapter,
  endReorderChapter,
  onChapterRegenerate,
  onChapterNotesRegenerate,
  addingChapterIndex,
  onManualRegenerationTrigger,
  view,
  generationError,
  chapterIndexOffset,
}: IProps) {
  const isGeneratedAllBookEnd = addingChapterIndex === null;
  const [bookTitleInput, setBookTitleInput] = useState<string>(
    bookData?.title ?? ''
  );
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const currentEditorBlockIndex = useObservable<number>(
    rxCurrentEditorBlockIndex
  );
  const [isBookTitleActive, setIsBookTitleActive] = useState<boolean>(false);
  const [isBookTitleLongError, setIsBookTitleLongError] =
    useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastSortingTimestamp, setLastSortingTimestamp] = useState<
    number | null
  >(null);

  const currentUser = useObservable<IUserDetails>(rxCurrentUser);

  const userData = useMemo(() => {
    const scopes = currentUser?.scopes ?? [];
    const legacyAccessMode = !scopes.includes('BOOKLE_GENERAL_ACCESS') || false;

    return {
      scopes,
      legacyAccessMode,
    };
  }, [currentUser]);

  const handleNameInput = (value: string) => {
    let textValue = value;
    if (textValue.length > 150) {
      textValue = textValue.slice(0, 150);
      setIsBookTitleLongError(true);
    } else {
      setIsBookTitleLongError(false);
    }
    const words = textValue.split(' ');
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(' ');

    setBookTitleInput(capitalizedValue);
  };

  // useEffect(()=>{
  //   fetchUserScope();
  // }, [])

  useEffect(() => {
    const subscription = rxBookDetailsPopup.subscribe((data) => {
      if (view) {
        view.setState({ textSelectionDisabled: data.open });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [view]);

  useEffect(() => {
    if (bookData) setBookTitleInput(bookData.title);
  }, [bookData, bookData?.title]);

  useEffect(() => {
    // if (currentEditorBlockIndex === 0) {
    //   setIsBookTitleActive(true);
    // } else {
    //   setIsBookTitleActive(false);
    // }

    if (
      currentEditorBlockIndex !== undefined &&
      currentEditorBlockIndex !== activeChapter
    ) {
      setActiveChapter(currentEditorBlockIndex - chapterIndexOffset);
    }
  }, [currentEditorBlockIndex]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (lastSortingTimestamp && currentTime - lastSortingTimestamp > 1000) {
        endReorderChapter();
      }
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [lastSortingTimestamp]);

  const onTitleInputClick = () => {
    rxActiveChapterIndex.next(0);
  };

  const handleChapterAdding = (
    indexBefore: number,
    action: 'add' | 'insert'
  ) => {
    if (
      !userData.scopes.includes('BOOKLE_ADD_NEW_CHAPTER') &&
      userData.legacyAccessMode == false
    ) {
      rxUpdatePlanPopup.next({
        open: true,
        message: 'Adding new chapters is not available in your plan',
      });
      return;
    }
    onChapterAdd(indexBefore, action);
  };

  const handleSetActiveChapter = (index: number) => {
    setActiveChapter(index);
    rxActiveChapterIndex.next(index + chapterIndexOffset);
  };

  const handleChapterSorting = (res: DropResult) => {
    const { destination, source, draggableId } = res;

    if (!destination) {
      return;
    }
    if (isGeneratedAllBookEnd) {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      setLastSortingTimestamp(Date.now());
      reorderChapter(source.index, destination.index);
    }
  };

  // const handleRestoreCover = () => {
  //   onRestoreCover();
  // };

  return (
    <div>
      <div
        className={clsx(s.bookInfoEditNameInputEditVersion, {
          [s.bookInfoEditNameInputEditVersionActive]: isBookTitleActive,
        })}
      >
        <div className={s.bookInfoEditNameInputInner}>
          <ContentEditableDiv
            name={bookTitleInput}
            fontSize={14}
            lineHeight={20}
            fontWeight={500}
            // width={300}
            height={50}
            onChange={!isGeneratedAllBookEnd ? () => {} : handleNameInput}
            onEdit={!isGeneratedAllBookEnd ? () => {} : onBookTitleEdited}
            onClick={onTitleInputClick}
            blocked={!isGeneratedAllBookEnd}
            suffixIcon={
              <InfoIcon
                onClick={() => {
                  if (isGeneratedAllBookEnd)
                    rxBookDetailsPopup.next({ open: true, bookData });
                }}
              />
            }
            prefixIcon={<BookIcon />}
          />
          {/* {isGeneratedAllBookEnd && <button className={s.restoreButton} onClick={() => handleRestoreCover()}>
            restore
          </button>} */}
          {isBookTitleLongError && (
            <div className={s.errorText}>Maximum characters 150</div>
          )}
        </div>
      </div>
      <DragDropContext onDragEnd={handleChapterSorting}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={s.chaptersBlockEditVersion}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {bookData?.chapters.map((chapter: IBookChapter, index) => (
                <Draggable
                  key={chapter.title + index.toString()}
                  draggableId={`chapter-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      // {...provided.dragHandleProps}
                    >
                      <ChapterItemEditor
                        addChaptersLocked={
                          !userData.scopes.includes('BOOKLE_ADD_NEW_CHAPTER') &&
                          !userData.legacyAccessMode
                        }
                        index={index}
                        chapter={chapter}
                        onChapterTitleEditedProps={onChapterTitleEdited}
                        activeChapter={activeChapter}
                        setActiveChapter={handleSetActiveChapter}
                        deleteChapter={deleteChapter}
                        onChapterAdd={handleChapterAdding}
                        onChapterRegenerate={(i) => onChapterRegenerate(i)}
                        onChapterNotesRegenerate={onChapterNotesRegenerate}
                        isBookTitleActive={isBookTitleActive}
                        addingChapterIndex={addingChapterIndex}
                        setIsBookTitleActive={setIsBookTitleActive}
                        blocked={!isGeneratedAllBookEnd}
                        dragableData={provided}
                        generationError={generationError}
                        onManualRegenerationTrigger={
                          onManualRegenerationTrigger
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              <div
                className={s.addNewChapter}
                onClick={() => {
                  if (isGeneratedAllBookEnd && !generationError) {
                    handleChapterAdding(
                      bookData ? bookData.chapters.length : 0,
                      'add'
                    );
                  }
                }}
              >
                {!isGeneratedAllBookEnd ? (
                  !isGeneratedAllBookEnd ? null : (
                    <Spinner size={25} />
                  )
                ) : (
                  <div style={{ position: 'relative', display: 'flex' }}>
                    {!userData.scopes.includes('BOOKLE_ADD_NEW_CHAPTER') &&
                    !userData.legacyAccessMode ? (
                      <Premium className={s.premiumIconWithOffset} />
                    ) : null}
                    <AddNewChapter />
                  </div>
                )}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default BookInfoBlockEditor;
