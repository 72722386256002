import { useState, useEffect } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * @param {Observable} observable
 */
export const useObservable = <T>(
  observable: BehaviorSubject<any> | Subject<any>
) => {
  const [state, setState] = useState<T>();

  useEffect(() => {
    const sub = observable.subscribe(setState);
    return () => sub.unsubscribe();
  }, [observable]);

  return state;
};
