export const GET_SECTIONS_BY_NAMES = {
  operationName: 'getSectionsByNames',
  query: `query getSectionsByNames(
      $names: [String!]!
    ) {
      getSectionsByNames(
        names: $names
      ) {
        name
        data
      }
    }`,
};
