import { Block } from 'types/page';
import Tabs from 'Components/Common/Tabs/Tabs';
import { blockSettings } from './constants';
import { PrimitiveTypes } from './types';

import styles from './BlockSettings.module.scss';

interface IProps {
  block: Block;
  onValueChange: (attributeId: string, value: PrimitiveTypes) => void;
}

const BlockSettings = ({ block, onValueChange }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Tabs
          className={styles.tabs}
          tabs={[
            {
              title: 'Action',
              content: (
                <div className={styles.settingsGroups}>
                  {blockSettings[block.type]?.action.map(
                    (SettingsComponent) => (
                      <SettingsComponent
                        block={block}
                        onValueChange={onValueChange}
                      />
                    )
                  )}
                </div>
              ),
            },
            {
              title: 'Styles',
              content: (
                <div className={styles.settingsGroups}>
                  {blockSettings[block.type]?.style.map((SettingsComponent) => (
                    <SettingsComponent
                      block={block}
                      onValueChange={onValueChange}
                    />
                  ))}
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default BlockSettings;
