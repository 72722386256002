import { rxExitModalEnabled, rxShowExitModal } from '../rxState';

export const switchView = (state, payload) => {
  const isExitModalView = payload.value;
  rxShowExitModal.next(isExitModalView);
};

export const switchExitEnabled = (state, payload) => {
  rxExitModalEnabled.next(payload.value);
};
