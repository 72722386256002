export const GET_NEXT_PAGE_QUERY = {
  operationName: 'getNextPage',
  query: `query getNextPage(
      $pageId: String!
      $funnelId: String!
    ) {
      getNextPage(
        pageId: $pageId
        funnelId: $funnelId
      ) {
        next
      }
    }`,
};
