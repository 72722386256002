import React, { Component } from 'react';
import s from './Video.module.scss';
import Spinner from '../../Components/Common/Spinner/Spinner';
import { ReactComponent as PlayIcon } from '../../Assets/play.svg';
import { ReactComponent as PauseIcon } from '../../Assets/pause.svg';
import { ReactComponent as PlayIconNew } from '../../Assets/newplay.svg';
import { ReactComponent as RestartIcon } from '../../Assets/newredo.svg';
import { ReactComponent as VolumeIcon } from '../../Assets/newvolume.svg';

export default class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVideoPlaying: false,
      videoTimeStart: 0,
      loading: true,
      progress: '0',
    };
    this.videoPlayer = React.createRef();
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.id !== this.props.id) {
      this.setVideoStartTime();
    }
  }
  componentDidMount() {
    this.setVideoStartTime();
  }

  setVideoStartTime() {
    let time = localStorage.getItem(`video-${this.props.id}-currentTime`);
    if (time === null) {
      time = 0;
    }

    if (!this.props.continueWatching) {
      time = 0;
    }
    this.videoPlayer.current.currentTime = time;
    this.setState({ videoTimeStart: time });
  }

  onTimeUpdate(event) {
    if (this.props.continueWatching) {
      localStorage.setItem(
        `video-${this.props.id}-currentTime`,
        this.videoPlayer.current.currentTime
      );
    }
    if (this.props.onTimeUpdate && this.state.isVideoPlaying) {
      this.props.onTimeUpdate(this.videoPlayer.current.currentTime);
    }

    let progress =
      (this.videoPlayer.current.currentTime /
        this.videoPlayer.current.duration) *
      100;
    this.setState({
      progress: String(progress),
    });
  }

  onPausePlayClick(event) {
    if (this.state.isVideoPlaying) {
      this.videoPlayer.current.pause();
    } else {
      this.videoPlayer.current.play();
    }
    this.setState({ isVideoPlaying: !this.state.isVideoPlaying });
  }

  onTimeSeek(event) {
    // console.log( event);
    var rect = event.target.getBoundingClientRect();
    var x = event.clientX - rect.left; //x position within the element.
    var percentage = x / rect.width;
    // console.log('PERCENTAGE: ', percentage);
    this.videoPlayer.current.currentTime =
      percentage * this.videoPlayer.current.duration;
  }

  onPlayClick(event) {
    this.videoPlayer.current.play();
    this.setState({ isVideoPlaying: true });
  }

  onRestartClick(event) {
    this.videoPlayer.current.currentTime = 0;
    this.videoPlayer.current.play();
    this.setState({ isVideoPlaying: true });
  }

  onVideoCanPlay(event) {
    this.setState({ loading: false });
  }

  render() {
    const smallScreen = window.matchMedia('(max-width: 500px)').matches;
    let overlay;
    if (!this.state.isVideoPlaying) {
      if (this.state.videoTimeStart !== 0) {
        overlay = (
          <div className={`${s.overlay} ${s.loaded}`}>
            <div className={s.label}>
              You've already started watching this video
            </div>
            <div className={s.box}>
              <div
                className={`${s.button} hvr-underline-from-center`}
                onMouseDown={(e) => this.onPlayClick(e)}
              >
                <PlayIconNew style={{ width: smallScreen ? '16px' : '32px' }} />
                <div className={s.label}>Continue Watching</div>
              </div>
              <div
                className={`${s.button} hvr-underline-from-center`}
                onMouseDown={(e) => this.onRestartClick(e)}
              >
                <RestartIcon style={{ width: smallScreen ? '20px' : '40px' }} />
                <div className={s.label}>Start From Beginning</div>
              </div>
            </div>
          </div>
        );
      } else {
        overlay = (
          <div
            className={`${s.overlay} ${s.loaded}`}
            onMouseDown={(e) => this.onPlayClick(e)}
          >
            <div className={s.box}>
              <div className={`${s.button} hvr-underline-from-center`}>
                <VolumeIcon style={{ width: smallScreen ? '20px' : '40px' }} />
                <div className={s.label}>
                  Click to {this.props.autoplay ? 'Unmute' : 'Play'}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    let controls = (
      <div className={s.control}>
        <div className={s.box}>
          <div
            className={s.button}
            onMouseDown={(e) => this.onPausePlayClick(e)}
          >
            {this.state.isVideoPlaying ? (
              <PauseIcon style={{ width: '15px' }} />
            ) : (
              <PlayIcon style={{ width: '15px' }} />
            )}
          </div>
          <div className={s.seekbar} onMouseDown={(e) => this.onTimeSeek(e)}>
            <div
              className={s.inner}
              style={{ width: `${this.state.progress}%` }}
            />
          </div>
        </div>
      </div>
    );

    let loading = (
      <div className={`${s.overlay} ${s.loading}`}>
        <Spinner size={110} />
      </div>
    );

    if (this.props.inEditor || this.props.loop) {
      overlay = null;
    }
    return (
      <div>
        <div className={s.container}>
          {(this.props.playBar && !this.props.loop) || this.props.inEditor
            ? controls
            : null}
          {this.state.loading === false ? overlay : loading}
          <video
            className={s.video}
            onLoadedMetadata={(e) => this.onVideoCanPlay(e)}
            onCanPlay={(e) => this.onVideoCanPlay(e)}
            onTimeUpdate={(e) => this.onTimeUpdate(e)}
            ref={this.videoPlayer}
            width={this.props.width}
            height={this.props.height}
            playsInline={true}
            loop={this.props.loop}
            autoPlay={
              this.props.loop
                ? true
                : this.props.autoplay
                ? this.state.videoTimeStart === 0
                : false
            }
            muted={
              this.state.videoTimeStart === 0 && !this.state.isVideoPlaying
            }
          >
            <source src={this.props.url} />
          </video>
        </div>
      </div>
    );
  }
}
