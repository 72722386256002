export const GET_PAGE_EDIT_INFO_QUERY = {
  operationName: 'getPageEditInfo',
  query: `query getPageEditInfo(
      $id: String!
    ) {
      getPageEditInfo(
        id: $id
      ) {
        pages {
          name
          data
          image
          enabled
          aiSuggestion
          exitpopupdata
          exitpopupenabled
        }
        order {
          id
          previous
          next
        }
        title
        description
        generalId
        products {
          name
          descriptor
          price
          Currency
        }
        stripePublicKey
        headerScript
        footerScript
        funnelHeaderScript
        funnelFooterScript
      }
    }`,
};
