export const STRIPE_GET_CUSTOMER_FROM_SESSION_QUERY = {
  operationName: 'getCustomerFromSession',
  query: `query getCustomerFromSession(
      $sessionId: String!
    ) {
      getCustomerFromSession(
        sessionId: $sessionId
      ) {
        name
        email
        id
      }
    }`,
};
