import React from 'react';
import s from './Spinner.module.scss';
import spinner from '../../../Assets/spinner2.svg';
import spinnerLogo from '../../../Assets/spinnerLogo.svg';

function Spinner({ size }) {
  return (
    <div
      className={s.container}
      style={{ height: `${size}px`, width: `${size}px`, margin: 'auto' }}
    >
      <img className={s.spinner} src={spinner} alt="" />
      <img className={s.logo} src={spinnerLogo} alt="" />
    </div>
  );
}

export default Spinner;
