export const CREATE_PAYMENT_INTENT_2 = {
  operationName: 'createPaymentIntent2',
  query: `mutation createPaymentIntent2(
      <KEYS>
    ) {
      createPaymentIntent2(
        <VALUES>
      ) {
        intent
      }
    }`,
};
