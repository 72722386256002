import { rxProducts } from '../rxState';
import { graphQlCall } from '../../graphql/utils';
import QUERIES from '../../graphql/queries';

export const fetchProducts = async () => {
  try {
    const products = await graphQlCall({
      queryTemplateObject: QUERIES.FETCH_PRODUCTS,
      headerType: 'USER-AUTH',
    });
    const productsValue = rxProducts.value;
    rxProducts.next(productsValue.concat(products));
  } catch (error) {
    console.log('error:', error);
  }
};

export const fetchAllProducts = async (pageId) => {
  try {
    const stripeProducts = await graphQlCall({
      queryTemplateObject: QUERIES.FETCH_PRODUCTS,
      headerType: 'USER-AUTH',
    });

    const userProducts = await graphQlCall({
      queryTemplateObject: QUERIES.GET_USER_PRODUCTS,
      headerType: 'USER-AUTH',
    });

    const funnelProducts = await graphQlCall({
      queryTemplateObject: QUERIES.GET_FUNNEL_PRODUCTS_BY_PAGE_ID,
      headerType: 'USER-AUTH',
      values: {
        pageId: pageId,
      },
    });

    let allProduncts = [];

    let products = userProducts.map((userProduct) => ({
      id: `user-${userProduct._id}`,
      name: userProduct.name,
      type: 'USER',
      prices: [
        {
          id: userProduct._id,
          unit_amount: userProduct.price * 100,
          currency: userProduct.currency,
        },
      ],
      data: {
        descriptor: userProduct.descriptor,
      },
    }));

    allProduncts = [...products];

    products = stripeProducts.map((stripeProduct) => ({
      id: stripeProduct.id,
      name: stripeProduct.name,
      type: 'STRIPE',
      prices: stripeProduct.prices.map((price) => ({
        id: price.id,
        currency: price.currency,
        unit_amount: price.unit_amount,
      })),
    }));

    allProduncts = [...allProduncts, ...products];

    products = funnelProducts.map((funnelProduct, index) => ({
      id: `funnel-${funnelProduct.name}-${index}`,
      name: funnelProduct.name,
      type: 'FUNNEL',
      prices: [
        {
          id: funnelProduct.name,
          unit_amount: funnelProduct.price * 100,
          currency: funnelProduct.currency,
        },
      ],
      data: {
        descriptor: funnelProduct.descriptor,
        isSubscription: funnelProduct.isSubscription,
        isSevenDayTrial: funnelProduct.isSevenDayTrial,
      },
    }));

    allProduncts = [...allProduncts, ...products];

    // rxProducts.next(allProduncts);

    const productsValue = rxProducts.value;
    rxProducts.next(productsValue.concat(allProduncts));
  } catch (error) {
    console.error('ERROR fetching all products', error);
  }
};
