import React, { useState } from 'react';
import PopUpWrapper from './PopupWrapper';
import LogInComponent from 'Components/LogInWrapper/LogInWrapper';

interface IValues {
  email: string;
  password: string;
}

interface IProps {
  onSubmit: (values: IValues) => void;
  onSwitchToSignUp: () => void;
  open: boolean;
  onClose: () => void;
}

const LogIn = (props: IProps) => {
  return props.open ? (
    <PopUpWrapper wide={true} onClose={props.onClose}>
      <LogInComponent
        isModal={true}
        onSwitchToSignUp={() => props.onSwitchToSignUp()}
      />
    </PopUpWrapper>
  ) : null;
};

export default LogIn;
