import React, { useState, useEffect } from 'react';

import style from './fakeLoading.module.scss';

const FakeLoading = ({ timeForOnePercent, textForLoading }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState(null);

  useEffect(() => {
    if (textForLoading) {
      setProgressText(textForLoading[0]);
    }

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 1;
        }
      });
    }, timeForOnePercent);

    const timer = setTimeout(() => {
      // setIsLoading(false);
    }, 50000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!textForLoading) {
      return;
    }
    if (progress >= 0 && progress < 10) {
      setProgressText(textForLoading[0]);
    }
    if (progress >= 10 && progress < 20) {
      setProgressText(textForLoading[1]);
    }
    if (progress > 20 && progress < 30) {
      setProgressText(textForLoading[2]);
    }
    if (progress > 30 && progress < 40) {
      setProgressText(textForLoading[3]);
    }
    if (progress > 40 && progress < 50) {
      setProgressText(textForLoading[4]);
    }
    if (progress > 50 && progress < 60) {
      setProgressText(textForLoading[5]);
    }
    if (progress > 60 && progress < 70) {
      setProgressText(textForLoading[6]);
    }
    if (progress > 70 && progress < 80) {
      setProgressText(textForLoading[7]);
    }
    if (progress > 80 && progress < 90) {
      setProgressText(textForLoading[8]);
    }
    if (progress > 90 && progress < 100) {
      setProgressText(textForLoading[9]);
    }
    if (progress === 100) {
      setProgressText(textForLoading[10]);
    }
  }, [progress]);
  return isLoading ? (
    <div className={style.loadingIndicatorWrapper}>
      {textForLoading && (
        <div className={style.loadingIndicatorText}>{progressText}</div>
      )}
      <div className={style.loadingIndicator}>
        <div
          className={style.loadingIndicatorBar}
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  ) : null;
};

export default FakeLoading;
