import { Block } from 'types/page';
import RequiredFieldWrapper from 'Components/Common/RequiredFieldWrapper/RequiredFieldWrapper';
import ToolbarManipulatorInput from '../../Common/ToolbarManipulatorInput/ToolbarManipulatorInput';
import ToolbarManipulatorDropdown from '../../Common/ToolbarManipulatorDropdown/ToolbarManipulatorDropdown';

import styles from './AttributesQuickAccessAction.module.scss';

interface IProps {
  block: Block;
  onToggleManipulatorVisibility: (value: boolean) => void;
}

const AttributesQuickAccessAction = ({
  block,
  onToggleManipulatorVisibility,
}: IProps) => {
  const handleValueChange = (
    attributeId: string,
    value: string | number | boolean
  ) => {
    block.onAttributeChanged(attributeId, value);
    block[attributeId].value = value;
    block.view.overlay.update();
    onToggleManipulatorVisibility(true);
  };

  const requiredAttributes = block.attributes
    .filter((attr: any) => attr.visible)
    .map((attr: any) => {
      let component;

      switch (attr.type) {
        case 'String':
          component = (
            <ToolbarManipulatorInput
              value={attr.value}
              placeholder={attr.placeholder}
              onChange={(e) => handleValueChange(attr.id, e.target.value)}
            />
          );
          break;
        case 'Dropdown':
          component = (
            <ToolbarManipulatorDropdown
              label={attr.displayName}
              value={attr.value}
              options={attr.options}
              onChange={(value: string) => handleValueChange(attr.id, value)}
            />
          );
          break;
        default:
          break;
      }

      return { value: attr.value, component };
    });

  return requiredAttributes.map(
    (attr: any) =>
      attr && (
        <RequiredFieldWrapper
          missingValue={!attr.value}
          className={styles.requiredFieldWrapper}
        >
          {attr.component}
        </RequiredFieldWrapper>
      )
  );
};

export default AttributesQuickAccessAction;
