import React, { useEffect, useState, useRef } from 'react';

import blankPagePlaceholder from '../../Assets/blankPagePlaceholder.svg';
import blankPageArrow from '../../Assets/blankPageArrow.svg';

import textIcon from '../../Assets/elements/Text.svg';
import buttonIcon from '../../Assets/elements/Button.svg';
import imageIcon from '../../Assets/elements/Image.svg';
import videoIcon from '../../Assets/elements/Video.svg';
import containerIcon from '../../Assets/elements/Container.svg';
import timerIcon from '../../Assets/elements/Timer.svg';
import formIcon from '../../Assets/elements/Form.svg';
import stripeIcon from '../../Assets/elements/Stripe.svg';
import customIcon from '../../Assets/elements/Custom.svg';

import {
  rxCreationData,
  // rxSideMenu,
  // rxSections,
  // eventEmitter,
  // rxBlocks,
  // rxIsLoading
} from '../../rx/rxState';

// import {ReactComponent as ArrowLeft} from '../../Assets/arrow-left-white.svg';

import StyledSideMenu from './StyledSideMenu';
// import {useObservable} from '../../utils/UseObservable';
// import {TEMPLATES_URL} from '../../Constants';
import Modal from '../Common/Modal/Modal';
import PrimaryButton from '../Common/PrimaryButton/PrimaryButton';
import clsx from 'clsx';
import { isAdmin } from '../../utils/api';
// import { AIGenerator, generateAIPage } from '../../page-ai-generator/generation';

const kBlockAdder = [
  {
    name: 'Text',
    type: 'Text',
    options: {},
    icon: textIcon,
  },
  {
    name: 'Button',
    type: 'Button',
    options: {},
    icon: buttonIcon,
  },
  {
    name: 'Image',
    type: 'Image',
    options: {},
    icon: imageIcon,
  },
  {
    name: 'Video',
    type: 'Video',
    options: {},
    icon: videoIcon,
  },
  {
    name: 'Timer',
    type: 'Timer',
    options: {},
    icon: timerIcon,
  },
  {
    name: 'Form',
    type: 'Form',
    options: {},
    icon: formIcon,
  },
  {
    name: 'Stripe',
    type: 'Stripe',
    options: {},
    icon: stripeIcon,
  },
  {
    name: 'Container',
    type: 'Row',
    options: {},
    icon: containerIcon,
  },
  {
    name: 'Custom',
    type: 'Custom',
    options: {},
    icon: customIcon,
  },
];

const SideMenu = () => {
  // const menuItems = useObservable(rxSideMenu);
  // const sections = useObservable(rxSections);
  // const [activeMenu, setActiveMenu] = useState(null);
  // const [countItemSubMenu,setCountItemSubMenu] = useState(1);    //current count of items in submenu
  // const [displayColorPicker, setDisplayColorPicker] = useState(false);

  // const [preload, setPreload] = useState(false);
  const [isTutorialActive, setIsTutorialActive] = useState(false);

  // const subMenuRef = useRef();
  // const endSubMenuRef = useRef();
  // let subMenuLength = 0;

  // const handleClickMenu = (i) => {
  //     // subMenuRef.current.scrollTop = 0
  //     setActiveMenu(i);
  //     setTimeout(500, () => eventEmitter.next({type: 'update-view'})) // trigger view updated after .5 seconds. this is how long side menu transition
  // }

  // const handleCloseSubMenu = () => {
  //     setActiveMenu(null);
  //     setTimeout(500, () => eventEmitter.next({type: 'update-view'})) // trigger view updated after .5 seconds. this is how long side menu transition
  // }

  // /**
  //  * 1. Render/Load items during initial page load.
  //  * 2. Then render/load next set of items only in case if viewport height can't contain all existing items during the scrolling.
  //  * */
  // const subMenuAddItem = () => {
  //     if (menuItems && menuItems.length > 0 && menuItems[activeMenu]) {
  //         subMenuLength = menuItems[activeMenu].items.length;
  //         if(subMenuRef.current && endSubMenuRef.current && countItemSubMenu + 1 <= subMenuLength) {
  //             const bottomSubMenuRef = subMenuRef.current.getBoundingClientRect().bottom;
  //             const topEndSubMenuRef = endSubMenuRef.current.getBoundingClientRect().top;

  //             if(topEndSubMenuRef - 10 < bottomSubMenuRef) {
  //                 setCountItemSubMenu(countItemSubMenu + 5)
  //             }
  //         }
  //     }
  // };

  // //Here add item while we have all of them on the page or the items get the end of the page
  // useEffect(() => {
  //     subMenuAddItem()
  // })

  // useEffect(() => {
  //     const getEditorStatus = () => localStorage.getItem('wasEditorVisited');
  //     const setEditorStatus = (value) => localStorage.setItem('wasEditorVisited', value);

  //     if (menuItems && menuItems.length > 0 && preload === false) {
  //         if (getEditorStatus() === null) {
  //             handleClickMenu(0);
  //             setIsTutorialActive(true);
  //             setEditorStatus(true);
  //         }
  //     }
  // })

  const onDragStart = (event) => {
    const id = event.currentTarget.id;
    const data = kBlockAdder[id];
    if (data) {
      rxCreationData.next({
        data,
        blockType: data.type,
      });
    }
  };

  const onDragEnd = (event) => {
    if (event.dataTransfer.dropEffect === 'none') {
      //Drop got Canceled
      rxCreationData.next({
        blockType: null,
      });
    }
  };
  return (
    <>
      <StyledSideMenu>
        <div className={clsx('menu', { admin: isAdmin() })}>
          {kBlockAdder.map((block, i) => (
            <div
              key={i}
              id={i}
              className={'menu-item'}
              draggable="true"
              onDragStart={(e) => onDragStart(e)}
              onDragEnd={(e) => onDragEnd(e)}
            >
              <div className={'image'}>
                <img src={block.icon} alt="text" />
              </div>
              <div className={'label'}>{block.name}</div>
            </div>
          ))}
        </div>

        <Modal displayModal={isTutorialActive}>
          <img alt="" src={blankPageArrow} className={'blankPageArrow'} />
          <div className={'placeholderContainer'}>
            <img
              alt=""
              src={blankPagePlaceholder}
              className={'blankPagePlaceholder'}
            />
            <p className={'blankPageText'}>
              Would you like to to add more content? <br /> Just drag and drop
              sections from the left bar into your page!
            </p>
          </div>
          <div className={'blankPageButton'}>
            <PrimaryButton
              height={40}
              width={180}
              onClick={() => setIsTutorialActive(false)}
              name={'Ok, Got It'}
            />
          </div>
        </Modal>
      </StyledSideMenu>
    </>
  );
};

export default SideMenu;
