import React, { useState } from 'react';
import s from './RadioSwitch.module.scss';

function RadioSwitch(props) {
  const [value, setValue] = useState(props.value);

  const handleChange = (event) => {
    setValue(event.currentTarget.value);
    props.onChange(event.currentTarget.value);
  };

  return (
    <span className={s.container}>
      {props.options.map((option, i) => (
        <label className={s.switch}>
          <input
            type="radio"
            key={i}
            value={option.value}
            name={option.value}
            checked={value == option.value}
            onChange={handleChange}
          />
          <div>{option.label}</div>
        </label>
      ))}
    </span>
  );
}

export default RadioSwitch;
