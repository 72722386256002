import clsx from 'clsx';

import styles from './ColorScheme.module.scss';

interface IProps {
  colors: string[];
  isSelected?: boolean;
  onClick: (colors: string[], index?: number) => void;
  index?: number;
}

const ColorScheme = (props: IProps) => {
  return (
    <div
      className={clsx(styles.container, {
        [styles.selected]: props.isSelected,
      })}
      onClick={() => props.onClick(props.colors, props.index)}
    >
      {props.colors.map((item, index) => (
        <div
          className={styles.color}
          key={index}
          style={{ backgroundColor: item }}
        />
      ))}
    </div>
  );
};

export default ColorScheme;
