import { FontFamilies } from './FontFamilies';

const mobileSizeMap = [
  {
    max: 10,
    offset: 1,
  },
  {
    max: 13,
    offset: 0.91,
  },
  {
    max: 17,
    offset: 0.88,
  },
  {
    max: 21,
    offset: 0.85,
  },
  {
    max: 26,
    offset: 0.8,
  },
  {
    max: 31,
    offset: 0.79,
  },
  {
    max: 41,
    offset: 0.75,
  },
  {
    max: 44,
    offset: 0.73,
  },
  {
    max: 61,
    offset: 0.699,
  },
  {
    max: 80,
    offset: 0.68,
  },
  {
    max: 188,
    offset: 0.6,
  },
];

export const forMobile = (value) => {
  const size = parseFloat(value);
  const current = mobileSizeMap.find((item) => item.max > size);
  if (current) {
    return `${(size * current.offset).toFixed(4)}px`;
  }
  return value;
};

export const parseClass = (styleKey, isMobile) => {
  const parse = /(.*)-([^-]*)/gi;
  const result = parse.exec(styleKey);
  const [_, key, value] = result || [];
  let style;
  if (['color'].includes(key)) {
    const newValue = value[0] == '#' ? value : `#${value}`;
    style = {
      color: newValue,
    };
  }

  if (['weight'].includes(key)) {
    style = {
      fontWeight: parseInt(value),
    };
  }

  if (['size'].includes(key)) {
    // const newValue = value.indexOf('px') ? value : `${value}px`;
    style = {
      fontSize: !isMobile ? value : forMobile(value),
    };
  }
  return style;
};

export const createStyleMap = (baseStyles, editorState, isMobile) => {
  const blockMap = editorState.getCurrentContent().getBlockMap().toJS();

  for (const blokKey in blockMap) {
    const blok = blockMap[blokKey];

    blok.characterList.forEach((char) => {
      char.style.forEach((styleKey) => {
        if (!baseStyles[styleKey]) {
          const style = parseClass(styleKey, isMobile);
          if (style) {
            baseStyles[styleKey] = style;
            baseStyles[`block-style-${styleKey.replace('#', '')}`] = style;
          }
        }
      });
    });
  }
  return baseStyles;
};

export const FontSizeStyleMap = (isMobile) => {
  if (isMobile === undefined) {
    isMobile = false;
  }

  return {
    'size-Tiny': {
      fontSize: isMobile ? forMobile('12px') : '12px',
      display: 'inline-block',
    },
    'size-Small': {
      fontSize: isMobile ? forMobile('15px') : '15px',
    },
    'size-Medium': {
      fontSize: isMobile ? forMobile('20px') : '20px',
    },
    'size-Normal': {
      fontSize: isMobile ? forMobile('25px') : '25px',
    },
    'size-Large': {
      fontSize: isMobile ? forMobile('30px') : '30px',
    },
    'size-Huge': {
      fontSize: isMobile ? forMobile('40px') : '40px',
    },
    'size-Epic': {
      fontSize: isMobile ? forMobile('60px') : '60px',
    },
  };
};

export const FontFamilyMap = FontFamilies.map((font) => ({
  [`font-${font.value}`]: {
    fontFamily: `${font.label}`,
  },
})).reduce((a, b) => ({
  ...a,
  ...b,
}));

export const Colors = [
  'transparent',
  '#5cc2fc',
  '#79fce9',
  '#8cf85a',
  '#feef63',
  '#fd978f',
  '#fd97ca',
  '#c092e9',
  //
  '#1ca4fc',
  '#2fe6cf',
  '#65d643',
  '#fed846',
  '#fc6554',
  '#fc48a1',
  '#834fe0',
  //
  '#1177b8',
  '#1aaa8e',
  '#29af1d',
  '#fcad2a',
  '#eb261f',
  '#d12177',
  '#5e30b3',
  //
  '#084e7e',
  '#0c6c65',
  '#0d700f',
  '#f07220',
  '#b31a10',
  '#951453',
  '#36217a',
  //
  '#ffffff',
  '#d5d5d5',
  '#878787',
  '#535353',
  '#292929',
  '#1b1b1b',
  '#000000',
];
let ColorStyleMap = {};
for (let color of Colors) {
  let key = 'color-' + color;
  ColorStyleMap[key] = { color: color };
}
export { ColorStyleMap };

const generateStyleSheet = () => {
  let styles = [];
  for (let color of Colors) {
    color = color.replace('#', '');
    styles.push(`.block-style-color-${color}{ color: #${color}; }`);
  }

  const sizes = FontSizeStyleMap();
  for (let key of Object.keys(sizes)) {
    styles.push(`.block-style-${key}{ font-size:${sizes[key].fontSize}; }`);
  }

  var style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = styles.join(' ');
  } else {
    style.appendChild(document.createTextNode(styles.join(' ')));
  }
  document.getElementsByTagName('head')[0].appendChild(style);
};
generateStyleSheet();
