import clsx from 'clsx';
import { DetailedHTMLProps, HTMLAttributes, LegacyRef } from 'react';

import styles from './ToolbarManipulatorButton.module.scss';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  enabled?: boolean;
  reference?: LegacyRef<HTMLDivElement>;
}

const ToolbarManipulatorButton = ({
  enabled,
  className,
  reference,
  style,
  children,
  ...rest
}: IProps) => {
  return (
    <div
      ref={reference}
      className={clsx(styles.button, className)}
      style={{ pointerEvents: enabled ? 'auto' : 'none', ...style }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ToolbarManipulatorButton;
