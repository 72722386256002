import clsx from 'clsx';
import { useState, useRef, useEffect, MutableRefObject } from 'react';
import { ReactComponent as ChapterDragAndDrop } from 'Assets/chapterDragAndDrop.svg';
import { ReactComponent as PencilIcon } from 'Assets/pencil.svg';
import { ReactComponent as ThreeDots } from 'Assets/threeDots.svg';
import checkMark from 'Assets/whiteCheckMark.svg';
import arrow from 'Assets/icons/arrow.svg';
import Tooltip from 'Components/Common/Tooltip/Tooltip';
import Button from 'Components/Common/Button/Button';
import { IBookChapter } from '../types';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import TextArea from '../TextArea/TextArea';

import s from './ChapterItem.module.scss';

interface IProps {
  chapter: IBookChapter;
  index: number;
  onChapterTitleEditedProps: (value: string, index: number) => void;
  activeChapter: number;
  setActiveChapter: (index: number) => void;
  // deleteChapter: (index: number) => void;
  // onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  // loadingAddChapter: boolean;
  onNoteAdd: (chapterIndex: number, noteText: string) => void;
  // isBookTitleActive: boolean;
  setIsBookTitleActive: (arg: boolean) => void;
  toggleDropdown: (
    index: number,
    ref: MutableRefObject<HTMLDivElement | null>
  ) => void;
  // isOpen: boolean;
}

function ChapterItem({
  chapter,
  index,
  onChapterTitleEditedProps,
  activeChapter,
  setActiveChapter,
  // deleteChapter,
  // onChapterAdd,
  // loadingAddChapter,
  onNoteAdd,
  // isBookTitleActive,
  setIsBookTitleActive,
  toggleDropdown,
}: // isOpen
IProps) {
  const [chapterTitleInput, setChapterTitleInput] = useState<string>(
    chapter.title
  );
  const [noteTextAreaInput, setNoteTextAreaInput] = useState<string>(
    chapter.comments[0]
  );
  const [isNoteTextAreaOpen, setIsNoteTextAreaOpen] = useState<boolean>(false);
  const [isHasNote, setIsHasNote] = useState<boolean>(false);

  const noteTextAreaRef = useRef<HTMLDivElement>(null);

  const refDotsMenu = useRef(null);

  const onChapterTitleEdited = (value: string) => {
    onChapterTitleEditedProps(value, index);
  };

  const handleChangeNote = () => {
    if (noteTextAreaInput) {
      onNoteAdd(index, noteTextAreaInput.trim());
      setIsNoteTextAreaOpen(false);
    } else {
      onNoteAdd(index, '');
      setIsNoteTextAreaOpen(false);
    }
  };

  useEffect(() => {
    if (chapter.comments[0] && chapter.comments[0].trim().length > 0) {
      setIsHasNote(true);
    } else {
      setIsHasNote(false);
    }
  }, [chapter.comments]);

  useEffect(() => {
    if (isNoteTextAreaOpen && activeChapter != index) {
      handleChangeNote();
    }
  }, [isNoteTextAreaOpen, activeChapter]);

  const onChapterClick = () => {
    setActiveChapter(index);
    setIsBookTitleActive(false);
  };

  useEffect(() => {
    if (isNoteTextAreaOpen && noteTextAreaRef.current) {
      noteTextAreaRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isNoteTextAreaOpen]);

  return (
    <div
      className={clsx(s.chapterItemWrapper, {
        [s.chapterItemWrapperActive]: index === activeChapter,
      })}
    >
      <div className={s.chapterItem} onClick={onChapterClick}>
        <div className={s.titleCircleBlock}>
          <div className={s.chapterIndexCircle}>{index + 1}</div>
          <div className={s.chapterTitle}>
            <ContentEditableDiv
              name={chapterTitleInput}
              fontSize={16}
              lineHeight={22}
              fontWeight={500}
              height={40}
              onChange={setChapterTitleInput}
              onEdit={onChapterTitleEdited}
              onClick={onChapterClick}
              isMultiline={true}
              preFirstEdit={true}
            />
          </div>
        </div>
        <ChapterDragAndDrop className={s.chapterDragAndDropSvg} />
        <div className={s.noteChangeButtonBlock}>
          {window.outerWidth > 600 ? (
            <Tooltip
              overlayContent={
                isHasNote ? 'Edit chapter note' : 'Add chapter note'
              }
            >
              <div
                className={s.noteChangeButton}
                onClick={() => setIsNoteTextAreaOpen(!isNoteTextAreaOpen)}
              >
                {isHasNote ? (
                  <PencilIcon fill="#5a5a5a" className={s.pencil} />
                ) : (
                  <img src={arrow} alt="arrow" />
                )}
              </div>
            </Tooltip>
          ) : (
            <div
              className={s.noteChangeButton}
              onClick={() => setIsNoteTextAreaOpen(!isNoteTextAreaOpen)}
            >
              {isHasNote ? (
                <PencilIcon fill="#5a5a5a" className={s.pencil} />
              ) : (
                <img src={arrow} alt="arrow" />
              )}
            </div>
          )}
          <ThreeDots
            className={s.threeDotsSvg}
            ref={refDotsMenu}
            onClick={() => toggleDropdown(index, refDotsMenu)}
          />
        </div>
      </div>
      {isNoteTextAreaOpen && (
        <div className={s.textAreaBlock} ref={noteTextAreaRef}>
          <TextArea
            label=""
            onChange={setNoteTextAreaInput}
            value={noteTextAreaInput}
            height={90}
            autoComplete="off"
            maxLength={2500}
          />
          <Button
            size="regular"
            onClick={() => handleChangeNote()}
            borderRadius={'25px'}
          >
            Add Note <img src={checkMark} alt="check mark" />
          </Button>
        </div>
      )}
      {!isNoteTextAreaOpen && isHasNote && (
        <div className={s.noteBlock}>{chapter.comments[0]}</div>
      )}
    </div>
  );
}

export default ChapterItem;
