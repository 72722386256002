import { ReactComponent as Premium } from 'Assets/Premium.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/duplicate.svg';
import { Block } from 'types/page';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

interface IProps {
  block: Block;
  enabled: boolean;
}

const DuplicateAction = ({ block, enabled }: IProps) => {
  return (
    <ToolbarManipulatorButton
      onClick={() => block.duplicateBlock()}
      enabled={enabled}
    >
      {/* {!props.hasCorrectPlan && (
        <Premium className={styles.premiumIcon} width={10} height={10} />
      )} */}
      <DuplicateIcon />
    </ToolbarManipulatorButton>
  );
};

export default DuplicateAction;
