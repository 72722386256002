import { ReactComponent as AIReloadIcon } from 'Assets/reloadai.svg';
import { Block } from 'types/page';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

interface IProps {
  block: Block;
  enabled: boolean;
}

const AIReloadAction = ({ block, enabled }: IProps) => {
  return (
    <ToolbarManipulatorButton
      onClick={() => block.rerollWithAiPrompt()}
      enabled={enabled}
    >
      <AIReloadIcon />
    </ToolbarManipulatorButton>
  );
};

export default AIReloadAction;
