var _controller = null;
export default class StoreController {
  constructor() {
    this.attributePanelSetBlock = null;
    this.liveMode = false;
    this.productionMode = false;
  }

  static instance() {
    if (_controller === null) {
      _controller = new StoreController();
    }
    return _controller;
  }

  select(blocks) {
    // this.attributePanelSetBlock(blocks);
  }

  attributePanelHandler(ref) {
    this.attributePanelSetBlock = ref;
  }

  textEditorUpdateHandler(ref) {}
}
