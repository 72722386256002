import { Block } from 'types/page';
import { ReactComponent as RemoveIcon } from 'Assets/remove.svg';
import ToolbarManipulatorSubmenuButton from '../../Common/ToolbarManipulatorSubmenuButton/ToolbarManipulatorSubmenuButton';

interface IProps {
  text: string;
  block: Block;
  enabled?: boolean;
}

const DeleteAction = ({ block, text, enabled }: IProps) => {
  const handleClick = () => {
    block.removeFromParent();
    const view = block.view;
    const overlay = view.overlay;
    overlay.clearSelection();
    view.update();
  };

  return (
    <ToolbarManipulatorSubmenuButton
      onClick={handleClick}
      enabled={enabled}
      Icon={RemoveIcon}
      text={text}
    />
  );
};

export default DeleteAction;
