export const START_FREE_SUBSCRIPTION = {
  operationName: 'startFreeSubscription',
  query: `mutation startFreeSubscription(
        <KEYS>
      ) {
        startFreeSubscription(
            <VALUES>
        ) {
          link
        }
      }`,
};
