export const CREATE_BOOK_MUTATION = {
  operationName: 'createBook',
  query: `mutation createBook(
      $title: String!
      $description: String!
      $tone: String!
      $audience: String
      $structure: String
      $data: String
      $coverData: String
      $size: String
      $chapters: String
      $coverImage: String
      $coverImageUrl: String
    ) {
      createBook(
        title: $title
        description: $description
        tone: $tone
        audience: $audience
        structure: $structure
        data: $data
        coverData: $coverData
        size: $size
        chapters: $chapters
        coverImage: $coverImage
        coverImageUrl: $coverImageUrl
      ) {
        _id
      }
    }`,
};
