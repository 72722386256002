import styled from 'styled-components';
import { useState, useEffect } from 'react';

import { applyStyle, getStyleChar } from './StyleControlsUtils';
import { FontFamilyMap } from './StyleMaps';
import { FontFamilies } from './FontFamilies';
import FontSelector from './FontSelector/FontSelector';
import FontManager from '../../utils/FontManager';

const ControlStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: initial;
  text-align: right;
  margin-right: 10px;
  align-items: center;
  z-index: 1003;
  .no-edit {
    .name {
      display: inline-block;
      width: 50px;
    }
    .warning {
      color: yellow;
    }
  }
  width: 180px;
`;

const getOptionsStyles = () =>
  FontFamilies.map((el) => ({
    ...el,
    style: {
      fontFamily: el.label,
    },
  })).sort((a, b) => (a.label > b.label ? 1 : -1));

const FontStyleControls = (props) => {
  const [value, setValue] = useState('Roboto');

  useEffect(() => {
    const newValue = getValue(props.editorState);
    setValue(newValue);
  }, [props.editorState]);

  const onChange = (item) => {
    console.log('font change', item);
    let fontName = item.value;
    setValue(fontName);

    FontManager.getInstance().loadFont(fontName);

    const style = 'font-' + fontName;

    let nextEditorState = applyStyle(
      props.block,
      props.editorState,
      style,
      FontFamilyMap
    );
    props.onChange(nextEditorState);
  };

  const getFontFromChar = (char) => {
    if (!char) {
      return 'Inter';
    }

    const arr = char.getStyle().toArray();
    let font = 'Inter'; //default font
    arr.forEach((style) => {
      if (style.search('font-') === 0) {
        font = style.substring(5);
      }
    });
    return font;
  };

  const getValue = (editorState) => {
    let char = getStyleChar(editorState);
    return getFontFromChar(char);
  };

  return (
    <ControlStyle>
      <FontSelector
        title={
          getOptionsStyles(FontFamilies).find((item) => item.value == value)
            ?.label
        }
        list={getOptionsStyles(FontFamilies)}
        select={value}
        onChange={onChange}
      />
    </ControlStyle>
  );
};

export default FontStyleControls;
