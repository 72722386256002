export const DELETE_SUBPAGE = {
  operationName: 'deleteSubPage',
  query: `mutation deleteSubPage(
      $pageId: String!
      $name: String!
    ) {
      deleteSubPage(
        pageId: $pageId
        name: $newName
      ) {
        message
      }
    }`,
};
