import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from 'Assets/arrowDown.svg';
import { ReactComponent as ArrowUp } from 'Assets/arrowUp.svg';
import { ReactComponent as Check } from 'Assets/check2.svg';
import { ReactComponent as Search } from 'Assets/search.svg';
import Tooltip from 'Components/Common/Tooltip/Tooltip';

import styles from './FontSelector.module.scss';

interface ListItem {
  label: string;
  value: string;
}

interface IProps {
  id?: string;
  title: string;
  list: Array<ListItem>;
  onChange: (item: ListItem) => void;
  select: string;
  checkIcon: React.ReactNode;
  arrowUpIcon: React.ReactNode;
  arrowDownIcon: React.ReactNode;
}

const FontSelector = (props: IProps) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [selectedItem, setSelectedItem] = useState<ListItem>();
  const [keyword, setKeyword] = useState('');

  const searchField = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (props.select) {
      selectSingleItem(props.select);
    }
  }, []);

  useEffect(() => {
    const close = () => {
      setIsListOpen(false);
    };

    if (isListOpen) {
      window.addEventListener('click', close);
    } else {
      window.removeEventListener('click', close);
    }

    return () => {
      window.removeEventListener('click', close);
    };
  }, [isListOpen]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      selectSingleItem(props.select);
    }
  }, [props.select]);

  const selectSingleItem = (value: string) => {
    const { list } = props;

    const selectedItem = list.find((i) => i.value === value);
    if (selectedItem) selectItem(selectedItem, true);
  };

  const selectItem = (item: ListItem, silent: boolean) => {
    const { label } = item;
    const { onChange } = props;

    let foundItem;

    if (!label) {
      foundItem = props.list.find((i) => i.value === item.value);
    }

    if (label || foundItem) setTitle(foundItem ? foundItem.label : label);
    setIsListOpen(false);
    setSelectedItem(item);

    if (!silent) {
      onChange(item);
    }
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
  };

  const toggleList = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsListOpen(!isListOpen);
    setKeyword('');
  };

  const filterList = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value.toLowerCase());
  };

  const listItems = () => {
    const { id, checkIcon } = props;
    let tempList = [...props.list];
    const selectedItemValue = selectedItem?.value;

    if (keyword.length) {
      tempList = props.list.filter((item) =>
        item.label.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (tempList.length) {
      return tempList.map((item) => (
        <div
          className={`${styles['dd-list-item']} ${id}`}
          key={item.value}
          onClick={(e) => {
            e.preventDefault();
            selectItem(item, false);
          }}
          onMouseDown={onMouseDown}
        >
          <span className={styles['dd-list-item-check']}>
            {item.value === selectedItemValue && <>{checkIcon || <Check />}</>}
          </span>
          <img
            className={styles['dd-list-item-preview']}
            src={`${process.env.PUBLIC_URL}/assets/fontPreview/${item.value}.svg`}
            alt={item.label}
          ></img>
        </div>
      ));
    }

    return <div className={`${styles['dd-list-item']} no-result ${id}`}></div>;
  };

  return (
    <div className={`${styles['dd-wrapper']} ${props.id}`}>
      <Tooltip overlayContent="Font" visible={isListOpen ? false : undefined}>
        <div
          className={`${styles['dd-header']} ${props.id}`}
          onClick={toggleList}
        >
          <div className={`${styles['dd-header-title']} ${props.id}`}>
            {title}
          </div>
          {isListOpen ? (
            <span>{props.arrowUpIcon || <ArrowUp />}</span>
          ) : (
            <span>{props.arrowDownIcon || <ArrowDown />}</span>
          )}
        </div>
      </Tooltip>

      {isListOpen && (
        <div className={`${styles['dd-list']} searchable ${props.id}`}>
          <div className={styles['dd-list-search-container']}>
            <Search />
            <input
              ref={searchField}
              className={`${styles['dd-list-search-bar']} ${props.id}`}
              placeholder="Search fonts"
              onClick={(e) => e.stopPropagation()}
              onChange={filterList}
            />
          </div>
          <div className={`${styles['dd-scroll-list']} ${props.id}`}>
            {listItems()}
          </div>
        </div>
      )}
    </div>
  );
};

export default FontSelector;
