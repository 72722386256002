import clsx from 'clsx';
import { Component, DetailedHTMLProps, HTMLAttributes, RefObject } from 'react';

import styles from './BlockWrapper.module.scss';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isDragging: boolean;
  width: number;
  reference: RefObject<any>;
  errors?: { requiredAttributes: number; errorMessages: string[] };
}

class BlockWrapper extends Component<IProps> {
  render() {
    const {
      isDragging,
      className,
      style,
      width,
      reference,
      errors,
      children,
      ...rest
    } = this.props;

    return (
      <div
        ref={reference}
        className={clsx(styles.wrapper, className, {
          [styles.draggingState]: isDragging,
          [styles.errorState]: !!errors && errors.errorMessages.length > 0,
        })}
        style={{ width: isDragging ? width : 'unset', ...style }}
        {...rest}
      >
        {children}
        {!!errors && errors.errorMessages.length > 0 && (
          <div className={styles.errorsAmount}>
            {errors.requiredAttributes - errors.errorMessages.length}/
            {errors.requiredAttributes}
            <div className={styles.popup}>
              <ul>
                {errors.errorMessages.map((errorMessage) => (
                  <li>{errorMessage}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BlockWrapper;
