export const FETCH_FUNNELS_QUERY = {
  operationName: 'fetchFunnels',
  query: `query fetchFunnels {
      fetchFunnels {
        description
        name
        title
        _id
        archived
        mailchimpAPI
        mailchimpAudienceId
        mailchimpUsername
        domain
        headerScript
        footerScript
        stripePublicKey
        stripeSecretKey
        customSubDomain
        position
        products {
          name
          descriptor
          price
          currency
          isSubscription
          isSevenDayTrial
        },
        order {
          id
          enabled
        }
        pages {
          _id
          projectId
          name
          enabled
          visited
          conversion
          archived 
          headerScript
          footerScript
          createdAt
          updatedAt
          thumbnail
          pages {
            name
            image
            enabled
            exitpopupdata
            exitpopupenabled
          }
        }
      }
    }`,
};
