export const GET_BOOK_BY_ID = {
  operationName: 'getBookById',
  query: `query getBookById (
      $id: String!
    ) {
      getBookById (
        id: $id
      ) {
        title
        _id
        chapters {
          title
          text
          comments
        }
        data
        coverData
        description
        tone
        audience
        structure
        coverImageUrl
      }
    }`,
};
