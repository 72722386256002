import React from 'react';
import styled from 'styled-components';
import StyleButton from './StyleButton/StyleButton';
import { RichUtils } from 'draft-js';
import { ReactComponent as AlignLeftOutlined } from '../../Assets/align-left.svg';
import { ReactComponent as AlignCenterOutlined } from '../../Assets/align-center.svg';
import { ReactComponent as AlignRightOutlined } from '../../Assets/align-right.svg';

const ControlStyle = styled.div`
  display: flex;
  margin-right: 10px;
`;

const BLOCK_TYPES = [
  {
    icon: <AlignLeftOutlined />,
    label: 'AL',
    style: 'align-left-item',
    name: 'Align Left',
  },
  {
    icon: <AlignCenterOutlined />,
    label: 'AC',
    style: 'align-center-item',
    name: 'Align Center',
  },
  {
    icon: <AlignRightOutlined />,
    label: 'AR',
    style: 'align-right-item',
    name: 'Align Right',
  },
];

export default class AlignStyleControls extends React.Component {
  onToggle(blockType) {
    this.props.onChange(
      RichUtils.toggleBlockType(this.props.editorState, blockType)
    );
  }

  render() {
    const { editorState } = this.props;
    let selection;
    let blockType;
    if (editorState) {
      selection = editorState.getSelection();
      blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    }

    return (
      <ControlStyle>
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            icon={type.icon}
            onToggle={this.onToggle.bind(this)}
            style={type.style}
            tooltip={type.name}
          />
        ))}
      </ControlStyle>
    );
  }
}
