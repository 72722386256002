import clsx from 'clsx';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './RequiredFieldWrapper.module.scss';

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  missingValue: boolean;
  errors?: { requiredAttributes: number; errorMessages: string[] };
}

const RequiredFieldWrapper = ({
  className,
  missingValue,
  children,
  ...rest
}: IProps) => {
  return (
    <div
      className={clsx(
        styles.wrapper,
        {
          [styles.errorState]: missingValue,
        },
        className
      )}
      {...rest}
    >
      {children}
      {missingValue && <div className={styles.warningIcon}>!</div>}
    </div>
  );
};

export default RequiredFieldWrapper;
