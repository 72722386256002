import { Block } from 'types/page';
import { getLinkWithoutCDN } from 'utils/functions';
import { ReactComponent as ArrowDownIcon } from 'Assets/icons/arrowDown.svg';
import ToolbarManipulatorSubmenuButton from '../../Common/ToolbarManipulatorSubmenuButton/ToolbarManipulatorSubmenuButton';

interface IProps {
  text: string;
  block: Block;
  enabled?: boolean;
}

const DownloadAction = ({ block, text, enabled }: IProps) => {
  const handleClick = async () => {
    let imageSrc: string | undefined = undefined;

    switch (block.type) {
      case 'BookCover':
        imageSrc = block.image.value;
        break;
      default:
        break;
    }

    if (!imageSrc) return;

    const image = await fetch(getLinkWithoutCDN(imageSrc));
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'background';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ToolbarManipulatorSubmenuButton
      onClick={handleClick}
      enabled={enabled}
      Icon={ArrowDownIcon}
      text={text}
    />
  );
};

export default DownloadAction;
