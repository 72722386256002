import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'Hooks/useSearchParams';

const HtmlRender = () => {
  const [html, setHtml] = useState('');
  const { query } = useSearchParams();
  const screenToken = query.screenToken as string;
  const url = query.url as string;

  const fetchAndSetHtml = async () => {
    const newHtml = await fetch(
      `http://${url}/images/screen-html?screenToken=${screenToken}`
    ).then((res) => res.text());
    setHtml(newHtml);
  };

  useEffect(() => {
    fetchAndSetHtml();
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};

export default HtmlRender;
