import { ReactComponent as RemoveIcon } from 'Assets/remove.svg';
import { Block } from 'types/page';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';
import { rxRemoveSection } from 'rx/rxState';

interface IProps {
  block: Block;
  enabled: boolean;
}

const RemoveAction = ({ block, enabled }: IProps) => {
  const handleClick = () => {
    const view = block.view;

    //TODO: might need to make a new action called RemoveSectionAction to make it more pure.
    if (block.type === 'Section') {
      const indexToRemove = view.getBlockIndex(block);
      //For Section remove we need to notify other consumers of this event
      rxRemoveSection.next({ index: indexToRemove });
    } else {
      block.removeFromParent();
      const overlay = view.overlay;
      overlay.clearSelection();
      view.update();
    }
  };

  return (
    <ToolbarManipulatorButton onClick={handleClick} enabled={enabled}>
      <RemoveIcon />
    </ToolbarManipulatorButton>
  );
};

export default RemoveAction;
