//Class that designed to help with adding blocks and provide visual guidelines

// import { intersect, distance } from 'mathjs';
import { COLORS, FONTS } from '../Constants';

const kDebug = false;

const kDirectionNone = -1;
const kDirectionUp = 0;
const kDirectionInsideDown = 1;

const kSnappingThreshold = 10;
const kDefaultWidth = 400;

export default class Adder {
  constructor(view) {
    this.view = view;
    this.blockInfo = null; //info about block to add
    this.parentBlock = null;
    this.placeholderRect = null;
    this.addingOrderIndex = -1;
  }
  onDrag(mousePos) {
    const blockUnder = this.view.getBlockAt(mousePos.x, mousePos.y);
    if (blockUnder) {
      if (blockUnder.type == 'Row') {
        this.parentBlock = blockUnder;
      } else {
        this.parentBlock = blockUnder.parent;
      }
    }

    if (this.parentBlock) {
      const rowGroupThreshold = 30;
      let rows = [];
      let rowIndex = -1;
      let prevRectCenter = -100;
      const children = this.parentBlock.children;

      //group rects based on their heights
      let i = 0;
      for (const child of children) {
        const rect = child.worldRenderBRect;
        let y = rect.y + rect.height * 0.5;
        if (
          prevRectCenter - rowGroupThreshold < y &&
          prevRectCenter + rowGroupThreshold > y
        ) {
          rows[rowIndex].rects.push(rect);
          rows[rowIndex].orderEnd = i;
        } else {
          rows.push({
            y: y,
            height: rect.height,
            rects: [rect],
            orderStart: i,
            orderEnd: i,
          });
          rowIndex += 1;
        }
        i += 1;
        prevRectCenter = y;
      }

      //get closest row
      let delta = Number.MAX_SAFE_INTEGER;
      let closestRowIndex = -1;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        let curentDelta = Math.abs(row.y - mousePos.y);
        if (curentDelta < Math.abs(delta)) {
          delta = row.y - mousePos.y;
          closestRowIndex = i;
        }
      }
      if (closestRowIndex >= 0) {
        const row = rows[closestRowIndex];

        const parentRect = this.parentBlock.worldRenderBRect;
        const padding = 10;
        let direction = 0;
        if (delta > 0) {
          direction = -1;
          this.addingOrderIndex = row.orderStart + 0;
        } else {
          direction = 1;
          this.addingOrderIndex = row.orderEnd + 1;
        }

        this.placeholderRect = {
          x: parentRect.x + padding,
          y: row.y + row.height * 0.5 * direction - 5,
          width: parentRect.width - padding * 2,
          height: 10,
        };
      }
    }
  }

  onDrop(e) {
    //Adding block
    if (this.blockInfo && this.parentBlock) {
      const block = this.view.createBlock(this.blockInfo.blockType);
      if (block) {
        if (this.blockInfo.blockType == 'Button') {
          //Special case for button to add text Block as child
          const textBlock = this.view.createBlock('Text');
          textBlock.textClass.value = 's-button-labelcase';
          block.addChild(textBlock);
        }

        let maxColumns = this.parentBlock.columns;
        if (maxColumns >= 8) {
          maxColumns /= 2;
        }
        block.setGridSpan({
          start: Math.floor((this.parentBlock.columns - maxColumns) / 2),
          length: maxColumns,
        });
        this.parentBlock.addChild(block, this.addingOrderIndex);
      }
    }
    this.blockInfo = null;
    this.placeholderRect = null;
    this.parentBlock = null;
  }

  update() {}

  renderOverlay(ctx) {
    if (this.parentBlock) {
      const padding = 10;
      ctx.beginPath();
      ctx.strokeStyle = '#73AD21';
      ctx.lineWidth = 2;
      const rect = this.parentBlock.worldRenderBRect;
      ctx.roundRect(
        rect.x - padding,
        rect.y - padding,
        rect.width + padding * 2,
        rect.height + padding * 2,
        [20]
      );
      ctx.stroke();
      // ctx.endPath()

      if (this.placeholderRect) {
        ctx.beginPath();
        ctx.fillStyle = '#4957D8';
        const rect = this.placeholderRect;
        ctx.roundRect(rect.x, rect.y, rect.width, rect.height, [20]);

        ctx.fill();
      }
    }
  }
}
