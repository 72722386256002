import React from 'react';
import PopUpWrapper from './PopupWrapper';
import Button from '../Common/Button/Button';
import s from './Popup.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const RecommendUseDesktopPopup = (props: IProps) => {
  return props.open ? (
    <PopUpWrapper wide={false} onClose={props.onClose} width={'370px'}>
      <div className={s.recommendUseDesktopPopupWrapper}>
        <h2>Use Desktop Browser For Best Experience</h2>
        <div>
          We strongly recommend you use a desktop computer for editing with
          AutoFunnel.AI. While a mobile version is in the works, the experience
          is not yet optimized for your devices
        </div>
        <Button onClick={() => props.onClose()} color={'secondary'}>
          Continue to Desktop Site
        </Button>
      </div>
    </PopUpWrapper>
  ) : null;
};

export default RecommendUseDesktopPopup;
