import React, { useState, useEffect } from 'react';
import s from './StripePurchase.module.scss';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_KEY } from 'Constants';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import Spinner from 'Components/Common/Spinner/Spinner';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

const stripePromise = loadStripe(STRIPE_KEY);

interface IProps {
  email: string;
  name: string;
  priceId: string;
  priceData?: { price: number; priceGap: string } | null;
}

const StripePurchase = (props: IProps) => {
  const [stripeClientSecretId, setStripeClientSecretId] = useState('');

  useEffect(() => {
    const fetchStripeClientId = async () => {
      const data = await graphQlCall({
        queryTemplateObject: QUERIES.AF_CREATE_SETUP_INTENT,
        values: {
          priceId: props.priceId,
          lm_data: window.lm_data,
        },
      });
      console.log('data', data);
      setStripeClientSecretId(data.client_secret);
    };

    if (stripeClientSecretId == '') {
      fetchStripeClientId().catch(console.error);
    }
  });

  return (
    <div className={s.content}>
      {!stripeClientSecretId && <Spinner size={100}></Spinner>}
      {stripeClientSecretId && (
        <>
          <h3 className={s.mainHeader}>Payment Details</h3>
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: stripeClientSecretId }}
          >
            <CheckoutForm
              name={props.name}
              email={props.email}
              priceData={props.priceData}
            />
          </Elements>
          <p className={s.terms}>
            By clicking "Continue" you agree to AutoFunnel’s
            <a href="https://cdn.autofunnel.ai/PageCraftLLCPrivacy.html">
              Privacy Policy
            </a>{' '}
            and
            <a href="https://cdn.autofunnel.ai/PageCraftLLCtos.html">
              Terms of Service
            </a>
            .
          </p>
        </>
      )}
    </div>
  );
};

export default StripePurchase;
