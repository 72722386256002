import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import styles from './Manipulator.module.scss';

import { DraggableHandle } from '../Items/Draggable';
import clsx from 'clsx';

export interface ManipulatorHandle {
  update: () => void;
}

export enum ManipulatorMode {
  Transform,
  Rotate,
  Scale,
}

interface IProps {
  item: DraggableHandle | null;
  onUpdate: (
    sender: DraggableHandle,
    deltaX: number,
    deltaY: number,
    refX: number,
    refY: number,
    mode: ManipulatorMode
  ) => void;
}

const Manipulator = forwardRef<ManipulatorHandle, IProps>((props, ref) => {
  useImperativeHandle(
    ref,
    () => ({
      update: update,
    }),
    [props.item]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedRect, setSelectedRect] = useState<DOMRect | undefined>(
    undefined
  );
  const [edgeDotActive, setEdgeDotActive] = useState(false);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    update();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    };
  }, [props.item, edgeDotActive]);

  const update = () => {
    if (props.item) {
      const parentRect = containerRef.current?.getBoundingClientRect();
      const rect = props.item.rect();
      if (rect && parentRect) {
        setSelectedRect(
          new DOMRect(
            rect.left - parentRect?.left,
            rect.top - parentRect?.top,
            rect.width,
            rect.height
          )
        );
      }
    } else {
      setSelectedRect(undefined);
    }
  };

  const handleEdgeDotMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    setEdgeDotActive(true);
    if (props.item?.forcedBlur) {
      props.item.forcedBlur();
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    setEdgeDotActive(false);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (props.item && !props.item.locked && event.buttons === 1) {
      //1: Primary button (usually the left button)
      const mode = edgeDotActive
        ? ManipulatorMode.Scale
        : ManipulatorMode.Transform;
      props.onUpdate(
        props.item,
        event.movementX,
        event.movementY,
        event.x - event.movementX,
        event.y - event.movementY,
        mode
      );
      update();
    }
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        className={styles.selectedRect}
        style={{
          width: selectedRect?.width,
          height: selectedRect?.height,
          top: selectedRect?.top,
          left: selectedRect?.left,
        }}
      >
        <div
          className={clsx(styles.dot, styles.left, styles.top)}
          onMouseDown={handleEdgeDotMouseDown}
        />
        <div
          className={clsx(styles.dot, styles.right, styles.top)}
          onMouseDown={handleEdgeDotMouseDown}
        />
        <div
          className={clsx(styles.dot, styles.left, styles.bottom)}
          onMouseDown={handleEdgeDotMouseDown}
        />
        <div
          className={clsx(styles.dot, styles.right, styles.bottom)}
          onMouseDown={handleEdgeDotMouseDown}
        />
      </div>
    </div>
  );
});

export default Manipulator;
