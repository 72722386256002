import React, { useState, useEffect, useRef } from 'react';
import { Block } from 'Canvas/Blocks/Block';

import s from './ToolbarWidgetAiField.module.scss';
import {
  Editor,
  EditorCommand,
  CompositeDecorator,
  EditorState,
  ContentBlock,
  ContentState,
  DraftHandleValue,
  getDefaultKeyBinding,
} from 'draft-js';
import CloseSvg from 'Assets/icons/check.svg';
import {
  getAutocomplete,
  getMatch,
  getSuggestions,
  findWithRegex,
  getSelectionPosition,
  addEntityToEditorState,
} from './EditorAutocomplete';
import clsx from 'clsx';
import { rxAiTemplate } from '../../rx/rxState';

interface IProps {
  block: Block;
  aiFieldsName?: string[];
  onClose: () => void;
}

const AiAttribute = (props: any) => {
  return <span className={s.aiAttribute}>{props.children}</span>;
};

const ToolbarWidgetAiFeild = (props: IProps) => {
  const [match, setMatch] = useState(null);
  const [matches, setMatches] = useState({});
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [aiFieldName, setAiFieldName] = useState();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const editorRef = useRef<Editor>(null);

  const onSuggestionMatch = (text: string) =>
    props.aiFieldsName!.filter((item) => {
      return item.indexOf(text) !== -1;
    });

  const autocompletes = [
    {
      prefix: '#',
      onMatch: onSuggestionMatch,
    },
  ];

  const syntaxHighlightStrategy = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    const result = findWithRegex(/(#)(\S*)(\s|$)/g, contentBlock, callback);
    let newMatches: any = matches;
    if (!newMatches[contentBlock.getKey()]) {
      newMatches[contentBlock.getKey()] = {};
    }

    newMatches[contentBlock.getKey()] = result;
    setMatches(newMatches);
  };

  const aiAttributeDecorator = new CompositeDecorator([
    {
      strategy: syntaxHighlightStrategy,
      component: AiAttribute,
    },
  ]);

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    const aiField = props.block.aiField;
    let promptText = '';
    if (aiField) {
      setAiFieldName(aiField);
      const prompt = getOrMakePrompt(aiField);
      promptText = prompt.prompt;
    }

    const content = ContentState.createFromText(promptText);
    setEditorState(
      EditorState.set(EditorState.createWithContent(content), {
        decorator: aiAttributeDecorator,
      })
    );
  }, []);

  useEffect(() => {
    updateSuggestionMatch();
  }, [editorState]);

  const getOrMakePrompt = (aiFieldName: string) => {
    let template = rxAiTemplate.getValue();
    let prompt = template.promptsFlat.find((value) => value.id === aiFieldName);
    if (!prompt) {
      prompt = {
        id: aiFieldName,
        type: props.block.type.toLowerCase(),
        prompt: '',
      };
      template.promptsFlat.push(prompt);
    }
    return prompt;
  };

  const handleEditorChange = (editorState: EditorState) => {
    setEditorState(editorState);

    const value = editorState.getCurrentContent().getPlainText('\u0001');
    let template = rxAiTemplate.getValue();

    let prompt = template.promptsFlat.find((value) => value.id === aiFieldName);
    if (prompt) {
      prompt.prompt = value;
    }

    // rxAiTemplate.next(template);
  };

  const resetMatch = () => {
    setMatch(null);
  };

  const updateSuggestionMatch = async () => {
    const match = getMatch(editorState, matches);
    if (!match) return resetMatch();

    const autocomplete = getAutocomplete(autocompletes, match);
    if (!autocomplete) return resetMatch();

    const suggestions = await getSuggestions(autocomplete, match);
    let position = getSelectionPosition();

    const newMatch = {
      ...match,
      ...autocomplete,
      suggestions,
      position,
    };
    setMatch(newMatch);
  };

  const handleNameChange = (e: any) => {
    const value = e.target.value;

    if (!aiFieldName || aiFieldName === '') {
      getOrMakePrompt(value);
    } else {
      let template = rxAiTemplate.getValue();
      let prompt = template.promptsFlat.find(
        (prompt) => prompt.id === aiFieldName
      );
      if (prompt) {
        prompt.id = value;
      }
    }

    setAiFieldName(value);
    props.block.aiField = value;
  };

  const buildSuggestionList = () => {
    if (!match) return null;
    const { suggestions, position } = match as any;
    if (!suggestions || suggestions.length === 0) return null;

    const { x, y } = editorRef.current!.editor!.getBoundingClientRect();

    const items = suggestions.map((item: string, index: number) => {
      const selected = selectedSuggestion === index;
      return (
        <li
          className={clsx(s.suggestionItem, { [s.selected]: selected })}
          key={index}
          onClick={() => addEntityWithSelectedSuggestion(index)}
        >
          {item}
        </li>
      );
    });

    return (
      <ul
        className={s.suggestionList}
        style={{ top: position?.bottom - y, left: position?.left - x }}
      >
        {items}
      </ul>
    );
  };

  const addEntityWithSelectedSuggestion = (selectedIndex?: number) => {
    const m = match as any;
    if (m.suggestions[selectedIndex ?? selectedSuggestion]) {
      const item = m.suggestions[selectedIndex ?? selectedSuggestion];
      const newEditorState = addEntityToEditorState(editorState, item, match);
      resetMatch();
      setEditorState(newEditorState);
    }
  };

  const onUpArrow = (e: any) => {
    if (match) {
      e.preventDefault();
      if (selectedSuggestion > 0) {
        setSelectedSuggestion(selectedSuggestion - 1);
      }
    }
  };

  const onDownArrow = (e: any) => {
    if (match) {
      const m = match as any;
      const lastSuggestionIndex =
        m.suggestions.length > 0 ? m.suggestions.length - 1 : 0;
      e.preventDefault();

      // Update selectedSuggestion index
      if (selectedSuggestion < lastSuggestionIndex) {
        setSelectedSuggestion(selectedSuggestion + 1);
      }
    }
  };

  const onEscape = (e: any) => {
    if (match) {
      e.preventDefault();
      setMatch(null);
      setSelectedSuggestion(0);
    }
  };

  const keyBindingFn = (e: any) => {
    if (match && e.keyCode === 13) {
      return 'add-entity';
    }

    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (
    command: EditorCommand,
    editorState: EditorState,
    eventTimeStamp: number
  ) => {
    if (command === 'add-entity') {
      addEntityWithSelectedSuggestion();
      return 'handled';
    }

    return 'not-handled' as DraftHandleValue;
  };

  const onTab = (e: any) => {};

  return (
    <div className={s.container}>
      <div className={s.nameBar}>
        <input
          className={s.nameInput}
          placeholder={'Name'}
          value={aiFieldName}
          autoFocus={true}
          onChange={handleNameChange}
          tabIndex={1}
        />
        <button className={s.closeButton} onClick={() => handleClose()}>
          <img src={CloseSvg} alt="Save" />
        </button>
      </div>
      <div className={s.input}>
        <Editor
          placeholder="Enter your AI prompt"
          ref={editorRef}
          onUpArrow={onUpArrow}
          onDownArrow={onDownArrow}
          onEscape={onEscape}
          keyBindingFn={keyBindingFn}
          handleKeyCommand={handleKeyCommand}
          onTab={onTab}
          editorState={editorState}
          onChange={handleEditorChange}
          stripPastedStyles={true}
          spellCheck={false}
          tabIndex={2}
        />
        {buildSuggestionList()}
      </div>
    </div>
  );
};

export default ToolbarWidgetAiFeild;
