import { ReactComponent as AiRerollIcon } from 'Assets/reroll.svg';
import { Block } from 'types/page';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

interface IProps {
  block: Block;
  enabled: boolean;
}

const AIRerollAction = ({ block, enabled }: IProps) => {
  return (
    <ToolbarManipulatorButton onClick={() => block.reroll()} enabled={enabled}>
      <AiRerollIcon />
    </ToolbarManipulatorButton>
  );
};

export default AIRerollAction;
